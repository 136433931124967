import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import {
	Button,
	FormControl,
	FormHelperText,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

type Props = {
	header?: ReactNode;
	onClose: () => void;
	onSubmit: () => Promise<unknown>;
	submitText?: string;
	word: string;
};

export const TypeToConfirmModal = ({
	submitText = 'confirm',
	header,
	word,
	onClose,
	onSubmit,
}: Props) => {
	const form = useForm({
		defaultValues: {
			word: '',
		},
	});

	const {
		formState: { isSubmitting },
	} = form;
	const typedWord = form.watch('word');

	const handleClose = () => {
		if (isSubmitting) return;
		onClose();
	};

	return (
		<Modal isCentered isOpen onClose={handleClose}>
			<ModalOverlay />
			<ModalContent as="form" onSubmit={form.handleSubmit(() => onSubmit().then(handleClose))}>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<Input {...form.register('word')} autoFocus placeholder={`Type "${word}" to confirm`} />
						<FormHelperText>Type "{word}" to confirm</FormHelperText>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="gray" isDisabled={isSubmitting} mr={3} onClick={handleClose}>
						Close
					</Button>
					<Button
						colorScheme="red"
						isDisabled={typedWord !== word}
						isLoading={isSubmitting}
						type="submit"
					>
						{submitText.toUpperCase()}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
