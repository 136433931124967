import { ReactNode } from 'react';

import { Box, Heading, Stack, StackProps } from '@chakra-ui/react';

interface FieldGroupProps extends StackProps {
	subtitle?: ReactNode;
	title?: string;
}

export const FieldGroup = (props: FieldGroupProps) => {
	const { title, subtitle, children, ...flexProps } = props;
	return (
		<Stack direction={{ base: 'column', md: 'row' }} py="4" spacing="3" {...flexProps}>
			<Box minW="3xs">
				{title && (
					<Heading as="h2" flexShrink={0} fontSize="lg" fontWeight="semibold">
						{title}
					</Heading>
				)}
				{subtitle}
			</Box>
			{children}
		</Stack>
	);
};
