export const ROUTES = {
	ROOT: '/',
	CAL_LOGOUT: '/auth/logout',
	LOGIN: '/auth/login',
	REGISTER: '/auth/register',
	FORGOT: '/auth/forgot',
	RESET: '/auth/reset',

	SETTINGS: '/settings',
	CHAT: '/chat',
	FORMS: '/forms',
	/** special route which will get Intake Questionnaire form */
	GENERAL_INFO: '/general-info',
	FORMS_V2: '/forms-v2',
	VIDEOCALL: '/videocall',
	NOTE_TEMPLATES: '/notetemplates',
	UTILITIES: '/utilities',
	CCM: '/ccm',

	ASSIGNMENTS: '/assignments',
	FORM_COMPLETE: '/formcomplete',
	WORKSHOPS: '/videocall-workshops',

	APPOINTMENTS_CREATE: '/appointments/create',
	APPOINTMENTS_CLIENT: '/appointments/client',
	APPOINTMENTS_CLINICIAN: '/appointments/clinician',

	CLIENTS: '/clients',
	CLIENT_ASSIGNMENTS: '/client/assignments',
	CLIENT_NOTES: '/client/notes',
	CLIENT_INFO: '/client/info',

	ANALYTICS: '/analytics',
};
