import { useMemo } from 'react';

import {
	MutationFunction,
	MutationKey,
	QueryKey,
	UseMutationOptions,
	useIsMutating,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isBoolean, isEmpty, isFunction, isString, isUndefined } from 'lodash-es';

import { useErrorToast, useSuccessToast } from '../hooks/useCustomToast';

export type UseAppMutationOptions<TData, TError, TVariables, TContext> = {
	/**
	 *  if `false` is passed invalidation will be done inside onSuccess
	 *  if `true` is passed invalidation will be done after the mutation function
	 * 	@default false
	 * */
	awaitInvalidateQueries?: boolean;

	/** pass `true` to show default errorMessage or pass your custom message */
	errorMessage?: string | true | ((err: AxiosError) => string);

	/** queryKeys to invalidate `onSuccess` callback */
	invalidateQueries?: QueryKey[];

	mutationKey?: string | MutationKey;

	/** pass `true` to show default successMessage or pass your custom message */
	successMessage?: string | true | ((data: TData) => string);
} & Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn' | 'mutationKey'>;

/**
 * It's a wrapper around the useMutation hook that adds a success and error toast and invalidates
 * queries
 * @param mutationFn - The mutation function that you want to use.
 * @param  - MutationFunction<TData, TVariables>
 * @returns A function that takes a mutation function and returns a useMutationResult
 */

export const useAppMutation = <
	TVariables = void,
	TData = unknown,
	TError = AxiosError,
	TContext = unknown
>(
	mutationFn: MutationFunction<TData, TVariables>,
	{
		errorMessage,
		successMessage,
		invalidateQueries: queriesToInvalidate = [],
		awaitInvalidateQueries = false,
		mutationKey,
		...options
	} = {} as UseAppMutationOptions<TData, TError, TVariables, TContext>
) => {
	const arrayMutationKey = useMemo(
		() =>
			isUndefined(mutationKey) ? undefined : isString(mutationKey) ? [mutationKey] : mutationKey,
		[mutationKey]
	);

	const client = useQueryClient();
	const successToast = useSuccessToast();
	const errorToast = useErrorToast();
	const isMutating = useIsMutating({
		mutationKey: arrayMutationKey,
		exact: true,
		predicate: () => {
			return isUndefined(arrayMutationKey) ? false : true;
		},
	});

	const mutationResult = useMutation({
		mutationFn: async (...args) => {
			const result = await mutationFn(...args);
			if (awaitInvalidateQueries && !isEmpty(queriesToInvalidate)) {
				await Promise.allSettled(
					queriesToInvalidate.map((queryKey) => client.invalidateQueries(queryKey))
				);
			}

			return result;
		},
		networkMode: 'always',
		...options,
		mutationKey: arrayMutationKey,
		onSuccess: (...args) => {
			if (successMessage) {
				const [mutationResponse] = args;
				successToast.closeAll();
				if (isFunction(successMessage)) {
					successToast({ title: successMessage(mutationResponse) });
				} else {
					successToast(isBoolean(successMessage) ? undefined : { title: successMessage });
				}
			}

			if (!isEmpty(queriesToInvalidate) && !awaitInvalidateQueries) {
				Promise.allSettled(
					queriesToInvalidate.map((queryKey) => client.invalidateQueries(queryKey))
				);
			}

			// pass all arguments to the original onSuccess handler
			options?.onSuccess?.(...args);
		},
		onError: (...args) => {
			console.log(...args);
			if (errorMessage) {
				const shouldShowDefaultError = isBoolean(errorMessage);
				errorToast.closeAll();
				if (shouldShowDefaultError) {
					errorToast();
				} else if (isFunction(errorMessage)) {
					const [axiosError] = args;
					errorToast({ title: errorMessage(axiosError as unknown as AxiosError) });
				} else {
					errorToast({ title: errorMessage });
				}
			}

			// pass all arguments to the original onError handler
			options?.onError?.(...args);
		},
	});

	return {
		...mutationResult,
		/**
		 * if you pass `mutationKey` `isMutating` will be true if any mutation from
		 * the same `mutationKey` is called and is not finished yet from anywhere in the app
		 *
		 * ```ts
		 * const isMutating = ReactQuery.useIsMutating({ mutationKey: options.mutationKey });
		 * ```
		 */
		isMutating: mutationKey ? Boolean(isMutating) : false,
	};
};
