import { orderBy, reject } from 'lodash-es';

import { DASS_21_TITLE } from '@/forms/documents/Dass21/Dass21';

import { AUTO_ASSIGNABLE_DASS_21 } from './forms';
import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-assignments';

type ClientAssignmentDataParams = {
	clientId: ID;
};

export type AssignedForm = Assignment & {
	nellie_form: {
		formURL?: string;
		form_short_description?: string;
		form_start_button_text?: string;
		form_title: string;
		form_title_client: string;
		id: ID;
		is_preconfigurable?: boolean;
		type: FormType;
	};
};

export type ClientAssignmentData = {
	assignedForms: AssignedForm[];
	clientInfo: _Person;
};

const mergeDass21AndAutoAssignableDass21 = (
	assignedForms: AssignedForm[],
	form_title_client = DASS_21_TITLE
) => {
	const dass21Form = assignedForms?.find((form) => form.nellie_form.form_title === DASS_21_TITLE);
	const autoAssignedDass21Form = assignedForms?.find(
		(form) => form.nellie_form.form_title === AUTO_ASSIGNABLE_DASS_21
	);

	if (dass21Form && autoAssignedDass21Form) {
		assignedForms = reject(assignedForms, (form) => form.nellie_form.form_title === DASS_21_TITLE);
	}

	if (autoAssignedDass21Form) {
		assignedForms = assignedForms.map((form) => {
			if (form.nellie_form.form_title === AUTO_ASSIGNABLE_DASS_21) {
				return {
					...form,
					nellie_form: {
						...form.nellie_form,
						form_title_client,
					},
				};
			}

			return form;
		});
	}

	return orderBy(
		assignedForms,
		({ nellie_form }) => nellie_form.form_title_client || nellie_form.form_title
	);
};

const getClientAssignmentData = async (params: ClientAssignmentDataParams) => {
	const { assignedForms, clientInfo } = await httpClient.post<ClientAssignmentData>(
		`${BASE_URL}/getClientAssignmentData`,
		params
	);

	return {
		assignedForms: mergeDass21AndAutoAssignableDass21(assignedForms),
		clientInfo,
	};
};

const findAssignedForms = async () =>
	httpClient
		.post<AssignedForm[]>(`${BASE_URL}/findAssignedForms`)
		.then((forms) => mergeDass21AndAutoAssignableDass21(forms, AUTO_ASSIGNABLE_DASS_21));

const getStaticFileList = () =>
	httpClient.post<StaticFilesDirectory>(`${BASE_URL}/getStaticFileList`);

type AssignStaticFileParams = {
	clientId: number;
	filename: string;
	isChecked: boolean;
};
const addDeleteStaticFileAssignment = (params: AssignStaticFileParams) =>
	httpClient.post(`${BASE_URL}/addDeleteStaticFileAssignment`, params);

const upload = (files: any, config?: any) => httpClient.post(`${BASE_URL}/upload`, files, config);

export type StaticFileAssignments = { filename: string; id: ID };
const getStaticAssignedList = async (clientId?: number) => {
	if (!clientId)
		return await httpClient.post<StaticFileAssignments[]>(`${BASE_URL}/getStaticAssignedList`);

	return await httpClient.post<StaticFileAssignments[]>(`${BASE_URL}/getStaticAssignedList`, {
		clientId,
	});
};

type GetStaticFileParams = { filename: string };
const getStaticFile = (params: GetStaticFileParams) => {
	return httpClient.get(`${BASE_URL}/getStaticFile?filename=${params.filename}`, {
		responseType: 'blob',
	});
};

type GetAssignmentFileParams = {
	response_id: number;
};
const getUploadedFile = ({ response_id }: GetAssignmentFileParams) => {
	return httpClient.get(`${BASE_URL}/getUploadedFile?rid=${response_id}`, { responseType: 'blob' });
};

export type UploadedFile = { filename: string; updatedAt: Date };
export type UploadedAssignment = {
	files: UploadedFile[];
	form_results_description: string;
	id: ID;
	response_data: Record<'title' | 'filename' | 'description', string>;
	response_text: string;
	title: string;
};

type GetUploadedListParams = { clientId: number };
type GetUploadedList = (params?: GetUploadedListParams) => Promise<UploadedAssignment[]>;
const getUploadedList: GetUploadedList = (params) => {
	return httpClient.post(`${BASE_URL}/getUploadedList`, {
		getAll: true,
		clientId: params?.clientId,
	});
};

type RemoveAssignmentParams = {
	assignmentId: ID;
	clientId: ID;
};
const removeAssignment = (params: RemoveAssignmentParams) =>
	httpClient.post<Assignment>(`${BASE_URL}/removeAssignment`, {
		clientId: params.clientId,
		id: params.assignmentId,
	});

type CreateAssignmentParams = {
	clientId: ID;
	default_values?: Record<string, any>;
	formId: ID;
	retake_max?: number;
};

const createAssignment = (params: CreateAssignmentParams) => {
	return httpClient.post<Assignment>(`${BASE_URL}/createAssignment`, params);
};

const getAssignmentDefaultValues = (id: ID) => {
	return httpClient.post<Assignment>(`${BASE_URL}/getAssignmentDefaultValues`, { id });
};

const completeAssignment = (params: RemoveAssignmentParams) => {
	return httpClient.post<Assignment>(`${BASE_URL}/completeAssignment`, {
		clientId: params.clientId,
		id: params.assignmentId,
	});
};

export const assignmentsApi = {
	getClientAssignmentData,
	removeAssignment,
	createAssignment,
	completeAssignment,
	findAssignedForms,
	getStaticFileList,
	addDeleteStaticFileAssignment,
	getStaticAssignedList,
	upload,
	getStaticFile,
	getUploadedList,
	getUploadedFile,
	getAssignmentDefaultValues,
};
