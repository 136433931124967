import { useRef, useState } from 'react';

import { UseQueryOptions } from '@tanstack/react-query';
import { trim } from 'lodash-es';

import { useAppQuery } from '@/app-react-query';

import { useDebounce } from './useDebounce';

type UseTableQueryProps<T extends { [key: string]: any; total: number }> = {
	defaultPageSize?: number;
	queryFn: (
		params: Partial<
			PagingParams & {
				search: string;
			}
		>
	) => Promise<T>;
} & Omit<UseQueryOptions<T, unknown, T, any>, 'queryFn'>;

export type UseTableQueryState = ReturnType<typeof useTableQuery>[1];
export type UseTableQuerySetState = ReturnType<typeof useTableQuery>[2];

export function useTableQuery<T extends { [key: string]: any; total: number }>({
	defaultPageSize = 20,
	queryKey,
	queryFn,
	...options
}: UseTableQueryProps<T>) {
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search, 500);
	const debouncedSearchRef = useRef(debouncedSearch);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(defaultPageSize);
	const [hideInactiveUsers, setHideInactiveUsers] = useState(true);

	const queryResult = useAppQuery(
		[
			queryKey,
			{
				page,
				pageSize,
				search: debouncedSearch,
				hideInactiveUsers,
			},
		],
		() => {
			const payload = {
				page,
				pageSize,
				search: debouncedSearch,
				hideInactiveUsers,
			};

			if (debouncedSearchRef.current !== debouncedSearch) {
				// reset page to 0 if search term changes
				payload.page = 0;
				setPage(0);
				debouncedSearchRef.current = debouncedSearch;
			}

			return queryFn(payload);
		},
		{
			cacheTime: 0,
			staleTime: 0,
			...options,
		}
	);

	const totalPages = Math.ceil((queryResult?.data?.total || 0) / pageSize);
	const canNextPage = page < totalPages - 1;
	const canPreviousPage = page > 0;
	const isEmpty = queryResult.data?.data?.length === 0 && !queryResult.isLoading;

	return [
		queryResult,
		{
			search,
			page,
			pageSize,
			debouncedSearch,
			hideInactiveUsers,
			totalPages,
			canNextPage,
			canPreviousPage,
			isEmpty,
		},
		{
			setSearch: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(trim(e.target.value)),
			setPage,
			setPageSize,
			setHideInactiveUsers,
		},
	] as const;
}
