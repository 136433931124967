import { httpClient } from './http-client';

const BASE_URL = 'api/nellie-terms/';

const getTerms = () => httpClient.post<{ privacy_policy: string; terms_of_use: string }>(BASE_URL);

const updateTermsAgreement = () => httpClient.post(`${BASE_URL}updateTermsAgreement`);

export const termsApi = {
	getTerms,
	updateTermsAgreement,
};
