import React, { FC } from 'react';

import { UseSidebarCollapseResult } from '../containers/NavLayout/hooks/useSidebarCollapse';

type SidebarContextProps = {
	children: JSX.Element;
	value: UseSidebarCollapseResult;
};

const context = React.createContext<UseSidebarCollapseResult>([false, () => void 0]);

/** a hook which returns sidebar's **isCollapsed** state and **toggleIsCollapse** function  */
export const useSidebarContext = () => React.useContext(context);

export const SidebarContext: FC<SidebarContextProps> = ({ children, value }) => {
	return <context.Provider value={value}>{children}</context.Provider>;
};
