import { memo } from 'react';

import {
	CheckboxGroup as ChakraCheckboxGroup,
	CheckboxGroupProps as ChakraCheckboxGroupProps,
	Checkbox,
	Stack,
	StackDirection,
} from '@chakra-ui/react';
import { isEqual } from 'lodash-es';

import { useNellieController } from '../hooks/use-nellie-controller';
import { FormFieldProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export const CheckboxGroup = memo(CheckboxGroupInner, isEqual) as typeof CheckboxGroupInner;
export type CheckboxGroupProps = Omit<ChakraCheckboxGroupProps, 'children'> & {
	/**
	 *  render some field based on what option is selected
	 */
	additionalField?: (value: string[], options: string[]) => React.ReactNode;
	direction?: StackDirection | undefined;
	options: string[];
};

function CheckboxGroupInner<AcceptedNames extends string>({
	name,
	label,
	isDisabled,
	isRequired,
	isReadOnly,
	helperText,
	options,
	direction = ['column', 'row'],
	shouldUnregister,
	additionalField,
	...props
}: FormFieldProps<CheckboxGroupProps, AcceptedNames>) {
	const {
		field: { ref, value = [], onChange, ...field },
		fieldState,
	} = useNellieController({
		name,
		shouldUnregister,
	});

	return (
		<>
			<FieldWrapper
				errorMessage={fieldState.error?.message}
				helperText={helperText}
				isDisabled={isDisabled}
				isReadOnly={isReadOnly}
				isRequired={isRequired}
				label={label}
			>
				<Stack direction={direction} mt={3}>
					<ChakraCheckboxGroup
						colorScheme="purple"
						{...field}
						value={value || []}
						onChange={(newValue = []) => {
							onChange(
								newValue.sort((a, b) => {
									const indexA = options.indexOf(a as string);
									const indexB = options.indexOf(b as string);

									return indexA - indexB;
								})
							);
						}}
						{...props}
					>
						{options.map((option, index) => (
							<Checkbox
								key={option}
								ref={index === 0 ? ref : undefined}
								value={option}
								width="fit-content"
							>
								{option}
							</Checkbox>
						))}
					</ChakraCheckboxGroup>
				</Stack>
			</FieldWrapper>
			{additionalField?.(value, options)}
		</>
	);
}
