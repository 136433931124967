import axios, { AxiosError, AxiosResponse } from 'axios';

import auth from '@/utils/auth';

export const axiosClient = axios.create({ baseURL: process.env.REACT_APP_API_URL });
axiosClient.interceptors.request.use(async (config) => {
	const token = auth.getToken();
	if (token) {
		if (!config.headers.has('Authorization')) {
			config.headers.set('Authorization', `Bearer ${token}`);
		}
	}

	return config;
});

/**
 * It takes a function that returns a promise that resolves to an **AxiosResponse**, and returns a function
 * that returns a promise that resolves to the **data** property of the **AxiosResponse**
 */
export const axiosToHttpAdapter = <Fn extends (...args: any[]) => Promise<AxiosResponse>>(
	fn: Fn
) => {
	return async (...params: Parameters<Fn>) => {
		try {
			const { data } = await fn(...params);
			return data;
		} catch (error) {
			if (process.env.NODE_ENV === 'development') console.error(error);
			// sentry error reporting goes here

			// rethrow the error so it can be handled by the caller
			throw error;
		}
	};
};

export const getAxiosResponseErrorMessage = (error?: AxiosError) =>
	(error?.response?.data as any)?.error?.message as string;
