import { useContext, useRef } from 'react';
import { Controller } from 'react-hook-form';

import * as yup from 'yup';
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
} from '@chakra-ui/react';
import { trim } from 'lodash-es';

import { Api } from '@/api';
import { useAppMutation } from '@/app-react-query';
import { GlobalContext } from '@/context';
import { useNellieForm } from '@/forms/hooks/use-nellie-form';

import { noteTypes } from '../constants';

const schema = yup.object().shape({
	title: yup.string().required('Title is required'),
	type: yup.string().required('Type is required'),
});

export const CreateTemplateModal = ({ selectedNoteType, onClose, noteId }) => {
	const { loggedInUser, trainee } = useContext(GlobalContext);

	const form = useNellieForm({
		schema,
		defaultValues: {
			title: '',
			type: selectedNoteType,
		},
	});

	const { mutateAsync, isLoading } = useAppMutation(
		async ({ title, type }: { title: string; type: any }) => {
			if (!trim(title)) return;

			return Api.templates.createTemplate({
				type,
				title,
				note: noteId,
				clinician: trainee?.id || loggedInUser.id,
			});
		},
		{
			successMessage: 'Template created successfully',
			errorMessage: true,
			invalidateQueries: [['templates']],
			awaitInvalidateQueries: true,
			onSettled: () => {
				onClose();
			},
		}
	);

	const initialFocusRef = useRef<HTMLInputElement>(null);

	return (
		<Modal initialFocusRef={initialFocusRef} isOpen={true} size="lg" onClose={onClose}>
			<ModalOverlay />
			<ModalContent as="form" onSubmit={form.handleSubmit((values) => mutateAsync(values))}>
				<ModalHeader></ModalHeader>
				<ModalCloseButton tabIndex={-1} />
				<ModalBody as={VStack} pb={6} spacing="32px">
					<Controller
						control={form.control}
						name="title"
						render={({ field, fieldState }) => {
							return (
								<FormControl isInvalid={!!fieldState.error?.message}>
									<FormLabel fontSize="30px" fontWeight="500">
										Template Title
									</FormLabel>

									<Input autoFocus placeholder="Title" {...field} />
									{!!fieldState.error?.message && (
										<FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
									)}
								</FormControl>
							);
						}}
					/>

					<Controller
						control={form.control}
						name="type"
						render={({ field }) => {
							return (
								<FormControl>
									<FormLabel color="gray.400" fontSize="20px" fontWeight="500">
										Note type category
									</FormLabel>
									<Grid gap={2} templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}>
										{noteTypes.map((noteType) => (
											<Box
												key={noteType}
												_selected={{ bg: '#E2E8F0', color: 'brand.100' }}
												aria-selected={noteType === field.value}
												border="1px solid"
												borderColor="gray.200"
												borderRadius="md"
												cursor="pointer"
												padding="8px"
												textAlign="center"
												onClick={() => field.onChange(noteType)}
											>
												{noteType}
											</Box>
										))}
									</Grid>
								</FormControl>
							);
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="purple"
						isDisabled={isLoading}
						isLoading={isLoading}
						type="submit"
						w="100%"
					>
						Save Template
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
