import { memo } from 'react';

import { Alert, AlertIcon, Stack } from '@chakra-ui/react';

type Alert404Props = { message?: string };

export const Alert404 = memo(
	({ message = "404 Error: Sorry, we can't find the page you requested." }: Alert404Props) => {
		return (
			<Stack data-cy="404-not-found" mt={5} pl={20} pr={20} spacing={3}>
				<Alert status="error">
					<AlertIcon />
					{message}
				</Alert>
			</Stack>
		);
	}
);
