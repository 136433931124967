import { Input as ChakraInput, InputProps } from '@chakra-ui/react';

import { useNellieController } from '../hooks/use-nellie-controller';
import { FormFieldProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export function Input<AcceptedNames extends string>({
	name,
	label,
	isDisabled,
	isRequired,
	isReadOnly,
	helperText,
	shouldUnregister,
	...inputProps
}: FormFieldProps<InputProps, AcceptedNames>) {
	const { field, fieldState } = useNellieController({
		name,
		shouldUnregister,
	});

	return (
		<FieldWrapper
			errorMessage={fieldState.error?.message}
			helperText={helperText}
			isDisabled={isDisabled}
			isReadOnly={isReadOnly}
			isRequired={isRequired}
			label={label}
		>
			<ChakraInput {...inputProps} {...field} value={field.value || ''} />
		</FieldWrapper>
	);
}
