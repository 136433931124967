import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
	semanticTokens: {
		colors: {
			'tokens.brand': {
				default: 'brand.100',
				_dark: 'teal.900',
			},
			'tokens.white': {
				default: 'grey.50',
				_dark: 'gray.700',
			},
			gray200: {
				default: 'gray.100',
				_dark: 'gray.600',
			},
		},
	},
	colors: {
		brand: {
			100: '#3A38A6',
		},
	},
	config: {
		fonts: {
			heading: 'Open Sans',
			body: 'Raleway',
		},
	},
});
export default theme;
