import { Suspense } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Select as ChakraSelect } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

const SelectUI = lazy(() => import('./SelectUI'));

export type { SelectProps } from './SelectUI';
export const Select: typeof SelectUI = (props) => (
	<Suspense fallback={<ChakraSelect />}>
		<SelectUI {...props} />
	</Suspense>
);
