import { FormikValues } from 'formik';
import { reject } from 'lodash-es';

import { ProgressStatus } from '@/containers/FormProvider';
import { DASS_21_TITLE } from '@/forms/documents/Dass21/Dass21';
import auth from '@/utils/auth';

import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-form';

export type FormResponse = {
	attributes: Attributes;
	id: ID;
};

type GetFormParams = {
	title?: string;
};

export const AUTO_ASSIGNABLE_DASS_21 = 'Symptom Questionnaire';

export type GetFormsResponse = Awaited<ReturnType<typeof getForms>>;
const getForms = async ({ title }: GetFormParams = {}) => {
	let stringParams = `${title ? 'form_title=' + title : ''}`.trim();
	stringParams = stringParams.length > 0 ? `?${stringParams}` : '';

	const forms = await httpClient.get<Attributes[]>(`${BASE_URL}s${stringParams}`);

	// AUTO_ASSIGNABLE_DASS_21 is a special case where we want to exclude it from the list of forms
	// because it is auto assigned to clients
	return reject(forms, { form_title: AUTO_ASSIGNABLE_DASS_21 });
};

const getGeneralInfoForm = async (): Promise<Attributes> => {
	const [generalInfoForm] = await getForms({ title: 'Intake Questionnaire' });

	return generalInfoForm;
};

const getDass21Form = async (): Promise<Attributes> => {
	const [form] = await getForms({ title: DASS_21_TITLE });

	return form;
};

export type GetFormByIdResponse = Awaited<ReturnType<typeof getFormById>>;

const getFormById = async (id: ID) => {
	const { data } = await httpClient.get<{
		data: FormResponse;
		meta: Record<string, any>;
	}>(`${BASE_URL}s/${id}`);
	return data;
};

export type ClientResponsesScore = {
	[key: string]:
		| number
		| null
		| {
				color?: string;
				title?: string;
				value: number;
		  };
};

export type ClientResponses = {
	[formTitle: string]: {
		[key: string]: any;
		createdAt: string;
		id: ID;
		nellie_form: NellieForm;
		score: ClientResponsesScore;
	}[];
};

const getClientResponses = (clientId: ID) => {
	return httpClient.post<ClientResponses>(
		`${BASE_URL}-responses/getClientResponses?status=completed`,
		{
			client_id: clientId,
		}
	);
};

const getResponsesGraphList = async ({ client_id, form_id }) => {
	const apiEndpoint = client_id ? 'getClientResponsesGraphList' : 'getOwnResponsesGraphList';

	type Response = {
		data: number[] | ClientResponsesScore[];
		labels: string[];
	};

	return httpClient.post<Response>(`${BASE_URL}-responses/${apiEndpoint}`, {
		client_id: client_id ?? undefined,
		form_id,
	});
};
const getOwnResponses = () => {
	return httpClient.post<ClientResponses>(`${BASE_URL}-responses/getOwnResponses?status=completed`);
};

const getOwnInProgressResponses = () => {
	return httpClient.post<ClientResponses>(
		`${BASE_URL}-responses/getOwnResponses?status=in progress`
	);
};

const getResponseNotes = async (response_id: number) => {
	return httpClient.post<Note[]>(`${BASE_URL}-responses/getResponseNotes`, {
		client_id: auth.getClientID(),
		response: response_id,
	});
};

export type GetFormReviewParams = {
	response_id: ID;
};
export type GetClientFormReviewParams = GetFormReviewParams & {
	client_id: ID;
};

const getClientFormReview = (params: GetClientFormReviewParams) => {
	return httpClient.post<NellieFormResponse>(`${BASE_URL}-responses/clientFormReview`, params);
};

const getFormReview = (params: GetFormReviewParams) => {
	return httpClient.post<NellieFormResponse>(`${BASE_URL}-responses/formReview`, params);
};

type SaveFormResponseParams = {
	assignment_id: number;
	form_id: number;
	isScored: boolean;
	response_data: FormikValues;
	response_id: number | null;
	retake_count?: number;
	score?: Record<string, any>;
	status: ProgressStatus;
	type: FormType;
	user_id?: number;
};
const saveFormResponse = (data: SaveFormResponseParams) => {
	return httpClient.post<number>(`${BASE_URL}-responses/saveFormResponse`, { ...data });
};

export const formsApi = {
	getFormById,
	getGeneralInfoForm,
	getDass21Form,
	getForms,
	getClientResponses,
	getResponsesGraphList,
	getOwnResponses,
	getOwnInProgressResponses,
	getClientFormReview,
	getFormReview,
	saveFormResponse,
	getResponseNotes,
};
