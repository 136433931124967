import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { ColorModeScript } from '@chakra-ui/react';
import { Integrations } from '@sentry/tracing';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(container!);

if (process.env.REACT_APP_SENTRY_ENV === 'production') {
	Sentry.init({
		dsn: 'https://13fd4ee1923e41a69ec687f4c3790dbb@o1116761.ingest.sentry.io/6150467',
		integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
		environment: process.env.REACT_APP_SENTRY_ENV,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		beforeSend(event, hint) {
			// Check if it is an exception, and if so, show the report dialog
			if (event.exception) {
				Sentry.showReportDialog({
					eventId: event.event_id,
					title: "It seems like you've encountered a bug.",
				});
			}
			return event;
		},
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.3,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
	});
}

root.render(
	<React.Fragment>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<App />
	</React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
