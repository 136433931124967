import { createContext, useContext, useState } from 'react';
import { Container as ModalContainer } from 'react-modal-promise';
import { BrowserRouter as Router } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AgreementModalProvider } from './components';
import AllRoutes from './containers/AllRoutes';
import NavLayout from './containers/NavLayout/NavLayout';
import { GlobalProvider } from './context/GlobalState';
import theme from './theme';

export const queryClient = new QueryClient();

const notificationsContext = createContext({
	setUnreadMessagesCount: () => {
		throw new Error('not implemented');
	},
} as unknown as {
	setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
	unreadMessagesCount: number;
});
notificationsContext.displayName = 'notificationsContext';

export const useUnreadMessagesContext = () => useContext(notificationsContext);

function App() {
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

	// Function to clear complete cache data
	// const clearCacheData = () => {
	// 	caches.keys().then((names) => {
	// 		names.forEach((name) => {
	// 			caches.delete(name);
	// 		});
	// 	});
	// 	console.log('Complete Cache Cleared');
	// };

	// clearCacheData();

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<notificationsContext.Provider value={{ unreadMessagesCount, setUnreadMessagesCount }}>
					<GlobalProvider>
						<ModalContainer />
						<AgreementModalProvider>
							<Router>
								<NavLayout>
									<AllRoutes />
								</NavLayout>
							</Router>
						</AgreementModalProvider>
					</GlobalProvider>
				</notificationsContext.Provider>
			</ChakraProvider>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
		</QueryClientProvider>
	);
}

export default App;
