import { memo, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Text } from '@chakra-ui/react';

import { queryClient } from '@/App';
import { Api } from '@/api';
import { openConfirmationModal, openSignatureModal } from '@/components/ui';
import { GlobalContext } from '@/context';

import { useNoteApi } from '../hooks';

export const AmendButton = memo(() => {
	const { handleSubmit } = useFormContext<Note & { newAmendment: string }>();
	const { loggedInUser } = useContext(GlobalContext);
	const [, setSearchParams] = useSearchParams();

	const { amendNote } = useNoteApi();

	const onAmend = useMemo(() => {
		return handleSubmit((values) => {
			const onSubmit = async () => {
				await amendNote({ id: values.id, amendment: values.newAmendment });

				setSearchParams((url) => {
					url.delete('amend');
					return url;
				});
			};

			if (loggedInUser.signature) {
				return openConfirmationModal({
					title: 'Amend Note',
					body: 'Are you sure you want to amend this note?',
					initialFocusedButton: 'submit',
					onSubmit,
				});
			}

			return openSignatureModal({
				header: (
					<Box w="90%">
						<Text fontWeight="light">Please provide your signature here to amend the note.</Text>
						<Text fontWeight="light">
							This signature will be used for all future signings and amendments, but you can update
							it in your user settings if necessary.
						</Text>
					</Box>
				),
				submitBtnText: 'Sign and Finalize',
				onSubmit: async (signature) => {
					await Api.users.addSignature(signature);

					await Promise.all([onSubmit(), queryClient.invalidateQueries(['Api.users.getMyInfo'])]);
				},
			});
		});
	}, [handleSubmit, loggedInUser.signature, amendNote, setSearchParams]);

	return (
		<Button _active={{}} _hover={{ bg: '#1D1671' }} bg="#3A38A6" color="white" onClick={onAmend}>
			Amend
		</Button>
	);
});
