import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-stripe-';

const addPaymentInformation = async () => {
	return httpClient.post<string>(`${BASE_URL}logs/addPaymentInformation`);
};

const stripeCustomerPortal = async () => {
	return httpClient.post<string>(`${BASE_URL}logs/stripeCustomerPortal`);
};

type SendInvoiceParams = {
	id: number;
	productId: ID;
	sessionDate: string;
	sessionMemo: string;
};

const sendInvoice = (params: SendInvoiceParams) => {
	return httpClient.post(`${BASE_URL}logs/sendInvoice`, params);
};

export type GetProductsResponse = {
	createdAt: string;
	currency: string;
	description: string;
	duration: number;
	id: ID;
	jurisdiction: string;
	owner: string;
	price_amount: string;
	pricing_tier: number;
	stripe_price_id: string;
	stripe_product_id: string;
	type?: null;
	updatedAt: string;
}[];

const getProducts = () => {
	return httpClient.post<GetProductsResponse>(`${BASE_URL}products/getProducts`);
};

export type GetStripeClientListResponse = {
	clientId: number;
	hasCreditCardOnFile: boolean;
}[];

export const stripeApi = {
	stripeCustomerPortal,
	addPaymentInformation,
	getProducts,
	sendInvoice,
};
