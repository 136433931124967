import { memo, useContext } from 'react';
import { Controller, FormProvider, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Badge,
	Box,
	CardBody,
	CardHeader,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	VStack,
	useMediaQuery,
} from '@chakra-ui/react';
import { DevTool } from '@hookform/devtools';
import { concat, find, isEmpty, map, trim } from 'lodash-es';

import { Dropzone, Editor, FileListItem } from '@/components/ui';
import { SCROLLABLE_CLASSNAME } from '@/containers/NavLayout/utils/constants';
import { GlobalContext } from '@/context';
import { formatDate, getFullName, isFile } from '@/utils';

import { noteTypes } from '../../constants';

import { NoteItemActionsMenu } from './NoteItemActionsMenu';
import { NoteItemSections } from './NoteItemSections';
import { SaveDraftButton, SignButton, SignaturesLogButton } from './buttons';
import { AmendButton } from './buttons/AmendButton';
import { NotesApiProvider, useNoteApi, useNoteForm } from './hooks';

export type NoteItemProps = {
	canAddAttachment?: boolean;
	createButton?: (handleSubmit: UseFormHandleSubmit<NoteItemFormValues>) => JSX.Element;
	form?: UseFormReturn<NoteItemFormValues, { isAmending: boolean }>;
	header?: JSX.Element;
	isAmending?: boolean;
	isReadOnly?: boolean;
	isUpload: boolean;
	keepDirtyValues?: boolean;
	onSelectTemplate?: (template: Template) => void;
	onSelectType?: (type: NoteType) => void;
	templates?: (Template & { selected?: boolean })[];
	values: Note;
};

export const newSection = {
	order: 0,
	title: '',
	description: '',
};

export type NoteItemFormValues = {
	attachments?: Note['attachments'];
	author?: Clinician['id'];
	id?: Note['id'];
	newAmendment?: string;
	sections?: Note['sections'];
	status: Note['status'];
	title: Note['title'];
	type: Note['type'];
};

export const NoteItem = memo((props: NoteItemProps) => {
	return (
		<NotesApiProvider>
			<NoteItemInner {...props} />
		</NotesApiProvider>
	);
});

const NoteItemInner = memo<NoteItemProps>(
	({
		keepDirtyValues,
		isUpload,
		values: { amendments, author, updatedAt, createdAt, ...values },
		templates,
		onSelectTemplate,
		onSelectType,
		isAmending = false,
		createButton,
		header,
		canAddAttachment,
		isReadOnly,
	}) => {
		const [isMobileView] = useMediaQuery('(max-width: 479px)');
		const { loggedInUser } = useContext(GlobalContext);
		const { updateNote, downloadNoteAttachment, refetchNotes } = useNoteApi();

		const form = useNoteForm({
			values: {
				id: values.id,
				status: values.status,
				title: values.title,
				type: values.type,
				attachments: values.attachments,
				newAmendment: undefined,
				sections: values.sections,
				author: author?.id,
			},
			...(keepDirtyValues && {
				resetOptions: {
					keepDirtyValues,
				},
			}),
			context: { isAmending },
		});

		// @ts-ignore
		const [id, title, status, selectedType, attachments] = form.watch(
			['id', 'title', 'status', 'type', 'attachments'],
			values
		);

		const selectedTemplateTitle = find(templates, { selected: true })?.title;

		const isCreateMode = !id;
		const noteIsDraft = Boolean(status === 'Draft' && id);
		const noteIsPublished = status === 'Published';
		const noteIsArchived = status === 'Archived';

		const noteTypeBadge = selectedType && (
			<Controller
				name="type"
				render={({ field }) => {
					const selectedType = field.value;
					return (
						<Badge
							alignItems="center"
							as={Box}
							borderRadius="md"
							colorScheme={
								{
									Other: 'orange',
									Telephone: 'purple',
									Treatment: 'green',
									Upload: 'blue',
								}[selectedType]
							}
							cursor="pointer"
							display="flex"
							fontSize="md"
							gap="10px"
							px="2"
							py="1"
						>
							{selectedType} {(isCreateMode || noteIsDraft) && <ChevronDownIcon />}
						</Badge>
					);
				}}
			/>
		);
		return (
			<FormProvider {...form}>
				<DevTool control={form.control} />

				{header || (
					<CardHeader p={{ base: '3.5', md: '5' }}>
						<Flex
							alignItems="start"
							flexWrap={{ base: 'wrap', sm: 'initial' }}
							gap="10px"
							justifyContent="space-between"
						>
							<HStack w="100%">
								<Controller
									name="title"
									render={({ field, fieldState }) => (
										<FormControl isInvalid={Boolean(fieldState.error?.message)}>
											<Input
												_readOnly={{ pl: 0, outline: 'none', boxShadow: 'none' }}
												border={noteIsPublished || noteIsArchived ? 'none' : undefined}
												flex="1"
												fontSize="2xl"
												isReadOnly={isReadOnly || noteIsPublished || noteIsArchived}
												placeholder='Note title (e.g. "Initial Assessment")'
												{...field}
												onBlur={
													noteIsDraft
														? async () => {
																const isValid = await form.trigger('title');
																if (isValid && trim(form.getValues('title'))) {
																	return updateNote({
																		id: form.getValues('id') as ID,
																		title: form.getValues('title'),
																	}).then(refetchNotes);
																}
														  }
														: field.onBlur
												}
											/>
											<FormErrorMessage fontWeight="semibold">
												Note title is required
											</FormErrorMessage>
										</FormControl>
									)}
								/>
								{isMobileView && (
									<NoteItemActionsMenu
										fontSize="2xl"
										note={
											{
												id,
												title,
												status,
												type: selectedType,
											} as Note
										}
									/>
								)}
							</HStack>

							<HStack align="center" flex={1}>
								{selectedType !== 'Upload' &&
									isCreateMode &&
									(createButton ? createButton(form.handleSubmit) : <SaveDraftButton flex={1} />)}
								{!createButton && (noteIsDraft || isCreateMode) && selectedType !== 'Upload' && (
									<SignButton canSign={loggedInUser.supervisors?.length === 0} />
								)}
								{isAmending && <AmendButton />}
								{!isMobileView && (
									<NoteItemActionsMenu
										fontSize="2xl"
										note={
											{
												id,
												title,
												status,
												type: selectedType,
											} as Note
										}
									/>
								)}
							</HStack>
						</Flex>
						{selectedType !== 'Assignment' && (
							<HStack align="start" my="4" spacing="10px" w="100%">
								{isCreateMode || noteIsDraft ? (
									<Menu>
										<MenuButton
											_expanded={{ borderColor: 'unset' }}
											_hover={{ borderColor: 'unset' }}
											border="1px solid"
											borderColor="transparent"
											borderRadius="md"
										>
											{noteTypeBadge}
										</MenuButton>
										<MenuList maxH="300px" overflow="scroll">
											{map(concat(noteTypes, isCreateMode ? ['Upload' as const] : []), (type) => (
												<MenuItem
													key={type}
													_selected={{ bg: 'gray.200' }}
													aria-selected={selectedType === type}
													onClick={() => {
														form.setValue('type', type);
														if (onSelectType) {
															onSelectType(type);
														} else if (form.getValues('id')) {
															updateNote({ id: form.getValues('id') as ID, type }).then(
																refetchNotes
															);
														}
													}}
												>
													{type}
												</MenuItem>
											))}
										</MenuList>
									</Menu>
								) : (
									noteTypeBadge
								)}

								{!isEmpty(templates) && (
									<Menu>
										<MenuButton
											_expanded={{ borderColor: 'unset' }}
											_hover={{ borderColor: 'unset' }}
											border="1px solid"
											borderColor="transparent"
											borderRadius="md"
										>
											<Badge
												alignItems="center"
												as={Box}
												borderRadius="md"
												display="flex"
												fontSize="md"
												gap="10px"
												px="2"
												py="1"
											>
												Templates{' '}
												{selectedTemplateTitle ? ` - ${selectedTemplateTitle}` : undefined}
												<ChevronDownIcon />
											</Badge>
										</MenuButton>
										<MenuList
											className={SCROLLABLE_CLASSNAME}
											maxH="400px"
											overflowY="scroll"
											py="0"
											w="400px"
										>
											{map(templates, (template) => (
												<MenuItem
													key={template.id}
													_selected={{ bg: 'gray.200' }}
													aria-selected={template.selected}
													onClick={() => {
														onSelectTemplate?.(template);
													}}
												>
													{template.title}
												</MenuItem>
											))}
										</MenuList>
									</Menu>
								)}

								<Text as="div" color="gray.400" flex="auto" fontSize="sm" textAlign="end">
									{author && (
										<div>
											<b>Author:</b> {getFullName(author)} ({formatDate(createdAt)})
										</div>
									)}
									{updatedAt && (
										<div>
											<b>Last saved:</b> {formatDate(updatedAt)}
										</div>
									)}
									{id && <SignaturesLogButton />}
								</Text>
							</HStack>
						)}
						<Divider color="gray.300" />
					</CardHeader>
				)}
				<CardBody
					as={VStack}
					className={SCROLLABLE_CLASSNAME}
					overflow="scroll"
					p={{ base: '3.5', md: '5' }}
					spacing="20px"
				>
					{isAmending && (
						<Controller
							shouldUnregister
							name="newAmendment"
							render={({ field, fieldState }) => (
								<FormControl isInvalid={Boolean(fieldState.error?.message)}>
									<Box mb="10" w="100%">
										<Editor
											autoHideToolbar={false}
											isInvalid={Boolean(fieldState.error?.message)}
											placeholder="Add you amendment here..."
											{...field}
										/>
										<FormErrorMessage fontWeight="bolder">
											{fieldState.error?.message}
										</FormErrorMessage>
									</Box>
								</FormControl>
							)}
						/>
					)}
					{!isEmpty(attachments) && (
						<Box w="100%">
							{attachments?.map((attachment) =>
								isFile(attachment) ? (
									<></>
								) : (
									<FileListItem
										key={attachment.id}
										date={attachment.updatedAt}
										filename={attachment.filename}
										title={attachment.label}
										onClick={() => downloadNoteAttachment(attachment)}
									/>
								)
							)}
						</Box>
					)}
					{!isEmpty(amendments) && (
						<VStack spacing="20px" w="100%">
							{map(amendments, (amendment) => (
								<Editor
									key={amendment.id}
									isReadOnly
									bg="gray200"
									value={
										`<i><b>Amended</b> ${formatDate(amendment.createdAt)}</i>` +
										amendment.description
									}
								/>
							))}
						</VStack>
					)}
					{isUpload ? (
						<Box h="100%" w="100%">
							<Controller
								name="attachments"
								render={({ field }) => (
									<Dropzone
										footer={
											<SaveDraftButton
												isDisabled={isEmpty(field.value)}
												px="60px"
												size="lg"
												w="100%"
											>
												UPLOAD
											</SaveDraftButton>
										}
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
						</Box>
					) : (
						<NoteItemSections
							canAddAttachment={canAddAttachment}
							isReadOnly={isReadOnly || status !== 'Draft'}
						/>
					)}
				</CardBody>
			</FormProvider>
		);
	}
);
