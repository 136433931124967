import { useFormikContext } from 'formik';

import { PhoneInput, PhoneInputProps } from '@/components/ui';

export type FormikPhoneInputProps = Omit<PhoneInputProps, 'onChange' | 'onBlur'> & { name: string };

export function FormikPhoneInput({ name, ...props }: FormikPhoneInputProps) {
	const { setFieldValue, setFieldTouched } = useFormikContext();

	return (
		<PhoneInput
			{...props}
			onBlur={() => {
				setFieldTouched(name);
			}}
			onChange={(value) => {
				setFieldValue(name, value);
			}}
		/>
	);
}
