import { Suspense } from 'react';

import { Skeleton, VStack } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

import { SIGNATURE_PAD_DEFAULT_SIZE, SignaturePadProps } from './SignaturePadUI';

const SignaturePadUI = lazy(() => import('./SignaturePadUI'));

export type { SignaturePadProps } from './SignaturePadUI';

export const SignaturePad = (props: SignaturePadProps) => (
	<Suspense
		fallback={
			<VStack alignItems="end">
				<Skeleton
					height={((props.size || SIGNATURE_PAD_DEFAULT_SIZE) / 16) * 9}
					width={props.size || SIGNATURE_PAD_DEFAULT_SIZE}
				/>
				<Skeleton w="80px">?</Skeleton>
			</VStack>
		}
	>
		<SignaturePadUI {...props} />
	</Suspense>
);
