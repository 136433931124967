import { lazy } from 'react';

const lazyImport = (formName: string) => lazy(() => import(`../documents/${formName}/${formName}`));

export const documents = {
	StuckPointLog: lazyImport('StuckPointLog'),
	BigPictureWorksheet: lazyImport('BigPictureWorksheet'),
	IntakeQuestionnaire: lazyImport('IntakeQuestionnaire'),
	ThoughtEmotionBehavior: lazyImport('ThoughtEmotionBehaviour'),
	SatisfactionQuestions: lazyImport('SatisfactionQuestions'),
	Dass21: lazyImport('Dass21'),
	DartQuestionnaire: lazyImport('DartQuestionnaire'),
	SymptomQuestionnaire: lazyImport('SymptomQuestionnaire'),
	AnxietySensitivityIndex3: lazyImport('AnxietySensitivityIndex3'),
	HealthAnxietyIndex: lazyImport('HealthAnxietyIndex'),
	DERSII: lazyImport('DERS-II'),
	ConsentToDisclosePersonalHealthInformation: lazyImport(
		'ConsentToDisclosePersonalHealthInformation'
	),
	MPS: lazyImport('MPS'),
	SessionRatingScale: lazyImport('SessionRatingScale'),
};
