import {
	Box,
	Center,
	Circle,
	Flex,
	HStack,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

type StepProps = {
	desc: string;
	isClicked: boolean;
	isDisabled_: boolean | undefined;
	onClick: () => void;
	qid: string;
	rating: string;
	state: 'complete' | 'active' | 'incomplete';
};
export const Step = (props: StepProps) => {
	const { desc, rating, state, onClick, isClicked, qid, isDisabled_ } = props;
	const { setFieldValue, setFieldTouched } = useFormikContext();

	// const isIncompleted = state === 'incomplete';
	const isCompleted = state === 'complete';
	const isActive = state === 'active';

	const accentColor = useColorModeValue('blue.500', 'blue.100');
	const mutedColor = useColorModeValue('gray.600', 'gray.150');
	const activeColor = useColorModeValue('white', 'black');

	const completedBoxBg = useColorModeValue('gray.300', 'gray.300');
	const incompletedBoxBg = useColorModeValue('gray.200', 'gray.100');

	const completedCircleBg = useColorModeValue('gray.400', 'gray.400');
	const inCompletedCircleBg = useColorModeValue('gray.300', 'gray.300');

	const cursorStyle = isDisabled_ ? 'default' : undefined;

	return (
		<Box>
			<HStack>
				<button
					style={{ cursor: cursorStyle }}
					onClick={(e) => {
						e.preventDefault();
						if (isDisabled_) return;
						setFieldTouched(qid, true);
						setFieldValue(qid, rating);
						onClick();
					}}
				>
					<Center
						alignContent="center"
						bg={
							state === 'active'
								? completedBoxBg
								: state === 'complete'
								? completedBoxBg
								: incompletedBoxBg
						}
						h="50px"
						justifyContent="center"
						minW="40px"
					>
						<Circle
							bg={
								!isClicked
									? inCompletedCircleBg
									: isActive
									? completedCircleBg
									: isCompleted
									? completedCircleBg
									: inCompletedCircleBg
							}
							borderColor="gray.300"
							color={isActive ? activeColor : isCompleted ? accentColor : mutedColor}
							fontWeight="bold"
							size="8"
						>
							{rating}
						</Circle>
					</Center>
				</button>

				<Flex direction="row" justifyContent="flex-end" minW="40px" w="40px">
					<Box>
						<Heading fontSize="lg" fontWeight="bold" lineHeight="1.1">
							{rating}
						</Heading>
					</Box>
				</Flex>
				<Box pl="3" w={[250, 500, 800]}>
					<Text lineHeight="1.2">{desc}</Text>
				</Box>
			</HStack>
		</Box>
	);
};
