import { memo } from 'react';

import { Box, CSSObject, Flex, Stack, Textarea, VStack } from '@chakra-ui/react';
import { CSSProperties } from '@emotion/serialize';
import { FastField, FieldProps } from 'formik';

import { InputWrapper } from './InputWrapper';

export type TextareaSplitProps = {
	_disabled?: CSSObject;
	borderColor?: CSSProperties['borderColor'];
	isDisabled_?: boolean;
	saveOnBlur?: (values: unknown) => void;
	saveSpace?: boolean;
	thisElement: any;
};

export const TextareaSplit = memo<TextareaSplitProps>(
	({ thisElement, saveSpace = false, isDisabled_ = false, saveOnBlur, borderColor, _disabled }) => {
		const {
			qid,
			text,
			responseLabels,
			style: { textalign, breakPoints, rows, textareaResize },
		} = thisElement;

		return (
			<>
				<Box alignSelf="flex-start">
					<label htmlFor="">{text}</label>
				</Box>
				<Stack alignItems="baseline" direction={breakPoints} spacing="10px" w="100%">
					{responseLabels.map((label, index) => {
						const qidIndex = `${qid}-${index}`;

						return (
							<InputWrapper
								key={qidIndex}
								borderColor={borderColor}
								fieldName={qidIndex}
								isDisabled_={isDisabled_}
								saveOnBlur={saveOnBlur}
								saveSpace={saveSpace}
								showQuestionText={false}
								thisElement={thisElement}
							>
								<Flex align={textalign} direction="column" justify="center" width="100%">
									{/* label */}
									<VStack spacing="0">
										{label.map((lbl, i) => (
											<label key={i} htmlFor="">
												{lbl}
											</label>
										))}
									</VStack>
									<FastField mt="4" name={qidIndex}>
										{({ field, form }: FieldProps) => (
											<Textarea
												_disabled={_disabled}
												borderColor="gray.400"
												isDisabled={isDisabled_}
												isInvalid={field.name in form.errors && field.name in form.touched}
												resize={textareaResize}
												rows={rows}
												{...field}
											/>
										)}
									</FastField>
								</Flex>
							</InputWrapper>
						);
					})}
				</Stack>
			</>
		);
	}
);
