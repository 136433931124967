import { Suspense, lazy, memo } from 'react';

import {
	Box,
	Button,
	Stack,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	StepSeparator,
	StepStatus,
	Stepper,
	Text,
	useConst,
	useSteps,
} from '@chakra-ui/react';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';

import { FormElementType } from '@/@types/enums';
import { useIsFormPreviewModalOpen } from '@/components/FormPreviewModal/FormPreviewModal';
import { CustomSpinner, Markdown } from '@/components/ui';
import { FormEngineV2, isFormEngineV2 } from '@/forms/core/FormEngineV2';

import { CBWorksheet } from './FormComponents/CBWorksheet';
import { FormElement } from './FormComponents/FormElement';
import { useIsFormReviewModalOpen } from './FormModal';

const ConsentTreatment = lazy(() => import('./FormComponents/ConsentTreatment'));

type FormBuilderProps = {
	formBuilder: FormBuilder;
	saveOnBlur?: (values: unknown) => void;
};

export const FormBuilder = memo<FormBuilderProps>((props) => {
	if (isFormEngineV2(props.formBuilder)) {
		return <FormEngineV2 Component={props.formBuilder.form_data.Component as string} />;
	}

	return <FormBuilderInner {...props} />;
});

const FormBuilderInner = ({ formBuilder, saveOnBlur }: FormBuilderProps) => {
	const isPreviewModalOpen = useIsFormPreviewModalOpen();
	const isReviewModalOpen = useIsFormReviewModalOpen();

	const showSinglePage = isPreviewModalOpen || isReviewModalOpen;

	const { form_data, form_title, form_instructions, isDisabled_, createdAt, form_type } =
		formBuilder;
	const { elements, formHeader: { formName } = {} } = form_data;
	const { handleSubmit, isSubmitting, isValid, dirty, errors, initialValues } = useFormikContext();

	const paginatedElements = useConst(() => {
		if (showSinglePage) return [elements.filter((el) => el.type !== FormElementType.pageBreak)];

		return elements.reduce(
			(pages, element) => {
				if (element.type === FormElementType.pageBreak) {
					const newPage = [];
					pages.push(newPage);
				} else {
					const lastPage = pages[pages.length - 1];
					lastPage.push(element);
				}

				return pages;
			},
			[[]] as (typeof elements)[]
		);
	});

	const {
		activeStep: currentPage,
		goToNext,
		goToPrevious,
		setActiveStep,
	} = useSteps({
		index: (() => {
			if (showSinglePage) return 0;

			const errorPageIndex = paginatedElements.findIndex((page) =>
				page.some((element) => {
					// if element is not required, then it's not an error
					return !(initialValues as Record<string, any>)?.[element.qid] && fieldIsRequired(element);
				})
			);

			return errorPageIndex !== -1 ? errorPageIndex : 0;
		})(),
		count: paginatedElements.length,
	});

	const isLastPage = currentPage === paginatedElements.length - 1;

	switch (formName) {
		case 'cbw':
			return (
				<Form autoComplete="off" onSubmit={handleSubmit}>
					<Stack alignItems="center" spacing="12">
						<CBWorksheet
							isDisabled_={isDisabled_}
							jsonData={form_data as any}
							saveOnBlur={saveOnBlur}
						/>
						{!isDisabled_ && (
							<Button
								colorScheme="teal"
								isLoading={isSubmitting}
								maxW="600"
								minW="200"
								mt={4}
								size="lg"
								textAlign="center"
								type="submit"
							>
								Submit
							</Button>
						)}
					</Stack>
				</Form>
			);
		default: {
			if (form_type === 'consent') {
				return (
					<Suspense fallback={<CustomSpinner />}>
						<ConsentTreatment
							createdAt={createdAt}
							elements={elements}
							formIsValid={dirty && isValid}
							instructions={form_instructions}
							isSubmitting={isSubmitting}
							title={form_title}
							onSubmit={handleSubmit}
						/>
					</Suspense>
				);
			}

			const handlePageChange = () => {
				const pageErrorKeys = paginatedElements[currentPage].reduce((keys, element) => {
					if (element.type === FormElementType.pageBreak) {
						return keys;
					}

					if (errors[element.qid]) {
						keys.push(element.qid);
					}

					return keys;
				}, [] as string[]);

				const pageIsValid = isEmpty(pageErrorKeys);

				if (pageIsValid) {
					window.scrollTo({ top: 0, behavior: 'smooth' });
					goToNext();
				} else {
					// handle submit will make <FormikErrorFocus /> component to scroll to the first error
					handleSubmit();
				}
			};

			return (
				<Form autoComplete="off">
					<Text fontSize="xl">{form_title}</Text>
					{paginatedElements.length > 1 && (
						<Stepper index={currentPage} px={20} py={10}>
							{paginatedElements.map((_, page) => (
								<Step
									key={page}
									onClick={() => {
										if (page > paginatedElements.length - 1) handleSubmit();
										else if (page > currentPage) handlePageChange();
										else setActiveStep(page);
									}}
								>
									<StepIndicator>
										<StepStatus
											active={<StepNumber />}
											complete={<StepIcon />}
											incomplete={<StepNumber />}
										/>
									</StepIndicator>
									<StepSeparator />
								</Step>
							))}
						</Stepper>
					)}
					<Box
						fontSize="lg"
						maxW="3xl"
						mb={5}
						mt={5}
						mx="auto"
						px={{ base: 0, md: 8 }}
						textAlign="left"
					>
						{form_instructions && <Markdown>{form_instructions}</Markdown>}
					</Box>
					<div style={{ width: '100%' }}>
						{paginatedElements[currentPage].map((el, index) => (
							<FormElement
								key={el.qid || index}
								borderColor="transparent"
								isDisabled_={isDisabled_}
								saveOnBlur={saveOnBlur}
								thisElement={el}
							/>
						))}
					</div>
					{!isDisabled_ && (
						<>
							{paginatedElements.length > 1 && currentPage > 0 && (
								<Button
									colorScheme="teal"
									maxW="600"
									minW="200"
									mr={4}
									mt={4}
									size="lg"
									textAlign="center"
									onClick={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' });
										goToPrevious();
									}}
								>
									Previous
								</Button>
							)}

							<Button
								colorScheme="teal"
								isLoading={isSubmitting}
								maxW="600"
								minW="200"
								mt={4}
								size="lg"
								textAlign="center"
								onClick={
									paginatedElements.length > 1 && !isLastPage
										? handlePageChange
										: () => handleSubmit()
								}
							>
								{paginatedElements.length > 1 && !isLastPage ? 'Next' : 'Submit'}
							</Button>
						</>
					)}
				</Form>
			);
		}
	}
};

const fieldIsRequired = (element: NellieFormElement) => {
	return element.validations?.some((validation) => validation.type === 'required');
};
