import { Suspense } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Select as ChakraSelect } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

const TimezoneSelectUI = lazy(() => import('./TimezoneSelectUI'));

export type { TimezoneSelectProps } from './TimezoneSelectUI';
export const TimezoneSelect = (props: Parameters<typeof TimezoneSelectUI>[0]) => (
	<Suspense fallback={<ChakraSelect />}>
		<TimezoneSelectUI {...props} />
	</Suspense>
);
