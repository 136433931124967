import { memo } from 'react';
import { FiExternalLink } from 'react-icons/fi';

import { Button, HStack, Text } from '@chakra-ui/react';

import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';

export const OpenBillingPortalButton = memo(() => {
	const {
		data: stripeSecret,
		isLoading: isLoadingStripeSecret,
		error: errorStripeSecret,
	} = useAppQuery('Api.stripe.addPaymentInformation', Api.stripe.addPaymentInformation, {
		refetchOnWindowFocus: false,
	});

	const {
		data: billingPortalUrl,
		isLoading: isLoadingBillingPortalUrl,
		error: errorBillingPortalUrl,
	} = useAppQuery('Api.stripe.stripeCustomerPortal', Api.stripe.stripeCustomerPortal, {
		refetchOnWindowFocus: false,
		enabled: Boolean(stripeSecret),
	});

	return (
		<Button
			as="a"
			colorScheme="green"
			href={billingPortalUrl}
			isDisabled={
				isLoadingStripeSecret ||
				isLoadingBillingPortalUrl ||
				Boolean(errorStripeSecret) ||
				Boolean(errorBillingPortalUrl)
			}
			isLoading={isLoadingStripeSecret || isLoadingBillingPortalUrl}
			minW="200"
			variant="outline"
		>
			<HStack justifyContent="space-between">
				<Text>Go to billing portal</Text>
				<FiExternalLink />
			</HStack>
		</Button>
	);
});
