import { memo, useCallback, useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { useDisclosure } from '@chakra-ui/react';

import { GlobalContext, useGlobalActions } from '@/context';
import { VideoCallStatus } from '@/utils';

import { SessionTimeoutModal } from './components/SessionTimeoutModal';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

const PROMPT_TIMEOUT = 5 * ONE_MINUTE;
const TIMEOUT = 60 * ONE_MINUTE;

export type SessionTimeoutProps = {
	onTimeout?: VoidFunction;
	promptTimeout?: number;
	timeout?: number;
};

const SessionTimeout = memo(
	({ promptTimeout = PROMPT_TIMEOUT, timeout = TIMEOUT, onTimeout }: SessionTimeoutProps) => {
		const {
			isOpen: countdownModalIsOpen,
			onOpen: openCountdownModal,
			onClose: closeCountdownModal,
		} = useDisclosure();

		const { logout } = useGlobalActions();
		const { videoCallStatus } = useContext(GlobalContext);

		const { pause, start, getRemainingTime } = useIdleTimer({
			startOnMount: false,
			startManually: true,
			stopOnIdle: true,
			promptBeforeIdle: promptTimeout,
			timeout,
			onPrompt: openCountdownModal,
			onIdle: onTimeout || logout,
			throttle: 500,
		});

		useEffect(() => {
			if (videoCallStatus === VideoCallStatus.inMeeting) pause();
			else start();
		}, [pause, start, videoCallStatus]);

		const closeModal = useCallback(() => {
			if (videoCallStatus !== VideoCallStatus.inMeeting) start();

			closeCountdownModal();
		}, [closeCountdownModal, start, videoCallStatus]);

		if (countdownModalIsOpen) {
			return <SessionTimeoutModal getRemainingTime={getRemainingTime} onClose={closeModal} />;
		}

		return <></>;
	}
);

export default memo(SessionTimeout);
