export enum FormElementType {
	br = 'br',
	checkbox = 'checkbox',
	date = 'date',
	fieldArray = 'fieldArray',
	hr = 'hr',
	image = 'image',
	list = 'list',
	/** A special type of element that is used to break up the form into multiple pages. */
	pageBreak = 'pageBreak',
	plaintext = 'plaintext',
	radio = 'radio',
	radioHStack = 'radio-hstack',
	radioVStack = 'radio-vstack',
	radioVStackWithText = 'radio-vstack-withtext',
	select = 'select',
	signature = 'signature',
	slider = 'slider',
	sliderWithText = 'slider-withtext',
	suds = 'suds',
	text = 'text',
	textarea = 'textarea',
	textareasplit = 'textareasplit',
}

export enum ClientStatus {
	'active' = 'active',
	'complete' = 'complete',
	'ended-early' = 'ended-early',
	'inactive' = 'inactive',
	'lost' = 'lost',
	'paused' = 'paused',
	'pre-treatment' = 'pre-treatment',
}
