import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-appointment';

export enum PaymentStatus {
	UNKNOWN,
	UNPROCESSED,
	MANUALRESOLVE,
	PENDING,
	PROCESSING,
	RECEIVED,
	ERROR,
}

export enum BookingStatus {
	ACCEPTED = 'accepted',
	CANCELLED = 'cancelled',
	PENDING = 'pending',
	REJECTED = 'rejected',
}

export enum BookingStatusApi {
	ACCEPTED = 'ACCEPTED',
	REJECTED = 'REJECTED',
}

export type AppointmentType = {
	bookingConfirmed?: boolean;
	bookingId: number;
	cancellationReason: string | null;
	description: string | null;
	endTime: string;
	id: number;
	isUpcoming?: boolean | null;
	is_third_party_payment: boolean | null;
	location: string | null;
	manual_resolve: boolean | null;
	manual_resolve_message: string;
	paymentStatus?: PaymentStatus;
	rejectionReason: string | null;
	sessionConfirmed: boolean;
	startTime: string;
	status: BookingStatus;
	stripePaymentData: any | null;
	stripePaymentStatus: string | null;
	third_party_payment_provider: string | null;
	third_party_payment_status: string | null;
	timeZone: string;
	title: string;
	uid: string;
};

const getBookingsClinician = (params: {
	page: number;
	pageSize?: number;
	type: 'pastConfirmed' | 'completed';
}) =>
	httpClient.post<{ data: AppointmentType[]; hasMore: boolean }>(
		`${BASE_URL}s/getBookingsClinician`,
		params
	);

const getBookingsClient = () => {
	return httpClient.post<{ pastConfirmed: AppointmentType[]; upcoming: AppointmentType[] }>(
		`${BASE_URL}s/getBookingsClient`
	);
};

const getCompletedBookings = (params: {
	attendeeId?: number;
	endDate: Date;
	page: number;
	pageSize?: number;
	searchQuery?: string;
	startDate: Date;
}) => {
	return httpClient.post<{ data: AppointmentType[]; hasMore: boolean }>(
		`${BASE_URL}s/getCompletedBookings`,
		params
	);
};

export type GetClinicianResponse = {
	email?: string;
	id: ID;
	username: string;
};
const getClinician = () =>
	httpClient.post<GetClinicianResponse | Record<string, never>>(`${BASE_URL}s/getClinician`);

export type EventType = {
	description?: string;
	id?: number;
	length: number;
	title: string;
	userId?: number;
};

export type CreateEntryParams = {
	clientId: Maybe<ID>;
	endTime: Maybe<Date>;
	eventType: Maybe<EventType>;
	startTime: Maybe<Date>;
};

const createEntry = (params: CreateEntryParams) =>
	httpClient.post<GetClinicianResponse>(`${BASE_URL}-records/createEntry`, params);

const getEventTypes = () => {
	return httpClient.post<EventType[]>(`${BASE_URL}-records/getEventTypes`);
};

const confirmSession = (bookingId: ID) => {
	return httpClient.post(`${BASE_URL}-records/confirmSession`, { bookingId });
};

const resolveSession = (params: { id: ID; message?: string; unresolve?: boolean }) => {
	return httpClient.post(`${BASE_URL}-records/resolveSession`, params);
};

const removeSession = (params: { uid: string }) => {
	return httpClient.post(`${BASE_URL}-records/removeSession`, params);
};

type BookingStatusParams = {
	bookingId: number;
	bookingStatus: BookingStatusApi;
};

const changeBookingStatus = (params: BookingStatusParams) => {
	return httpClient.post(`${BASE_URL}s/changeBookingStatus`, params);
};

export type SetCalPasswordResponse = { isCalOnboarded: boolean; password: string };

const setCalPassword = () => {
	return httpClient.post<SetCalPasswordResponse>(`${BASE_URL}s/setCalPassword`);
};

export const appointmentsApi = {
	getBookingsClinician,
	getBookingsClient,
	getCompletedBookings,
	getClinician,
	createEntry,
	getEventTypes,
	confirmSession,
	resolveSession,
	removeSession,
	changeBookingStatus,
	setCalPassword,
};
