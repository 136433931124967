export const SCROLLBAR_WIDTH = 8;
/**
 * add this classname if you need your container's scroll bar be always visible
 */
export const SCROLLABLE_CLASSNAME = 'scrollable';

export const SIDEBAR_WIDTH = {
	collapsed: 75 + SCROLLBAR_WIDTH / 2 + 'px',
	expanded: 225 + SCROLLBAR_WIDTH / 2 + 'px',
};
