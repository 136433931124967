import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';

import { SignatureModal } from '@/components/ui/SignatureModal/SignatureModal';

const width = 100;
const height = (width / 16) * 9;

/**
 * SignatureProps is an object with an optional onChange function property and an optional value string
 * property.
 * @property onChange - A callback function that is called when the signature is changed.
 * @property {string} value - The value of the signature.
 */
type SignatureProps = {
	/** don't pass onChange for read only mode  */
	onChange?: (base64Signiture: string) => void;
	/** base64 string  */
	value?: string;
};

export const Signature = ({ onChange, value }: SignatureProps) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<HStack
			alignItems="baseline"
			cursor={onChange ? 'pointer' : 'auto'}
			onClick={onChange ? onOpen : undefined}
		>
			<Text textAlign="start">Signature</Text>

			<Flex borderBottom="1px" justifyContent="center" width="200px">
				{isOpen && <SignatureModal onClose={onClose} onSubmit={onChange || (() => undefined)} />}
				{value ? (
					<Box
						backgroundImage={value}
						backgroundPosition="center"
						backgroundRepeat="no-repeat"
						backgroundSize="contain"
						h={height + 'px'}
						w={width + 'px'}
					/>
				) : (
					<Box padding={0} textAlign="start">
						Sign here
					</Box>
				)}
			</Flex>
		</HStack>
	);
};
