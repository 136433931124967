import { Suspense, createContext, lazy, useContext, useState } from 'react';

import { Room } from './VideoPip';

const VideoPip = lazy(() => import('./VideoPip'));

const setRoomContext = createContext<React.Dispatch<React.SetStateAction<Room | undefined>>>(() => {
	throw new Error('setRoomContext not implemented');
});

export const useSetRoom = () => useContext(setRoomContext);

export const VideoPipProvider = ({ children }) => {
	const [room, setRoom] = useState<Room>();

	return (
		<setRoomContext.Provider value={setRoom}>
			{children}
			{room && (
				<Suspense>
					<VideoPip room={room} setRoom={setRoom} />
				</Suspense>
			)}
		</setRoomContext.Provider>
	);
};
