/**
 *
 * Authenticated
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */
import { Suspense, lazy, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { CustomSpinner } from '@/components/ui';
import { VideoPipProvider } from '@/containers/VideoCall/components/VideoPipProvider';
import { useUnreadMessages } from '@/hooks';
import { ROUTES } from '@/routes';
import { Roles } from '@/utils';
import auth from '@/utils/auth';

const SessionTimeout = lazy(() => import('@/components/SessionTimeout/SessionTimeout'));
const AgreementModal = lazy(() => import('@/components/AgreementModal'));

const AuthenticatedRoute = memo(() => {
	useUnreadMessages();

	// If authorized, return an outlet that will render child elements
	// If not, return element that will navigate to login page
	if (!auth.getToken()) {
		return <Navigate replace state={{ fromPath: location.pathname }} to={ROUTES.LOGIN} />;
	}

	if (auth.getUserRole() === Roles.client || auth.getUserRole() === Roles.clinician) {
		return (
			<AgreementModal>
				{(privacyTermsAcknowledged) => (
					<VideoPipProvider>
						<Suspense fallback={<CustomSpinner />}>
							<SessionTimeout />
							{privacyTermsAcknowledged && <Outlet />}
						</Suspense>
					</VideoPipProvider>
				)}
			</AgreementModal>
		);
	}

	return (
		<VideoPipProvider>
			<Suspense fallback={<CustomSpinner />}>
				<SessionTimeout />
				<Outlet />
			</Suspense>
		</VideoPipProvider>
	);
});

export default AuthenticatedRoute;
