import { useIsFormPreviewModalOpen } from '@/components/FormPreviewModal/FormPreviewModal';
import { useIsFormReviewModalOpen } from '@/containers/FormProvider/FormModal';

export const useIsDisabled = () => {
	const isFormPreviewModalOpen = useIsFormPreviewModalOpen();
	const isFormReviewModalOpen = useIsFormReviewModalOpen();
	return {
		isFormReviewModalOpen,
		isFormPreviewModalOpen,
		isDisabled: isFormPreviewModalOpen || isFormReviewModalOpen,
	};
};
