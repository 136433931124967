import { useCallback, useContext } from 'react';
import {
	FiCalendar,
	FiCheckSquare,
	FiFolder,
	FiInfo,
	FiMessageSquare,
	FiUser,
	FiVideo,
} from 'react-icons/fi';
import { ImStatsBars } from 'react-icons/im';
import { RiEraserLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import {
	Avatar,
	Box,
	Divider,
	Flex,
	HStack,
	Heading,
	Icon,
	Square,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';

import { useUnreadMessagesContext } from '@/App';
import { GlobalContext, useGlobalActions } from '@/context';
import { useGetClientInfo } from '@/hooks/queries/use-get-client-info';
import { ROUTES } from '@/routes';
import { getFullName, getNellieUserFullName, getNellieUserInitials } from '@/utils';
import auth from '@/utils/auth';

import { SchedulerLink } from '../../../components/SchedulerLink/SchedulerLink';
import { NavItem } from '../components/NavItem';
import { useSidebarColors } from '../hooks';

import { NavItemTooltip } from './NavItemTooltip';

export const ClinicianNavItems = ({ isCollapsed, onClose }) => {
	const genericProps = {
		isCollapsed,
		onClick: onClose,
		py: 3,
	};

	const usersWithAnalyticsAccess = [1, 33, 138, 397];

	return (
		<Stack spacing={6}>
			<Box>
				<NavItem icon={FiUser} label="Clients" to={ROUTES.CLIENTS} {...genericProps} />
				<NavItem
					icon={FiCalendar}
					label="Appointments"
					to={ROUTES.APPOINTMENTS_CLINICIAN}
					{...genericProps}
				/>
				{usersWithAnalyticsAccess.includes(auth.getUserInfo().id) && (
					<NavItem icon={ImStatsBars} label="Analytics" to={ROUTES.ANALYTICS} {...genericProps} />
				)}
			</Box>
			<ClientFocus focusID={auth.getClientID()} isCollapsed={isCollapsed} onClose={onClose} />
		</Stack>
	);
};

export type ClientFocusProps = {
	focusID: ID;
	isCollapsed?: boolean;
	onClose: VoidFunction;
};

export const ClientFocus = ({ focusID, onClose, isCollapsed = false }: ClientFocusProps) => {
	const { _linkHover, color } = useSidebarColors();
	const { unreadMessagesCount } = useUnreadMessagesContext();

	const { trainee } = useContext(GlobalContext);
	const { setTrainee, setClient } = useGlobalActions();

	const navigate = useNavigate();
	const onClearFocus = useCallback(() => {
		setTrainee();
		setClient();
		auth.clearClientID();
		navigate(ROUTES.CLIENTS);
	}, [navigate, setTrainee, setClient]);

	const { data: focusedClient } = useGetClientInfo();

	const genericProps = {
		isCollapsed,
		onClick: onClose,
		py: 3,
	};

	if (!isCollapsed && isEmpty(focusedClient)) {
		return (
			<Box mt="10" mx="5">
				<Text
					_hover={{
						color: 'blue.600',
					}}
					cursor="pointer"
					fontWeight="regular"
					pt={2}
					px={4}
					textAlign="center"
					textDecoration="underline"
					onClick={() => {
						onClose();
						navigate(ROUTES.CLIENTS);
					}}
				>
					Select a client to see more options here
				</Text>
			</Box>
		);
	}

	return focusedClient ? (
		<>
			<Divider borderColor="purple.700" opacity={1} />
			{isCollapsed ? (
				<Flex align="center" direction="column" justifyContent="center">
					<NavItemTooltip label={<Text as="span">{getNellieUserFullName(focusedClient)}</Text>}>
						<Avatar
							bg="#E6AF6E"
							color="gray.700"
							mb={2}
							name={getNellieUserInitials(focusedClient)}
							size="sm"
						/>
					</NavItemTooltip>

					{!trainee && (
						<SchedulerLink clientId={+focusID}>
							<NavItemTooltip label={<Text as="span">Schedule appointment</Text>}>
								<Square _hover={_linkHover} color={color} cursor="pointer" rounded="lg" size={45}>
									<Icon as={FiCalendar} boxSize={5} m={0} p={0} />
								</Square>
							</NavItemTooltip>
						</SchedulerLink>
					)}

					<NavItemTooltip label={<Text as="span">Clear client focus</Text>}>
						<Square
							_hover={_linkHover}
							color={color}
							cursor="pointer"
							rounded="lg"
							size={45}
							onClick={onClearFocus}
						>
							<Icon as={RiEraserLine} boxSize={5} m={0} p={0} />
						</Square>
					</NavItemTooltip>
				</Flex>
			) : (
				<>
					{trainee && (
						<Heading color="blue.800" fontSize="md">
							{getFullName(trainee)}
						</Heading>
					)}
					<HStack justifyContent="space-between">
						<Heading color="blue.800" fontSize="md">
							{getNellieUserFullName(focusedClient)}
						</Heading>
						<HStack>
							{!trainee && (
								<SchedulerLink clientId={+focusID}>
									<Tooltip label="Schedule appointment">
										<Square
											_hover={_linkHover}
											color={color}
											cursor="pointer"
											p={2}
											rounded="lg"
											size={34}
										>
											<FiCalendar />
										</Square>
									</Tooltip>
								</SchedulerLink>
							)}
							<Tooltip label="Clear client focus">
								<Square
									_hover={_linkHover}
									color={color}
									cursor="pointer"
									p={2}
									rounded="lg"
									size={34}
									onClick={onClearFocus}
								>
									<Icon as={RiEraserLine} boxSize={5} m={0} opacity={0.8} p={0} />
								</Square>
							</Tooltip>
						</HStack>
					</HStack>
				</>
			)}

			<Box position="relative">
				<div>
					{!trainee && (
						<NavItem
							icon={FiCheckSquare}
							label="Assignments"
							to={ROUTES.CLIENT_ASSIGNMENTS}
							{...genericProps}
						/>
					)}
					<NavItem icon={FiFolder} label="Notes" to={ROUTES.CLIENT_NOTES} {...genericProps} />
					{!trainee && (
						<>
							<NavItem
								count={unreadMessagesCount}
								icon={FiMessageSquare}
								label="Messages"
								to={ROUTES.CHAT}
								{...genericProps}
							/>
							<NavItem icon={FiVideo} label="Video Call" to={ROUTES.VIDEOCALL} {...genericProps} />
							<NavItem
								icon={FiInfo}
								label="Client Info"
								to={ROUTES.CLIENT_INFO}
								{...genericProps}
							/>
						</>
					)}
				</div>
			</Box>

			<Divider borderColor="purple.700" opacity={1} />
		</>
	) : (
		<></>
	);
};
