import { memo, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Text, Tooltip } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { Api } from '@/api';
import { CreateNoteParams, UpdateNoteParams } from '@/api/notes';
import { openConfirmationModal, openSignatureModal } from '@/components/ui';
import { GlobalContext } from '@/context';
import { ROUTES } from '@/routes';
import { encrypt } from '@/utils';

import { useNoteApi } from '../hooks';

type SignButtonProps = {
	canSign: boolean;
};

export const SignButton = memo<SignButtonProps>(({ canSign }) => {
	const queryClient = useQueryClient();
	const { loggedInUser } = useContext(GlobalContext);
	const navigate = useNavigate();
	const { handleSubmit } = useFormContext<Note>();

	const { signNote } = useNoteApi();

	const onSign = useMemo(() => {
		return handleSubmit((values) => {
			const onSubmit = async () => {
				const note = await signNote(values as unknown as CreateNoteParams | UpdateNoteParams);

				if (!values.id) {
					navigate(`${ROUTES.CLIENT_NOTES}/${encrypt(note.id)}`);
				}
			};

			if (loggedInUser.signature) {
				return openConfirmationModal({
					title: 'Sign Note',
					body: 'Are you sure you want to sign this note?',
					initialFocusedButton: 'submit',
					onSubmit,
				});
			}

			return openSignatureModal({
				header: (
					<Box w="90%">
						<Text fontWeight="light">Please provide your signature here to amend the note.</Text>
						<Text fontWeight="light">
							This signature will be used for all future signings and amendments, but you can update
							it in your user settings if necessary.
						</Text>
					</Box>
				),
				submitBtnText: 'Sign and Finalize',
				onSubmit: async (signature) => {
					await Api.users.addSignature(signature);

					await Promise.all([onSubmit(), queryClient.invalidateQueries(['Api.users.getMyInfo'])]);
				},
			});
		});
	}, [handleSubmit, loggedInUser, navigate, queryClient, signNote]);

	return (
		<Tooltip
			hasArrow
			borderRadius="md"
			isDisabled={canSign}
			label={
				<Text fontWeight="semibold" p="2">
					Only your supervisor can Sign and Finalize this note
				</Text>
			}
			placement="bottom-end"
		>
			<Button
				_active={{}}
				_hover={canSign ? { bg: '#1D1671' } : {}}
				bg="#3A38A6"
				color="white"
				flex={1}
				isDisabled={!canSign}
				onClick={onSign}
			>
				Sign and Finalize
			</Button>
		</Tooltip>
	);
});
