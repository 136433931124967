import { useContext } from 'react';

import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';
import { GlobalContext } from '@/context';

/**
 * `useGetTemplates` is a React hook that returns the result of calling `useQuery` with the
 * `Api.templates.getTemplates` function
 */
export const useGetTemplates = <ActualResponse = Template[], TError = AxiosError>(
	options?: Omit<UseQueryOptions<Template[], TError, ActualResponse>, 'queryKey' | 'queryFn'>
) => {
	const { trainee, loggedInUser } = useContext(GlobalContext);

	return useAppQuery<Template[], TError, ActualResponse>(
		['templates'],
		() => Api.templates.getTemplates(trainee?.id || loggedInUser.id),
		options
	);
};
