import { FC, ReactNode, createContext, memo, useContext } from 'react';

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Highlight,
	Text,
} from '@chakra-ui/react';
import { FieldProps } from 'formik';

import { AppField } from './AppField';

type EditableFieldInnerProps<T> = Partial<Omit<FormLabelProps, 'children'>> & {
	children: (props: FieldProps<T[keyof T], T>) => ReactNode;
	isDisabled?: boolean;
	label?: string;
	name: keyof T;
	renderValue?: (value: T[keyof T]) => any;
};

const EditableFieldContext = createContext(false);
export const EditableFieldProvider: FC<{ children: ReactNode; isEditMode: boolean }> = ({
	children,
	isEditMode,
}) => <EditableFieldContext.Provider value={isEditMode}>{children}</EditableFieldContext.Provider>;

export const EditableField = memo(EditableFieldInner) as unknown as typeof EditableFieldInner;
function EditableFieldInner<T>({
	name,
	label,
	children,
	renderValue = (v) => <>{v}</>,
	isDisabled,
	...formLabelProps
}: EditableFieldInnerProps<T>) {
	const isEditMode = useContext(EditableFieldContext);

	return (
		<AppField<T> name={name}>
			{(props) => {
				// if there was no initialValue it means it is not saved yet
				const initialValue = props.form.initialValues[name];
				const currentValue = props.field.value;

				return (
					<FormControl isDisabled={isDisabled} isInvalid={Boolean(props.form.errors[name])}>
						{label && (
							<FormLabel {...formLabelProps} fontWeight="semibold">
								{!initialValue ? (
									<Highlight
										query={label}
										styles={{
											bg: !initialValue ? 'orange.200' : undefined,
											padding: '5px 12px',
											rounded: 'full',
											whiteSpace: 'pre-wrap',
										}}
									>
										{label || ''}
									</Highlight>
								) : (
									label
								)}
							</FormLabel>
						)}
						{isEditMode || !initialValue ? (
							children(props)
						) : currentValue ? (
							<Text as="span">{renderValue(currentValue)}</Text>
						) : (
							children(props)
						)}
						<FormErrorMessage>{props.form.errors[name]?.toString()}</FormErrorMessage>
					</FormControl>
				);
			}}
		</AppField>
	);
}
