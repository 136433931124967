import { ReactNode, memo } from 'react';

import * as Yup from 'yup';
import { Box, BoxProps, useColorModeValue as mode } from '@chakra-ui/react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import { isFunction } from 'lodash-es';

import { FormBuilder } from '@/containers/FormProvider/FormBuilder';
import { useIsFormReviewModalOpen } from '@/containers/FormProvider/FormModal';
import { FormEngineV2, isFormEngineV2 } from '@/forms/core/FormEngineV2';

import { useIsFormPreviewModalOpen } from '../FormPreviewModal/FormPreviewModal';

function formIsCBW(form_data) {
	return form_data?.formHeader?.formName === 'cbw';
}

export type FormBuilderBoxProps<T extends Record<string, any>> = BoxProps & {
	footer?: ReactNode | ((formikProps: FormikState<T>) => ReactNode);
	formBuilder: FormBuilder;
	header?: ReactNode | ((formikProps: FormikState<T>) => ReactNode);
	initialValues?: FormikProps<T>['initialValues'];
	onSubmit?: (values: T, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
	saveOnBlur?: (...args: any[]) => unknown;
	validationSchema?: Yup.AnySchema;
};

export const FormBuilderBox = memo((props) => {
	if (isFormEngineV2(props.formBuilder)) {
		return <FormEngineV2 Component={props.formBuilder.form_data.Component as string} />;
	}

	return <FormBuilderBoxInner {...props} />;
}) as typeof FormBuilderBoxInner;

function FormBuilderBoxInner<T extends Record<string, any> = Record<string, any>>({
	formBuilder,
	saveOnBlur,
	footer,
	header,
	initialValues,
	validationSchema,
	onSubmit = placeholderFunction,
	ml = { md: '1%', lg: '3%', xl: '5%' },
	...boxProps
}: FormBuilderBoxProps<T>) {
	const isPreviewModalOpen = useIsFormPreviewModalOpen();
	const isReviewModalOpen = useIsFormReviewModalOpen();

	const bgColor = mode('white', 'gray.700');

	return (
		<Formik
			/**
			 * validate on mount to have form's isValid state from start
			 * is useful when we have paginated form and we want to disable the next button
			 */
			validateOnMount
			initialValues={initialValues as any}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{(state) => (
				<Box
					{...(!formIsCBW(formBuilder?.form_data) && {
						maxWidth: '4xl',
						ml: isReviewModalOpen || isPreviewModalOpen ? 0 : ml,
					})}
					bg={bgColor}
					mx="auto"
					pb="10"
					pt="8"
					px={{ base: '4', md: '10' }}
					rounded={{ lg: 'lg' }}
					textAlign="center"
					{...boxProps}
				>
					{isFunction(header) ? header(state) : header}
					<FormBuilder formBuilder={formBuilder} saveOnBlur={saveOnBlur} />
					{isFunction(footer) ? footer(state) : footer}

					<FormikErrorFocus
						// See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
						align="middle"
						duration={200}
						ease="linear"
						focusDelay={0}
						// some workaround to not pass anything
						// because it's working without it
						formik={undefined as any}
						offset={0}
					/>
				</Box>
			)}
		</Formik>
	);
}

function placeholderFunction() {
	// placeholder function
}
