import { useUpdateEffect } from '@chakra-ui/react';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { FormResponse } from '@/api/forms';
import { useAppQuery } from '@/app-react-query';

const makeUseGetFormKey = (id: ID) => `form:${id}`;

/** if formId is **falsy** value it will not fetch the form */
export const useGetForm = <ActualResponse = FormResponse, TError = AxiosError>(
	formId: Maybe<ID>,
	options?: Omit<UseQueryOptions<FormResponse, TError, ActualResponse>, 'queryKey' | 'queryFn'>
) => {
	const { data, isLoading, error, isFetching, refetch } = useAppQuery(
		makeUseGetFormKey(formId as ID),
		() => Api.forms.getFormById(formId as ID),
		{
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			enabled: Boolean(formId),
			retry: false,
			...options,
		}
	);

	useUpdateEffect(() => {
		refetch();
	}, [formId]);

	return {
		refetch,
		data,
		/** A boolean that is true when the query is loading or fetching. */
		loading: isLoading || isFetching,
		error,
	};
};

useGetForm.makeKey = makeUseGetFormKey;
