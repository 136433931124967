import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from '@chakra-ui/react';

import { CreateNoteParams } from '@/api/notes';
import { openConfirmationModal } from '@/components/ui';
import { ROUTES } from '@/routes';
import { encrypt } from '@/utils';

import { useNoteApi } from '../hooks';

export function SaveDraftButton({ children = 'Save as Draft', ...buttonProps }: ButtonProps) {
	const { handleSubmit } = useFormContext<CreateNoteParams>();

	const navigate = useNavigate();
	const { createNote } = useNoteApi();

	return (
		<Button
			_active={{}}
			_hover={{
				bg: '#1D1671',
			}}
			bg="#3A38A6"
			color="white"
			onClick={handleSubmit(({ attachments, ...note }) => {
				openConfirmationModal({
					title: 'Save as Draft',
					body: 'Are you sure you want to save this draft?',
					onSubmit: () =>
						createNote({
							...note,
							attachments,
							status: attachments ? 'Published' : note.status,
						}).then((createdNote) =>
							navigate(ROUTES.CLIENT_NOTES + `/${encrypt(createdNote.id)}`, { replace: true })
						),
					initialFocusedButton: 'submit',
				});
			})}
			{...buttonProps}
		>
			{children}
		</Button>
	);
}
