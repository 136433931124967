import { AxiosError } from 'axios';

import { httpClient } from './http-client';

const BASE_URL = '/api/auth';

export type LoginParams = {
	identifier: string;
	password: string;
};

const STRAPI_AUTH_TOKEN = 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN;

const logIn = async (
	params: LoginParams
): Promise<
	| { jwt: string }
	| AxiosError<{
			error: {
				details: Record<string, any>;
				message: string;
				name: string;
				status: number;
			};
	  }>
> => {
	try {
		return await httpClient.post<{ jwt: string }>(`${BASE_URL}/local`, params, {
			headers: {
				Authorization: STRAPI_AUTH_TOKEN,
			},
		});
	} catch (error) {
		// instead of throwing the error, return it
		// this way react-query will not log this as error
		return error as AxiosError<{
			error: {
				details: Record<string, any>;
				message: string;
				name: string;
				status: number;
			};
		}>;
	}
};

type RegisterProps = {
	confirmed?: boolean;
	email: string;
	first_name: string;
	last_name: string;
	password: string;
	terms: boolean;
	username: string;
};

const register = (params: RegisterProps) => {
	return httpClient.post(`${BASE_URL}/local/register`, params, {
		headers: {
			Authorization: STRAPI_AUTH_TOKEN,
		},
	});
};

export type ChangePasswordParams = {
	currentPassword: string;
	password: string;
	passwordConfirmation: string;
};

const changePassword = (params: ChangePasswordParams) => {
	return httpClient.post(`${BASE_URL}/change-password`, params);
};

type ResetParams = {
	code?: string | null;
	password: string;
	passwordConfirmation: string;
};

const resetPassword = (params: ResetParams) => {
	return httpClient.post(`${BASE_URL}/reset-password`, params, {
		headers: {
			Authorization: STRAPI_AUTH_TOKEN,
		},
	});
};

const forgotPassword = (email: string) => {
	return httpClient.post(
		`${BASE_URL}/forgot-password`,
		{ email },
		{
			headers: {
				Authorization: STRAPI_AUTH_TOKEN,
			},
		}
	);
};

export const authApi = {
	logIn,
	register,
	changePassword,
	resetPassword,
	forgotPassword,
};
