import { useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';

import { Api } from '@/api';
import { EventType } from '@/api/appointments';
import { useAppQuery } from '@/app-react-query';
import { DEFAULT_EVENT_TYPES } from '@/utils';

import { Select, SelectProps } from '../ui';

export const AppointmentEventTypesSelect = (props: SelectProps<EventType>) => {
	const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen: false });

	const { data, isLoading, error } = useAppQuery(
		['Api.appointments.getEventTypes'],
		Api.appointments.getEventTypes,
		{
			staleTime: 0,
			refetchOnReconnect: false,

			enabled: isOpen,

			select: (eventTypes) => (isEmpty(eventTypes) ? DEFAULT_EVENT_TYPES : eventTypes),
		}
	);

	return (
		<Select<EventType>
			closeMenuOnSelect
			isClearable
			getOptionLabel={(option) => option.title}
			getOptionValue={(option) => option.length.toString()}
			isLoading={isLoading}
			placeholder="Select session type..."
			selectedOptionColor="purple"
			size={{ base: 'sm', md: 'lg' }}
			onMenuClose={onClose}
			onMenuOpen={onOpen}
			{...props}
			options={(error ? [] : data) as EventType[]}
		/>
	);
};
