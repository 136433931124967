import { Center, CenterProps, Spinner } from '@chakra-ui/react';

export const CustomSpinner = (props: CenterProps) => {
	return (
		<Center height="100vh" {...props}>
			<Spinner color="purple" emptyColor="purple.200" size="xl" speed="0.65s" thickness="4px" />
		</Center>
	);
};

export const CustomSpinnerTiny = () => {
	return (
		<Center>
			<Spinner color="purple" size="xs" />
		</Center>
	);
};
