import { memo } from 'react';

import * as yup from 'yup';
import { List, ListItem, Text } from '@chakra-ui/react';

import { useIsFormReviewModalOpen } from '@/containers/FormProvider/FormModal';
import { FormEngineV2Props } from '@/forms/core/FormEngineV2';
import { Form, RadioGroup } from '@/forms/form-elements';
import { SubmitButton } from '@/forms/form-elements/SubmitButton';
import { ValueTransformer, transformers } from '@/forms/hooks/use-nellie-controller';
import { useNellieForm } from '@/forms/hooks/use-nellie-form';
import { Roles } from '@/utils';
import auth from '@/utils/auth';

const schema = yup.object({
	Q1: yup.string().required('Required'),
	Q2: yup.string().required('Required'),
	Q3: yup.string().required('Required'),
	Q4: yup.string().required('Required'),
	Q5: yup.string().required('Required'),
	Q6: yup.string().required('Required'),
	Q7: yup.string().required('Required'),
	Q8: yup.string().required('Required'),
	Q9: yup.string().required('Required'),
	Q10: yup.string().required('Required'),
	Q11: yup.string().required('Required'),
	Q12: yup.string().required('Required'),
	Q13: yup.string().required('Required'),
	Q14: yup.string().required('Required'),
	Q15: yup.string().required('Required'),
	Q16: yup.string().required('Required'),
	Q17: yup.string().required('Required'),
	Q18: yup.string().required('Required'),
	Q19: yup.string().required('Required'),
	Q20: yup.string().required('Required'),
	Q21: yup.string().required('Required'),
});

type AcceptedNames = keyof typeof schema.fields;

const options = ['Never', 'Sometimes', 'Often', 'Almost Always'];

const transformer: ValueTransformer = transformers.stringArrayToIndex(options);

/**
 * There are 3 sub-scales: Depression, Anxiety, Stress
 *
 * Depression: sum of questions (3, 5, 10, 13, 16, 17, 21) x 2
 * Anxiety: sum of questions (2, 4, 7, 9, 15, 19, 20) x 2
 * Stress: sum of questions (1, 6, 8, 11, 12, 14, 18) x 2
 *
 * Total Score sum of above
 */
const getTotalsOfSubScales = (responses: Record<string, string>) => {
	const depressionSum =
		Number(responses.Q3) +
		Number(responses.Q5) +
		Number(responses.Q10) +
		Number(responses.Q13) +
		Number(responses.Q16) +
		Number(responses.Q17) +
		Number(responses.Q21);

	const anxietySum =
		Number(responses.Q2) +
		Number(responses.Q4) +
		Number(responses.Q7) +
		Number(responses.Q9) +
		Number(responses.Q15) +
		Number(responses.Q19) +
		Number(responses.Q20);

	const stressSum =
		Number(responses.Q1) +
		Number(responses.Q6) +
		Number(responses.Q8) +
		Number(responses.Q11) +
		Number(responses.Q12) +
		Number(responses.Q14) +
		Number(responses.Q18);

	return {
		depression: depressionSum * 2,
		anxiety: anxietySum * 2,
		stress: stressSum * 2,
		sum: (depressionSum + anxietySum + stressSum) * 2,
	};
};

export const DASS_21_TITLE = 'DASS-21';
const Dass21 = ({ defaultValues, footer = <SubmitButton />, saveOnSubmit }: FormEngineV2Props) => {
	const isFormReviewModalOpen = useIsFormReviewModalOpen();

	const form = useNellieForm<Record<AcceptedNames, any>>({
		defaultValues,
		schema,
	});

	const renderSummary = () => {
		const { anxiety, depression, stress, sum } = getTotalsOfSubScales(defaultValues || {});

		return (
			<List pt="20px" px="40px">
				<ListItem>
					<b>Score:</b> {sum}
				</ListItem>
				<ListItem>
					<b>Depression:</b> {depression}
				</ListItem>
				<ListItem>
					<b>Anxiety:</b> {anxiety}
				</ListItem>
				<ListItem>
					<b>Stress:</b> {stress}
				</ListItem>
			</List>
		);
	};

	const handleSubmit = async (values: any) => {
		const { anxiety, depression, stress, sum } = getTotalsOfSubScales(values);

		await saveOnSubmit?.({
			...values,
			score: {
				anxiety: { value: anxiety, color: 'gray', title: 'Anxiety' },
				depression: { value: depression, color: 'red', title: 'Depression' },
				stress: { value: stress, color: 'gold', title: 'Stress' },
				sum: { value: sum, color: '#1E73BE', title: 'Score' },
			},
		});

		const formIsFinished = true;
		return formIsFinished;
	};

	return (
		<>
			{isFormReviewModalOpen && defaultValues && renderSummary()}
			<Form
				form={form}
				paddingTop={isFormReviewModalOpen && defaultValues ? '0' : undefined}
				title={auth.getUserRole() === Roles.client ? 'Symptom Questionnaire' : DASS_21_TITLE}
				onSubmit={handleSubmit}
			>
				<Text>
					Please read each statement and indicate how much the statement applied to you over the{' '}
					<Text as="span" fontWeight="bold" textDecor="underline">
						past week
					</Text>
					. There are no right or wrong answers. Do not spend too much time on any statement.
				</Text>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I found it hard to wind down"
					name="Q1"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I was aware of dryness of my mouth"
					name="Q2"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I couldn’t seem to experience any positive feeling at all"
					name="Q3"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I experienced breathing difficulty (e.g., excessively rapid breathing, breathlessness in the absence of physical exertion)"
					name="Q4"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I found it difficult to work up the initiative to do things"
					name="Q5"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I tended to over-react to situations"
					name="Q6"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I experienced trembling (e.g., in the hands)"
					name="Q7"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt that I was using a lot of nervous energy"
					name="Q8"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I was worried about situations in which I might panic and make a fool of myself"
					name="Q9"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt that I had nothing to look forward to"
					name="Q10"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I found myself getting agitated"
					name="Q11"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I found it difficult to relax"
					name="Q12"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt down-hearted and blue"
					name="Q13"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I was intolerant of anything that kept me from getting on with what I was doing"
					name="Q14"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt I was close to panic"
					name="Q15"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I was unable to become enthusiastic about anything"
					name="Q16"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt I wasn’t worth much as a person"
					name="Q17"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt that I was rather touchy"
					name="Q18"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I was aware of the action of my heart in the absence of physical exertion (e.g., sense of heart rate increase, heart missing a beat)"
					name="Q19"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt scared without any good reason"
					name="Q20"
					options={options}
					transformer={transformer}
				/>
				<RadioGroup<AcceptedNames>
					justifyContent="space-evenly"
					label="I felt that life was meaningless"
					name="Q21"
					options={options}
					transformer={transformer}
				/>
				{footer}
			</Form>
		</>
	);
};

export default memo(Dass21);
