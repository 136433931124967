import { ReactNode, createContext, memo, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import { filter } from 'lodash-es';

import { Api } from '@/api';
import { CreateNoteParams, CreateSectionParams, UpdateNoteParams } from '@/api/notes';
import { useAppMutation } from '@/app-react-query';
import { GlobalContext } from '@/context';
import { isFile } from '@/utils';
import auth from '@/utils/auth';

const useNoteApiInner = () => {
	const { loggedInUser, trainee, client: focusedClient } = useContext(GlobalContext);
	const clinicianId = trainee?.id || loggedInUser?.id;
	const clientId = focusedClient?.id as number;

	const queryClient = useQueryClient();
	const formContext = useFormContext<Note>();

	const setQueryData = (note: Note) => queryClient.setQueryData(['note', note.id], note);

	const { mutateAsync: duplicateNote } = useAppMutation(
		async (noteId: number) => {
			const newNote = await Api.notes.duplicateNote({
				id: noteId,
				client: clientId,
				clinician: clinicianId,
			});
			await queryClient.invalidateQueries(['notes']);

			setQueryData(newNote);
			return newNote;
		},
		{
			mutationKey: ['duplicateNote'],
			successMessage: 'Note duplicated',
			errorMessage: true,
		}
	);

	const { mutateAsync: archiveNote } = useAppMutation(
		async (noteId: number) => {
			const updatedNote = await Api.notes.archiveNote({
				id: noteId,
				client: clientId,
				clinician: clinicianId,
			});
			await queryClient.invalidateQueries(['notes']);

			setQueryData(updatedNote);
			formContext?.reset(updatedNote);
			return updatedNote;
		},
		{
			mutationKey: ['archiveNote'],
			successMessage: 'Note archived',
			errorMessage: true,
		}
	);

	const { mutateAsync: createNote } = useAppMutation(
		async ({ attachments, ...note }: CreateNoteParams) => {
			const createdNote = await Api.notes.createNote({
				...note,
				client: clientId,
				clinician: clinicianId,
				owner: auth.getUserInfo()?.id,
				attachments,
				status: attachments ? 'Published' : note.status,
			});

			await queryClient.invalidateQueries(['notes']);
			setQueryData(createdNote);

			return createdNote;
		},
		{
			mutationKey: ['createNote'],
			errorMessage: true,
		}
	);

	const { mutateAsync: updateNote } = useAppMutation(
		async (params: UpdateNoteParams) => {
			const updatedNote = await Api.notes.updateNote({
				client: clientId,
				clinician: clinicianId,
				...params,
			});

			// update only "last saved"
			// do not update the whole note because some fields
			// may lose focus because of form rerender
			queryClient.setQueryData<Note>(['note', +updatedNote.id], (note) => ({
				...(note || updatedNote),
				updatedAt: updatedNote.updatedAt,
			}));

			return updatedNote;
		},
		{
			mutationKey: ['updateNote'],
			errorMessage: true,
		}
	);

	const { mutateAsync: signNote } = useAppMutation(
		async (values: UpdateNoteParams | CreateNoteParams) => {
			const payload = {
				...values,
				status: 'Published',
				signatory: loggedInUser.id,
			};

			const note = (payload as UpdateNoteParams).id
				? await updateNote(payload as UpdateNoteParams).then(async (note) => {
						await queryClient.invalidateQueries(['notes']);
						setQueryData(note);
						return note;
				  })
				: await createNote(payload as CreateNoteParams);

			formContext?.reset(note);
			return note;
		},
		{
			mutationKey: ['signNote'],
			errorMessage: true,
		}
	);

	const { mutateAsync: amendNote } = useAppMutation(
		async ({ id, amendment }: { amendment: string; id: ID }) => {
			const note = await updateNote({
				id,
				sections: [
					{
						description: amendment,
						type: 'amendment',
						author: (loggedInUser as any).id,
					} as NoteSection,
				],
				amender: loggedInUser.id,
			});

			setQueryData(note);
			return note;
		},
		{
			mutationKey: ['amendNote'],
			errorMessage: true,
		}
	);

	const { mutateAsync: deleteNote } = useAppMutation(
		(id: ID) =>
			Api.notes.deleteNote({
				id,
				client: clientId,
				clinician: clinicianId,
			}),
		{
			mutationKey: ['deleteNote'],
			awaitInvalidateQueries: true,
			invalidateQueries: [['notes']],
			errorMessage: true,
		}
	);

	const { mutateAsync: downloadNoteAttachment } = useAppMutation(
		(attachment: Attachment) =>
			Api.notes.downloadNoteAttachment({
				...attachment,
				client: clientId,
				clinician: clinicianId,
			}),
		{
			mutationKey: ['downloadNoteAttachment'],
			errorMessage: true,
		}
	);

	const { mutateAsync: exportNotePdf } = useAppMutation(
		(params: {
			filename: string;
			id?: ID;
			q?: string;
			status?: NoteStatus;
			type?: NoteType | null;
		}) =>
			Api.notes.exportNotePdf({
				...params,
				client: clientId,
				clinician: clinicianId,
			}),
		{
			mutationKey: ['exportNotePdf'],
			errorMessage: true,
		}
	);

	const { mutateAsync: createNoteSection } = useAppMutation(
		async (params: CreateSectionParams) => {
			const noteSection = await Api.notes.createNoteSection({
				...params,
				client: clientId,
				clinician: clinicianId,
			});

			if (noteSection?.note?.id) {
				queryClient.setQueryData<Note>(
					['note', noteSection?.note.id],
					(note) =>
						({
							...note,
							updatedAt: noteSection.note.updatedAt,
						} as Note)
				);
			}

			return noteSection;
		},
		{
			mutationKey: ['createNoteSection'],
			errorMessage: true,
		}
	);

	const { mutateAsync: uploadNoteSectionAttachments } = useAppMutation(
		({ id, attachments }: Pick<NoteSection, 'id' | 'attachments'>) =>
			Api.notes.uploadAttachment({
				files: filter(attachments, isFile),
				note_section: id,
				client: clientId,
				clinician: clinicianId,
			}),
		{
			mutationKey: ['uploadNoteSectionAttachments'],
			errorMessage: true,
		}
	);

	const { mutateAsync: deleteNoteSection } = useAppMutation(
		(id: ID) => {
			return Api.notes.deleteNoteSection({
				client: clientId,
				clinician: clinicianId,
				id,
			});
		},
		{
			mutationKey: ['deleteNoteSection'],
			successMessage: true,
			errorMessage: true,
		}
	);

	const { mutate: updateNoteSectionOrder } = useAppMutation(
		async (params: { id: ID; sections: NoteSection[] }) => {
			return updateNote(params);
		},
		{
			mutationKey: ['updateNoteSectionOrder'],
		}
	);

	const refetchNotes = useCallback(() => {
		queryClient.invalidateQueries(['notes', focusedClient?.id]);
	}, [focusedClient?.id, queryClient]);

	return {
		createNote,
		updateNote,
		signNote,
		amendNote,
		duplicateNote,
		archiveNote,
		deleteNote,
		downloadNoteAttachment,
		exportNotePdf,
		createNoteSection,
		deleteNoteSection,
		updateNoteSectionOrder,
		uploadNoteSectionAttachments,
		refetchNotes,
	};
};

const NotesApiContext = createContext<ReturnType<typeof useNoteApiInner>>(
	{} as unknown as ReturnType<typeof useNoteApiInner>
);

export const useNoteApi = () => {
	return useContext(NotesApiContext);
};

export const NotesApiProvider = memo<{ children: ReactNode }>(({ children }) => {
	const notesApi = useNoteApiInner();

	return <NotesApiContext.Provider value={notesApi}>{children}</NotesApiContext.Provider>;
});
