import React, { memo, useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';

import {
	Button,
	ButtonGroup,
	FormLabel,
	Icon,
	IconButton,
	Stack,
	useColorModeValue,
} from '@chakra-ui/react';
import { isEqual } from 'lodash-es';

import { confirmationModal } from '@/components/ui';

import { useIsDisabled } from '../hooks/useIsDisabled';

import { formContext } from './Form';

type FieldArrayProps = {
	/** item to add */
	emptyItem: any;
	label?: string;
	name: string;
	renderItem: (namePrefix: string, item: any) => React.ReactNode;
	shouldUnregister?: boolean;
};

export const FieldArray = memo<FieldArrayProps>(
	({ renderItem, name, emptyItem, label, shouldUnregister }) => {
		const borderColor = useColorModeValue('#e2e8f0', ' #4e5766');
		const { isDisabled } = useIsDisabled();
		const { append, fields, remove } = useFieldArray({
			shouldUnregister,
			name,
		});

		const { getValues } = useFormContext();

		const { saveOnBlur } = useContext(formContext);

		return (
			<Stack width="100%">
				{label && <FormLabel>{label}</FormLabel>}
				<Stack gap={4} mt={4}>
					{fields.map((item, index) => {
						return (
							<Stack
								key={item.id}
								border="1px solid"
								borderColor={borderColor}
								borderRadius="4px"
								padding="20px"
								paddingBottom="30px"
								position="relative"
							>
								{!isDisabled && (
									<ButtonGroup position="absolute" right="10px" top="10px">
										{fields.length > 1 && (
											<IconButton
												_hover={{ opacity: 1 }}
												aria-label="remove"
												icon={<Icon as={AiOutlineClose} />}
												opacity={0.3}
												size="sm"
												onClick={async () => {
													try {
														await confirmationModal();
														remove(index);
														saveOnBlur?.(getValues());
													} catch {
														//
													}
												}}
											/>
										)}
									</ButtonGroup>
								)}
								{renderItem(`${name}.${index}.`, item)}
							</Stack>
						);
					})}
					{!isDisabled && (
						<Button _hover={{ bg: 'purple.400', color: 'white' }} onClick={() => append(emptyItem)}>
							ADD MORE
						</Button>
					)}
				</Stack>
			</Stack>
		);
	},
	isEqual
);
