import { useContext } from 'react';
import { FcSurvey } from 'react-icons/fc';

import { GlobalContext } from '@/context';
import { ROUTES } from '@/routes';
import auth from '@/utils/auth';

import { NavItem } from '../components/NavItem';

export const DETAILED_EXPLANATION = 'detailed-explanation';

export const AuthenticatedNavItems = ({ isCollapsed, onClose }) => {
	const { generalInfoComplete } = useContext(GlobalContext);

	return generalInfoComplete ? (
		<></>
	) : (
		<NavItem
			icon={FcSurvey}
			isCollapsed={isCollapsed}
			label="Intake Survey"
			py="3"
			to={
				// logic to decide to start a new form or continue an existing one
				(auth.getUserInfo() as NellieUser).general_info_id
					? [
							ROUTES.FORMS_V2,
							{
								state: {
									response_id: (auth.getUserInfo() as NellieUser).general_info_id as string,
								},
							},
					  ]
					: ROUTES.GENERAL_INFO
			}
			onClick={onClose}
		/>
	);
};
