import { memo } from 'react';

import {
	Button,
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import { openEmailClient } from '@/utils';

export const HelpAndFeedbackModal = memo<{ onClose: VoidFunction }>(({ onClose }) => (
	<Modal closeOnOverlayClick isOpen size="xl" onClose={onClose}>
		<ModalOverlay />
		<ModalContent>
			<ModalHeader textAlign="center">Help & Feedback</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<Text px={2}>
					<Text fontWeight="bold" mb={6}>
						If this is an emergency, please contact your local emergency number.
					</Text>
					<Text>
						If you require any technical assistance or would like to provide feedback on how to
						improve the platform, please send an email to:{' '}
						<Center>
							<Text
								_hover={{ color: 'purple.600' }}
								color="purple.500"
								cursor="pointer"
								fontSize="xl"
								mt={4}
								textDecor="underline"
								onClick={openEmailClient}
							>
								info@nelliehealth.com
							</Text>
						</Center>
					</Text>
				</Text>
			</ModalBody>

			<ModalFooter>
				<Button colorScheme="purple" onClick={onClose}>
					Close
				</Button>
			</ModalFooter>
		</ModalContent>
	</Modal>
));
