import { useCallback, useState } from 'react';

import { useMediaQuery, useUpdateEffect } from '@chakra-ui/react';
import { isBoolean } from 'lodash-es';

export type UseSidebarCollapseResult = ReturnType<typeof useSidebarCollapse>;

const STORAGE_KEY = 'sidebarIsCollapsed';

const IPAD_AIR_ROTATED_WIDTH = 1180;
export const useSidebarCollapse = () => {
	// let sidebar be open by default, for Ipad Air size tablets
	const [shouldCollapseSidebar] = useMediaQuery(`(max-width: ${IPAD_AIR_ROTATED_WIDTH - 1}px)`);
	const [{ isCollapsed }, setState] = useState<{
		isCollapsed: boolean;
		shouldCollapseAuto: boolean;
	}>(() => {
		try {
			return {
				shouldCollapseAuto: true,
				isCollapsed: JSON.parse(localStorage.getItem(STORAGE_KEY) as string),
			};
		} catch (error) {
			return {
				shouldCollapseAuto: true,
				isCollapsed: false,
			};
		}
	});

	useUpdateEffect(() => {
		setState((prev) => {
			if (prev.shouldCollapseAuto) {
				return {
					...prev,
					isCollapsed: shouldCollapseSidebar,
				};
			}

			return prev;
		});
	}, [shouldCollapseSidebar]);

	useUpdateEffect(() => {
		localStorage.setItem(STORAGE_KEY, isCollapsed.toString());
	}, [isCollapsed]);

	const toggleCollapseManual = useCallback((isCollapsed?: boolean) => {
		if (isBoolean(isCollapsed)) {
			setState({
				shouldCollapseAuto: false,
				isCollapsed,
			});
		} else {
			setState((prev) => ({
				shouldCollapseAuto: !prev.shouldCollapseAuto,
				isCollapsed: !prev.isCollapsed,
			}));
		}
	}, []);

	return [isCollapsed, toggleCollapseManual] as [boolean, (isCollapsed?: boolean) => void];
};
