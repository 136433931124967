import { ITimezone, ITimezoneOption } from 'react-timezone-select';

import { omit } from 'lodash-es';

import { Roles } from '@/utils';
import auth from '@/utils/auth';

import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-users';

const getMyInfo = async () => {
	const user = await httpClient.post<Client | Clinician>(`${BASE_URL}/getMyInfo`);

	if (user) {
		auth.setUserInfo({
			role: user.role.type,
			id: user.id,
			username: user.username,
			email: user.email,
			first_name: user.nellie_user.first_name,
			initials: user.initials,
			privacy_terms_acknowledged: user.nellie_user.privacy_terms_acknowledged,
			general_info_complete: user.nellie_user.general_info_complete,
			general_info_id: user.nellie_user.general_info_id,
			verified_phone_number: user.nellie_user.verified_phone_number,
		});

		auth.setUserRole(user.role.type);
	}

	return user;
};

const getMyRole = () => httpClient.post<Roles>(`${BASE_URL}/getMyRole`);

export type GetClientsResponse = {
	email: string;
	id: ID;
	nellie_user: NellieUser;
	trainee?: {
		first_name: string;
		id: ID;
		last_name: string;
	};
	unreadMessagesCount?: number;
}[];

const getUnreadMessagesCount = (params: { recipient_id?: string; sender_id?: string }) => {
	return httpClient.post(`${BASE_URL}/getUnreadMessagesCount`, params);
};

const getClients = () => {
	return httpClient.post<GetClientsResponse>(`${BASE_URL}/getClients`);
};

const getMyAndTraineeClients = () => {
	return httpClient.post<GetClientsResponse>(`${BASE_URL}/getMyAndTraineeClients`);
};

export type GetCliniciansResponse = {
	email: string;
	id: ID;
	nellie_user: NellieUser;
	role: { id: ID; type: Roles };
};

const getClientInfo = (client_id: string) => {
	return httpClient.post<GetCliniciansResponse>(`${BASE_URL}/getClientInfo`, { client_id });
};

export type GetProfileResponse = {
	accountType: Roles;
	address_city: string | null;
	address_country: string | null;
	address_postal: string | null;
	address_province_state: string | null;
	address_street: string | null;
	clientStatus: string | null;
	clinician?: Clinician;
	dob: string | null;
	readonly email: string;
	emerg_email: string | null;
	emerg_first_name: string | null;
	emerg_last_name: string | null;
	emerg_local_hospital_name: string | null;
	emerg_local_hospital_number: string | null;
	emerg_phone: string | null;
	first_name: string;
	last_name: string;
	middle_name: string | null;
	notifications_email: boolean | null;
	notifications_sms: boolean | null;
	phone: string | null;
	phone2: string | null;
	signature: string | null;
	timezone: ITimezoneOption | null;
	two_factor_enabled?: boolean;
	verified_phone_number?: string;
};

const getMyProfile = () => {
	return httpClient.post<GetProfileResponse>(`${BASE_URL}/getMyProfile`);
};
type GetClientProfileParams = { client_id: number };

const getClientProfile = (params: GetClientProfileParams) => {
	return httpClient.post<GetProfileResponse & { hasAssignedClinician: boolean }>(
		`${BASE_URL}/getClientProfile`,
		params
	);
};

type UpdateProfileParams = Partial<
	{
		clinician_id?: number;
		general_info_complete: boolean;
		general_info_id: number;
		timezone: ITimezone;
	} & Omit<GetProfileResponse, 'timezone'>
>;
const updateMyProfile = (data: UpdateProfileParams) => {
	return httpClient.post<GetProfileResponse>(
		`${BASE_URL}/updateMyProfile`,
		omit(data, 'verified_phone_number') as GetProfileResponse
	);
};

const updateClientProfile = (data: UpdateProfileParams & GetClientProfileParams) => {
	return httpClient.post(`${BASE_URL}/updateClientProfile`, data as GetProfileResponse);
};

export type GetCoordinatorUser = {
	first_name: string;
	last_name: string;
	user_id: string;
};

const getCoordinatorClinicians = () => {
	return httpClient.post<GetCoordinatorUser[]>(`${BASE_URL}/getCoordinatorClinicians`);
};

export type GetCoordinatorClient = GetCoordinatorUser & {
	clientStatus: string;
	clinician: {
		first_name: string;
		last_name: string;
		user_id: string;
	};
	email: string;
	emerg_phone: string;
	phone: string;
};

export type GetCoordinatorClientResponse = {
	data: GetCoordinatorClient[];
	total: number;
};

export type GetCoordinatorClientsParams = Partial<
	PagingParams & { hideInactiveUsers?: boolean; search: string }
>;
const getCoordinatorClients = (params: GetCoordinatorClientsParams) => {
	return httpClient.post<GetCoordinatorClientResponse>(`${BASE_URL}/getCoordinatorClients`, params);
};

export type AssignClinicianToClientParams = {
	client_id: string;
	clinician_id: string;
};
const assignClinicianToClient = (params: AssignClinicianToClientParams) => {
	return httpClient.post(`${BASE_URL}/assignClinicianToClient`, params);
};

const getCoordinatorAuthenticatedUsers = (params: GetCoordinatorClientsParams) => {
	return httpClient.post<GetCoordinatorClientResponse>(
		`${BASE_URL}/getCoordinatorAuthenticatedUsers`,
		params
	);
};

const sendVerificationCode = (phoneNumber: string) => {
	return httpClient.post(`${BASE_URL}/sendVerificationCode`, { phone_number: phoneNumber });
};

const verifyVerificationCode = (params: { code: string; phone_number: string }) => {
	return httpClient.post(`${BASE_URL}/verifyVerificationCode`, params);
};

const invalidateVerificationCode = () => {
	return httpClient.post(`${BASE_URL}/invalidateVerificationCode`);
};

const addSignature = (signature: string) => {
	return httpClient.post<boolean>(`${BASE_URL}/addSignature`, { signature });
};

export const usersApi = {
	getMyInfo,
	getMyRole,
	getClients,
	getMyAndTraineeClients,
	getClientInfo,
	getCoordinatorClients,
	getCoordinatorClinicians,
	getCoordinatorAuthenticatedUsers,
	getMyProfile,
	updateMyProfile,
	addSignature,
	updateClientProfile,
	getClientProfile,
	assignClinicianToClient,
	sendVerificationCode,
	invalidateVerificationCode,
	verifyVerificationCode,
	getUnreadMessagesCount,
};
