import { memo, useCallback, useState } from 'react';
import { NavigateOptions, useMatch, useNavigate } from 'react-router-dom';

import {
	As,
	Box,
	Circle,
	HStack,
	Icon,
	Link,
	LinkProps,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { isString } from 'lodash-es';

import { useSidebarColors } from '../hooks';

import { NavItemTooltip } from './NavItemTooltip';

interface NavLinkProps extends LinkProps {
	count?: number;
	icon?: As;
	isCollapsed?: boolean;
	label: string;
	to: string | [string, NavigateOptions];
}

const isTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const useMobileNavigation = (navigateTo: VoidFunction) => {
	const [showTooltip, setShowTooltip] = useState<boolean | undefined>(isTablet ? false : undefined);

	const onTouch = useCallback(() => {
		navigateTo();
		setShowTooltip(true);
		setTimeout(() => {
			setShowTooltip(false);
		}, 1000);
	}, [navigateTo]);

	return {
		showTooltip,
		onTouch,
	};
};

export const NavItem = memo<NavLinkProps>(
	({ count, icon, label, to, isCollapsed, target, onClick, ...rest }) => {
		const [sidenavIsVisible] = useMediaQuery('(min-width: 768px)');

		const { _linkActive, _linkHover, color } = useSidebarColors();
		const navigate = useNavigate();

		const isActiveRoute = useMatch({ path: isString(to) ? to : to[0], end: false });
		const navigateTo = useCallback(() => {
			if (isActiveRoute) return;
			// this is workaround for mobile devices issues
			if (target === '_blank') return window.open(isString(to) ? to : to[0], '_blank');

			if (isString(to)) navigate(to);
			else navigate(...to);
		}, [isActiveRoute, target, to, navigate]);

		const { onTouch, showTooltip } = useMobileNavigation(navigateTo);

		return (
			<Box position="relative">
				{Boolean(count) && (
					<Circle
						bg="red"
						color="white"
						fontSize="12px"
						fontWeight="bolder"
						lineHeight="7px"
						p="7px"
						position="absolute"
						right={isCollapsed ? '-3px' : '2%'}
						top={isCollapsed ? '8px' : '50%'}
						transform={isCollapsed ? 'translateY(-50%)' : 'translate(-50%, -50%)'}
					>
						{count}
					</Circle>
				)}
				<NavItemTooltip
					isDisabled={!isCollapsed}
					isOpen={isCollapsed ? showTooltip : undefined}
					label={<Text as="span">{label}</Text>}
				>
					<span onClick={navigateTo} onTouchEnd={sidenavIsVisible ? onTouch : undefined}>
						<Link
							_activeLink={_linkActive}
							_focus={{ shadow: 'none' }}
							_hover={isTablet ? undefined : isActiveRoute ? _linkActive : _linkHover}
							aria-current={isActiveRoute ? 'page' : undefined}
							borderRadius="md"
							color={color}
							display="block"
							fontWeight="medium"
							lineHeight="1.5rem"
							mt={2}
							pt={3}
							px={3}
							transition="all 0.3s"
							w={isCollapsed ? 'max-content' : undefined}
							{...rest}
							onClick={onClick}
							onTouchEnd={sidenavIsVisible ? (onClick as any) : undefined}
						>
							<HStack spacing={4}>
								{icon && <Icon as={icon} boxSize="5" />}
								{!isCollapsed && <Text as="span">{label}</Text>}
							</HStack>
						</Link>
					</span>
				</NavItemTooltip>
			</Box>
		);
	}
);
