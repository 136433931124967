import { useLocation, useNavigate } from 'react-router-dom';

import { NotAllowedIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import { find } from 'lodash-es';

import { CustomSpinnerTiny } from '@/components/ui';
import { useGetClients } from '@/hooks';
import { ROUTES } from '@/routes';
import { generateBookingUrl } from '@/utils';
import auth from '@/utils/auth';

type SchedulerLinkProps = {
	children?: React.ReactNode;
	clientId: number;
};

export const SchedulerLink = ({ clientId, children }: SchedulerLinkProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	const {
		data: clinicianUrl,
		isLoading,
		error,
	} = useGetClients({
		select: (allClients) => {
			const currentClient = find(allClients, { id: Number(clientId) }) as Client;
			const clinician = auth.getUserInfo();
			return generateBookingUrl(clinician, {
				...currentClient,
				first_name: currentClient.nellie_user.first_name,
			});
		},
	});

	return (
		<>
			{isLoading ? (
				<CustomSpinnerTiny />
			) : error ? (
				<Tooltip label="Unable to schedule appointment">
					<span>
						<NotAllowedIcon />
					</span>
				</Tooltip>
			) : (
				<span
					onClickCapture={(e) => {
						e.stopPropagation();
						navigate(ROUTES.APPOINTMENTS_CREATE, {
							state: { ...(location?.state as Record<string, unknown>), clinicianUrl },
						});
					}}
				>
					{children}
				</span>
			)}
		</>
	);
};
