import { Suspense, lazy } from 'react';

import type { MarkdownProps } from './MarkdownUI';

export type { MarkdownProps };

const MarkdownUI = lazy(() => import('./MarkdownUI'));

export const Markdown = (props: MarkdownProps) => {
	return (
		<Suspense>
			<MarkdownUI {...props} />
		</Suspense>
	);
};
