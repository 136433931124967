import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { GetFormsResponse } from '@/api/forms';
import { useAppQuery } from '@/app-react-query';

/**
 * `useGetForms` is a React hook that returns the result of calling `useQuery` with the
 * `Api.forms.getForms` function
 */
export const useGetForms = <ActualResponse = GetFormsResponse, TError = AxiosError>(
	options?: Omit<UseQueryOptions<GetFormsResponse, TError, ActualResponse>, 'queryKey' | 'queryFn'>
) => {
	return useAppQuery<GetFormsResponse, TError, ActualResponse>(
		['Api.forms.getForms'],
		() => Api.forms.getForms(),
		{
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			...options,
		}
	);
};
