import { ReactNode, createContext, memo, useContext, useReducer } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

import { useUnmountEffect } from '@chakra-ui/react';
import { indexOf, isNil, union } from 'lodash-es';

import { useMountEffect } from '@/hooks';

export const registeredFieldsContext = createContext<{
	registerField(value: string): void;
	registeredFields: string[];
	unregisterField(value: string): void;
}>({
	registerField: () => undefined,
	unregisterField: () => undefined,
	registeredFields: [],
});

export const RegisteredFieldsContextProvider = memo<{
	children: ReactNode;
}>(({ children }) => {
	const [registeredFields, dispatch] = useReducer(
		(state = [], { name, type }: { name: string; type: 'register' | 'unregister' }) => {
			if (type === 'unregister') return state.filter((field) => field !== name);
			if (type === 'register') return union([...state, name]);
		},
		[],
		undefined
	);

	return (
		<registeredFieldsContext.Provider
			value={{
				registeredFields,
				unregisterField: (name: string) =>
					dispatch({
						name: name,
						type: 'unregister',
					}),
				registerField: (name: string) =>
					dispatch({
						name: name,
						type: 'register',
					}),
			}}
		>
			{children}
		</registeredFieldsContext.Provider>
	);
});

export type ValueTransformer = {
	input: (value: any) => any;
	output: (value: any) => any;
};

export const useNellieController = (
	props: UseControllerProps & {
		transformer?: ValueTransformer;
	}
) => {
	const { registerField, unregisterField } = useContext(registeredFieldsContext);

	useMountEffect(() => {
		registerField?.(props.name);
	});

	useUnmountEffect(() => {
		unregisterField?.(props.name);
	});

	const { field, ...rest } = useController(props);

	return {
		...rest,
		field: {
			...field,
			value: props.transformer?.input ? props.transformer?.input(field.value) : field.value,
			onChange: props.transformer?.output
				? (e) => field.onChange(props.transformer?.output(e))
				: field.onChange,
		},
	};
};

export const transformers = {
	/**
	 * return selected option's index as a value
	 *
	 * Example:
	 *  ```ts
	 * options = ['Very little', 'A little', 'Some', 'Much', 'Very much']
	 * selectedOption = 'Some'
	 * value will be 2
	 * ```
	 *
	 *
	 * Example usage:
	 *  ```tsx
	 * <RadioGroup
	 *    transformer={stringArrayToIndex(options)}
	 *    options={options}
	 * />
	 * ```
		/>
	 */
	stringArrayToIndex: (options: string[]): ValueTransformer => ({
		input: (value?: string) => (isNil(value) ? undefined : options[value]),
		output: (value: any) => (isNil(value) ? undefined : indexOf(options, value)),
	}),

	/**
	 * return selected option's index as a value
	 *
	 * Example:
	 *  ```ts
	 * options = ['Very little', 'A little', 'Some', 'Much', 'Very much']
	 * selectedOption = 'Some'
	 * value will be 3
	 * ```
	 *
	 *
	 * Example usage:
	 *  ```tsx
	 * <RadioGroup
	 *    transformer={stringArrayToIndexStartingFromOne(options)}
	 *    options={options}
	 * />
	 * ```
		/>
	 */
	stringArrayToIndexStartingFromOne: (options: string[]): ValueTransformer => ({
		input: (value?: number) => {
			console.log({ value, options });
			return isNil(value) ? undefined : options[value - 1];
		},
		output: (value: any) => {
			console.log({ value });
			console.log(indexOf(options, value) + 1);
			return isNil(value) ? undefined : indexOf(options, value) + 1;
		},
	}),
};
