import { ReactNode, forwardRef, memo, useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { Resizable } from 'react-resizable';

import { Box, HStack, Icon } from '@chakra-ui/react';
import { omit } from 'lodash-es';

import { lazy } from '@/utils/react-wrappers';

import { resizablePipPadding } from './ResizablePIP';

const Draggable = lazy(() => import('react-draggable'));

export type ResizablePIPProps = {
	actionsBar?: ReactNode;
	children: (size: { height: number; width: number }) => JSX.Element;
	defaultHeight: number;
	defaultWidth: number;
	maxConstraints: number[] | undefined;
	minConstraints: number[] | undefined;
};

export const ResizablePIP = memo<ResizablePIPProps>(
	({ children, defaultWidth, defaultHeight, minConstraints, maxConstraints, actionsBar }) => {
		const [size, setSize] = useState({
			width: defaultWidth,
			height: defaultHeight,
		});

		const [isDragging, setIsDragging] = useState(false);
		const [isResizing, setIsResizing] = useState(false);

		return (
			<Box
				__css={{
					'.draggable': {
						borderRadius: '5',
						boxShadow: '1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%)',
						className: 'pipWindow',
						pos: 'fixed',
						zIndex: '1000',

						'&:hover': { cursor: 'grab' },
					},
				}}
			>
				<Draggable
					defaultClassName="draggable"
					disabled={isResizing}
					onStart={() => setIsDragging(true)}
					onStop={() => setIsDragging(false)}
				>
					<Box
						bg="#242d36"
						borderRadius="md"
						h={size.height + resizablePipPadding + (actionsBar ? 25 : 15) + 'px'}
						p={resizablePipPadding + 'px'}
						position="relative"
						pt={actionsBar ? '25px' : undefined}
						w={size.width + resizablePipPadding * 2 + 'px'}
					>
						{actionsBar && (
							<HStack position="absolute" right="15px" spacing="5px" top="4px">
								{actionsBar}
							</HStack>
						)}
						<Box pointerEvents={isDragging || isResizing ? 'none' : undefined}>
							<Resizable
								lockAspectRatio
								handle={<ResizeHandler />}
								handleSize={[10, 10]}
								height={size.height}
								maxConstraints={maxConstraints as [number, number]}
								minConstraints={minConstraints as [number, number]}
								resizeHandles={['w']}
								width={size.width}
								onResize={(e, { size }) => {
									setSize(size);
								}}
								onResizeStart={() => setIsResizing(true)}
								onResizeStop={() => setIsResizing(false)}
								{...{
									onMouseDown: (e) => {
										e.stopPropagation();
									},
								}}
							>
								<Box height={size.height + 'px'} width={size.width + 'px'}>
									{children(size)}
								</Box>
							</Resizable>
						</Box>
					</Box>
				</Draggable>
			</Box>
		);
	}
);

export default ResizablePIP;

const ResizeHandler = forwardRef<any, any>((props, ref) => {
	return (
		<Box
			ref={ref}
			cursor="w-resize"
			fontSize="20px"
			left="2.5px"
			position="absolute"
			top="1px"
			{...{ ...omit(props, 'handleAxis') }}
		>
			<Icon as={BsChevronLeft} color="white" transform="rotate(45deg)" />
		</Box>
	);
});
