import { createContext, useContext, useMemo, useReducer } from 'react';

import { ROUTES } from '@/routes';
import { VideoCallStatus } from '@/utils';
import auth from '@/utils/auth';

import AppReducer from './AppReducer';

// Initial state
const initialState = {
	userType: '',
	videoCallStatus: '' as VideoCallStatus,
};

// TODO Remove Actions from this context its just for backward compatibility
type State = Actions & {
	client?: { email: string } & NellieUser;
	generalInfoComplete?: boolean;
	loggedInUser: NellieUser;
	trainee?: NellieUser;
	videoCallStatus: VideoCallStatus;
};

type Actions = {
	logout: () => void;
	setClient: (client?: { email: string; nellie_user: NellieUser }) => void;
	setGlobalInfoComplete: (generalInfoComplete: boolean) => void;
	setLoggedInUser: (user: NellieUser) => void;
	setTrainee: (trainee?: State['trainee']) => void;
	setVideoCallStatus: (status: VideoCallStatus) => void;
};

// Create context
export const GlobalContext = createContext<State>({} as State);
GlobalContext.displayName = 'GlobalContext';

const GlobalActionsContext = createContext<Actions>({} as Actions);
export const useGlobalActions = () => useContext(GlobalActionsContext);

// Provider component
export const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, initialState);

	// this can be safely used in dependency arrays
	const actions = useMemo<Actions>(() => {
		function setGlobalInfoComplete(generalInfoComplete: boolean) {
			dispatch({
				type: 'SET_GLOBAL_INFO_COMPLETE',
				payload: generalInfoComplete,
			});
		}

		function logout() {
			auth.clearAppStorage();
			auth.clearToken();
			// logout with browser reload to ensure that no data is cached
			window.location.replace(ROUTES.LOGIN);
		}

		function setVideoCallStatus(status) {
			dispatch({
				type: 'SET_VIDEO_CALL_STATUS',
				payload: status,
			});
		}

		function setTrainee(trainee?: State['trainee']) {
			auth.setTrainee(trainee);
			dispatch({
				type: 'SET_TRAINEE',
				payload: trainee,
			});
		}

		function setClient(client?: { email: string; nellie_user: NellieUser }) {
			auth.setClient(client ? { ...client.nellie_user, email: client?.email || '' } : undefined);
			auth.setClientID(client?.nellie_user?.user_id);
			dispatch({
				type: 'SET_CLIENT',
				payload: client ? { ...client.nellie_user, email: client?.email || '' } : undefined,
			});
		}

		function setLoggedInUser(user: NellieUser) {
			dispatch({
				type: 'SET_LOGGED_IN_USER',
				payload: user,
			});
		}

		return {
			setTrainee,
			setClient,
			setVideoCallStatus,
			logout,
			setGlobalInfoComplete,
			setLoggedInUser,
		};
	}, []);

	const stateValue = useMemo<State>(() => {
		return {
			...state,
			...actions,
		};
	}, [actions, state]);

	return (
		<GlobalActionsContext.Provider value={actions}>
			<GlobalContext.Provider value={stateValue}>{children}</GlobalContext.Provider>
		</GlobalActionsContext.Provider>
	);
};
