import { FiDatabase } from 'react-icons/fi';
import { ImStatsBars } from 'react-icons/im';
import { RiSettingsLine } from 'react-icons/ri';

import { ROUTES } from '@/routes';

import { NavItem } from '../components/NavItem';

export const CoordinatorNavItems = ({ isCollapsed }) => {
	const genericProps = {
		isCollapsed,
		py: 3,
	};

	return (
		<>
			<NavItem icon={FiDatabase} label="Client Manager" to={ROUTES.CCM} {...genericProps} />
			<NavItem icon={ImStatsBars} label="Analytics" to={ROUTES.ANALYTICS} {...genericProps} />
			<NavItem icon={RiSettingsLine} label="Settings" to={ROUTES.SETTINGS} {...genericProps} />
		</>
	);
};
