import { useFormState } from 'react-hook-form';

import { Button, ButtonProps } from '@chakra-ui/react';

import { useIsDisabled } from '../hooks/useIsDisabled';

export const SubmitButton = (props: ButtonProps) => {
	const { isSubmitting } = useFormState();
	const { isDisabled } = useIsDisabled();

	if (isDisabled) return <></>;

	return (
		<Button
			colorScheme="teal"
			isDisabled={isSubmitting}
			isLoading={isSubmitting}
			maxW="600"
			minW="200"
			size="lg"
			textAlign="center"
			type="submit"
			{...props}
		>
			{props.children || 'Submit'}
		</Button>
	);
};
