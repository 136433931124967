import { FC, Suspense } from 'react';

import { CustomSpinner } from '@/components/ui';

import { FormContextProvider, FormIsCompleted } from '../form-elements/Form';

import { documents } from './documents';

export type FormEngineV2Props<Value extends Record<string, any> = Record<string, any>> = {
	defaultValues?: Value;
	footer?: JSX.Element;
	nextFormId?: number;
	responseId?: number;
	saveOnBlur?: (values: any) => Promise<number | undefined>;
	saveOnSubmit?: (values: any) => Promise<FormIsCompleted>;
};

export function isFormEngineV2({ form_data } = {} as { form_data: { version?: number } }) {
	return form_data?.version === 2;
}

export const FormEngineV2 = ({
	Component: ComponentName,
	saveOnBlur,
	saveOnSubmit,
	defaultValues,
	footer,
	nextFormId,
	responseId,
}: FormEngineV2Props & { Component: string }) => {
	const Component: FC<FormEngineV2Props> = documents[ComponentName];

	return (
		<Suspense fallback={<CustomSpinner />}>
			<FormContextProvider saveOnBlur={saveOnBlur} saveOnSubmit={saveOnSubmit}>
				<Component
					defaultValues={defaultValues}
					footer={footer}
					nextFormId={nextFormId}
					responseId={responseId}
					saveOnBlur={saveOnBlur}
					saveOnSubmit={saveOnSubmit}
				/>
			</FormContextProvider>
		</Suspense>
	);
};
