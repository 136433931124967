import { useSwipeable } from 'react-swipeable';

export const useSwipeToClose = (onClose) => {
	return useSwipeable({
		onSwipedRight: ({ initial, deltaX }) => {
			const [start] = initial;
			if (start < 70 && deltaX > 30) {
				onClose();
			}
		},
	});
};
