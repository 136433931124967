import { RefObject, createContext, useContext, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
	Box,
	Drawer,
	DrawerContent,
	useColorModeValue as mode,
	useDisclosure,
} from '@chakra-ui/react';

import { CustomSpinner } from '@/components/ui';
import { SidebarContext, useGlobalActions } from '@/context';
import { useGetMyInfo } from '@/hooks';
import { ROUTES } from '@/routes';
import { Roles } from '@/utils/constants';

import { SidebarContent } from './components/SidebarContent';
import TopMobileMenu from './components/TopMobileMenu';
import { UseSidebarCollapseResult, useSidebarCollapse } from './hooks';
import { SCROLLABLE_CLASSNAME, SIDEBAR_WIDTH } from './utils/constants';

const appContentContext = createContext<RefObject<HTMLDivElement>>({ current: null });
export const useAppContentRef = () => useContext(appContentContext);
export default function NavLayout({ children }) {
	const bgColor = mode('gray.50', 'gray.800');

	const appContentRef = useRef<HTMLDivElement>(null);
	const { logout } = useGlobalActions();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isCollapsed, toggleIsCollapsed] = useSidebarCollapse();

	const contextValue = useMemo<UseSidebarCollapseResult>(
		() => [isCollapsed, toggleIsCollapsed],
		[isCollapsed, toggleIsCollapsed]
	);

	const location = useLocation();

	const { data, isLoading } = useGetMyInfo({
		enabled:
			location.pathname !== ROUTES.LOGIN &&
			location.pathname !== ROUTES.REGISTER &&
			location.pathname !== ROUTES.FORGOT &&
			location.pathname !== ROUTES.RESET,
	});

	const role = useMemo<Maybe<Roles>>(() => data?.role.type, [data?.role.type]);
	const initials = useMemo(() => data?.initials, [data?.initials]);

	if (isLoading) return <CustomSpinner />;

	if (role && role in Roles) {
		return (
			<SidebarContext value={contextValue}>
				<Box
					__css={{
						[`.${SCROLLABLE_CLASSNAME}`]: {
							'&::-webkit-scrollbar': {
								width: '8px',
							},

							'&::-webkit-scrollbar-thumb': {
								backgroundColor: 'rgba(39, 40, 41, 0.409)' /* chakra gray.100 */,
								borderRadius: '8px',
							},
						},
					}}
					bg={bgColor}
					minH="100vh"
					minW="full"
				>
					{!isOpen && (
						<SidebarContent
							initials={initials}
							isCollapsed={isCollapsed}
							isOpen={isOpen}
							logout={logout}
							role={role}
							toggleIsCollapsed={toggleIsCollapsed}
							onClose={onClose}
						/>
					)}

					<Drawer
						autoFocus={false}
						isOpen={isOpen}
						placement="left"
						returnFocusOnClose={false}
						size="xs"
						onClose={onClose}
						onOverlayClick={onClose}
					>
						<DrawerContent>
							<SidebarContent
								initials={initials}
								isOpen={isOpen}
								logout={logout}
								role={role}
								onClose={onClose}
							/>
						</DrawerContent>
					</Drawer>

					<Box display={{ base: 'block', md: 'none' }} position="sticky" top="0" zIndex="99">
						<TopMobileMenu initials={initials} logout={logout} role={role} onOpen={onOpen} />
					</Box>

					<appContentContext.Provider value={appContentRef}>
						<Box
							ref={appContentRef}
							bg={bgColor}
							ml={{ base: 0, md: isCollapsed ? SIDEBAR_WIDTH.collapsed : SIDEBAR_WIDTH.expanded }}
							p="4"
						>
							{children}
						</Box>
					</appContentContext.Provider>
				</Box>
			</SidebarContext>
		);
	}

	return children;
}
