import { ReactNode } from 'react';

import { Flex, Grid, GridProps, useMediaQuery } from '@chakra-ui/react';

type ListProps = {
	columnGap?: GridProps['columnGap'];
	fontSize: string[];
	items: ReactNode[];
	maxColumns: 1 | 2 | 3 | 4;
	rowGap?: GridProps['rowGap'];
};

export const List = ({ items, maxColumns, columnGap = 4, rowGap = 2, fontSize }: ListProps) => {
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

	const gridTemplateColumns = () => {
		if (isLargerThan1024) {
			return `repeat(${maxColumns > 4 ? 4 : maxColumns}, auto)`;
		} else if (isLargerThan768) {
			return `repeat(${maxColumns > 3 ? 3 : maxColumns}, auto)`;
		} else if (isLargerThan600) {
			return `repeat(${maxColumns > 2 ? 2 : maxColumns}, auto)`;
		} else {
			return 'repeat(1, auto)';
		}
	};

	return (
		<Grid
			columnGap={columnGap}
			gridTemplateColumns={gridTemplateColumns()}
			marginBottom={10}
			marginTop={10}
			rowGap={rowGap}
			textAlign="start"
			w="100%"
		>
			{items.map((item, index) => (
				<Flex key={index} fontSize={fontSize} p={1} w="fit-content" wordBreak="break-word">
					{item}
				</Flex>
			))}
		</Grid>
	);
};

// NOTE adding too much styling complexity to the forms; implement this eventually if possible;
{
	/* <ul>
	<li>{item}</li>
</ul> */
}
