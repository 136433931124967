import { memo } from 'react';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import {
	Avatar,
	Box,
	Flex,
	FlexProps,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	VStack,
	useColorModeValue as mode,
} from '@chakra-ui/react';

import { ROUTES } from '@/routes';
import { Roles } from '@/utils';

interface MobileProps extends FlexProps {
	initials?: string;
	logout: () => void;
	onOpen: () => void;
	role?: string;
}
const TopMobileMenuInner = memo(
	({ onOpen, logout, navigate, ...rest }: MobileProps & { navigate: NavigateFunction }) => {
		const bgColor = mode('white', 'gray.900');
		const bgBorder = mode('gray.200', 'gray.700');
		const bgBorderBottom = mode('gray.200', 'gray.700');

		return (
			<Flex
				alignItems="center"
				bg={bgColor}
				borderBottomColor={bgBorderBottom}
				borderBottomWidth="1px"
				height="14"
				justifyContent={{ base: 'space-between', md: 'flex-end' }}
				ml={{ base: 0, md: 60 }}
				px={{ base: 4, md: 4 }}
				{...rest}
			>
				<IconButton
					aria-label="open menu"
					display={{ base: 'flex', md: 'none' }}
					icon={<FiMenu />}
					variant="outline"
					onClick={onOpen}
				/>

				<HStack spacing={{ base: '3', md: '6' }}>
					{/*
				<ColorModeSwitcher mb='0' ml='0' />
				<IconButton aria-label='open menu' icon={<FiBell />} size='md' variant='ghost' /> */}
					<Flex alignItems="center">
						<Menu>
							<MenuButton _focus={{ boxShadow: 'none' }} py={0} transition="all 0.3s">
								<HStack>
									<Avatar
										bg="#E6AF6E"
										color="gray.700"
										name={rest.initials?.charAt(0) + ' ' + rest.initials?.charAt(1)}
										pt={0.3}
										size="sm"
									/>
									<VStack
										alignItems="flex-start"
										display={{ base: 'none', md: 'flex' }}
										ml="2"
										spacing="1px"
									>
										{/* <Text fontSize='sm'>{rest.initials}</Text> */}
										<Text color="gray.600" fontSize="xs">
											{rest.role === Roles.clinician
												? 'Clinician'
												: rest.role === Roles.coordinator
												? 'Coordinator'
												: null}
										</Text>
									</VStack>
									<Box display={{ base: 'none', md: 'flex' }}>
										<FiChevronDown />
									</Box>
								</HStack>
							</MenuButton>
							<MenuList bg={bgColor} borderColor={bgBorder}>
								<MenuItem onClick={() => navigate(ROUTES.SETTINGS)}>Settings</MenuItem>
								<MenuDivider />

								<MenuItem onClick={logout}>Sign out</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</HStack>
			</Flex>
		);
	}
);

const TopMobileMenu = (props: MobileProps) => {
	const navigate = useNavigate();

	return <TopMobileMenuInner {...props} navigate={navigate} />;
};
export default TopMobileMenu;
