import * as Yup from 'yup';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import 'yup-phone-lite';

import { GetProfileResponse } from '@/api/users';

export const sections = {
	profile: [
		'accountType',
		'first_name',
		'last_name',
		'email',
		'phone',
		'dob',
		'notifications_sms',
		'notifications_email',
		'two_factor_enabled',
		'signature',
	] as (keyof FormValues)[],
	emergency: [
		'emerg_email',
		'emerg_first_name',
		'emerg_last_name',
		'emerg_local_hospital_name',
		'emerg_local_hospital_number',
		'emerg_phone',
	] as (keyof FormValues)[],
	address: [
		'address_city',
		'address_country',
		'address_postal',
		'address_province_state',
		'address_street',
	] as (keyof FormValues)[],
	timezone: ['timezone'] as (keyof FormValues)[],
	clientStatus: ['clientStatus'] as (keyof FormValues)[],
};

export const getInitialValues = (
	profile: GetProfileResponse | Partial<GetProfileResponse> = {}
) => {
	return {
		accountType: profile.accountType,
		first_name: profile.first_name || '',
		last_name: profile.last_name || '',
		phone: profile.phone || '',
		email: profile.email || '',
		address_street: profile.address_street || '',
		address_city: profile.address_city || '',
		address_province_state: profile.address_province_state || '',
		address_postal: profile.address_postal || '',
		address_country: profile.address_country || '',
		dob: profile.dob || '',
		emerg_first_name: profile.emerg_first_name || '',
		emerg_last_name: profile.emerg_last_name || '',
		emerg_phone: profile.emerg_phone || '',
		emerg_email: profile.emerg_email || '',
		emerg_local_hospital_name: profile.emerg_local_hospital_name || '',
		emerg_local_hospital_number: profile.emerg_local_hospital_number || '',
		// emailNotifications: false, // TODO: get from backend
		notifications_sms: profile.notifications_sms || false,
		notifications_email: profile.notifications_email || false,
		// color_mode: colorMode,
		timezone: profile.timezone || '',
		signature: profile.signature,
		verified_phone_number: profile.verified_phone_number || '',
		two_factor_enabled: profile.two_factor_enabled || false,
		clientStatus: profile.clientStatus,
	};
};

export type FormValues = ReturnType<typeof getInitialValues>;
export function getSectionIsValid(
	sectionFields: (keyof FormValues)[],
	formikProps: FormikProps<FormValues>
) {
	let canSaveSection = true;
	sectionFields.forEach((field) => {
		if (field in formikProps.errors) canSaveSection = false;
	});

	return canSaveSection;
}

type YupObjectShape = Partial<Record<keyof FormValues, Yup.AnySchema>>;

export const validationSchema = Yup.object<YupObjectShape>({
	phone: Yup.string().phone('CA', 'This is not a valid number.').nullable(),
	email: Yup.string().email('Must be a valid e-mail address.').nullable(),
	address_street: Yup.string().nullable(),
	first_name: Yup.string().required('First name is required.'),
	last_name: Yup.string().required('Last name is required.'),
	address_city: Yup.string().nullable(),
	address_province_state: Yup.string().nullable(),
	address_postal: Yup.string().nullable(),
	address_country: Yup.string().nullable(),
	dob: Yup.date()
		.transform(function (value, originalValue) {
			if (this.isType(value)) {
				return value;
			}

			return dayjs(originalValue, 'DD.MM.YYYY');
		})
		.typeError('Required'),
	emerg_first_name: Yup.string().nullable(),
	emerg_last_name: Yup.string().nullable(),
	emerg_phone: Yup.string().phone('CA', 'Phone number is not valid').nullable(),
	emerg_email: Yup.string().email('Must be a valid e-mail address.').nullable(),
	emerg_local_hospital_name: Yup.string().nullable(),
	emerg_local_hospital_number: Yup.string().phone('CA', 'Phone number is not valid').nullable(),
	notifications_sms: Yup.boolean().nullable(),
	notifications_email: Yup.boolean().nullable(),
	two_factor_enabled: Yup.boolean().nullable(),
});
