import { QueryFunction, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { compact, isString, omit } from 'lodash-es';

type CustomQueryKey = string | QueryKey;

const deprecatedOptions = ['onSuccess', 'onError', 'onSettled'];

// create a function useAppQuery that returns the result of calling useQuery
// and is backward compatible with react-query v3
export function useAppQuery<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
	Key = CustomQueryKey
>(
	queryKey: Key,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) {
	const arrayQueryKeys = compact(
		(isString(queryKey) ? [queryKey] : queryKey) as unknown as TQueryKey
	);

	const queryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>({
		notifyOnChangeProps: [
			'data',
			'isLoading',
			'error',
			'isFetching',
			'isRefetching',
			'isInitialLoading',
		],
		queryFn: async (...args: any[]) => {
			try {
				// @ts-ignore
				const result = await queryFn(...args);

				if (options?.onSuccess) {
					options.onSuccess(result as TData);
				}

				if (options?.onSettled) {
					options.onSettled(result as TData, null);
				}

				return result;
			} catch (err) {
				if (options?.onError) {
					options.onError(err as TError);
				}

				if (options?.onSettled) {
					options.onSettled(null as TData, err as TError);
				}

				throw err;
			}
		},
		queryKey: arrayQueryKeys as unknown as TQueryKey,
		...omit(options, deprecatedOptions),
	});

	return {
		...queryResult,
		// disabled queries (even temporarily disabled ones) will start in `loading` state.
		// To make migration easier, especially for having a good flag to know when to display a loading spinner,
		// you can check for isInitialLoading instead of isLoading:
		isLoading: options?.enabled === false ? queryResult.isInitialLoading : queryResult.isLoading,
	} as typeof queryResult;
}
