import { Suspense, forwardRef } from 'react';

import { Textarea } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

import type { EditorProps } from './EditorUI';

export type { EditorProps } from './EditorUI';
const EditorUI = lazy(() => import('./EditorUI'));

export const Editor = forwardRef<HTMLDivElement, EditorProps>((props, ref) => (
	<Suspense fallback={<Textarea />}>
		<EditorUI {...props} ref={ref} />
	</Suspense>
));
