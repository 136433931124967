import { useLayoutEffect, useRef } from 'react';

import { Textarea as ChakraTextarea, TextareaProps, useMergeRefs } from '@chakra-ui/react';

import { useNellieController } from '../hooks/use-nellie-controller';
import { useIsDisabled } from '../hooks/useIsDisabled';
import { FormFieldProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export function Textarea<AcceptedNames extends string>({
	name,
	label,
	isRequired,
	isReadOnly,
	helperText,
	shouldUnregister,

	...inputProps
}: FormFieldProps<TextareaProps, AcceptedNames>) {
	const isDisabled = useIsDisabled();
	const { field, fieldState } = useNellieController({
		name,
		shouldUnregister,
	});

	const ref = useRef<HTMLTextAreaElement>(null);
	const mergedRefs = useMergeRefs(field.ref, ref);

	useLayoutEffect(() => {
		if (ref.current) {
			// make textarea show all content when previewing
			if (ref.current?.scrollHeight) ref.current.style.height = ref.current?.scrollHeight + 'px';
		}
	}, []);

	return (
		<FieldWrapper
			errorMessage={fieldState.error?.message}
			helperText={helperText}
			isReadOnly={isReadOnly}
			isRequired={isRequired}
			label={label}
		>
			<ChakraTextarea
				overflow={isDisabled ? 'hidden' : undefined}
				resize={isDisabled ? 'none' : undefined}
				{...inputProps}
				{...field}
				ref={mergedRefs}
				value={field.value || ''}
			/>
		</FieldWrapper>
	);
}
