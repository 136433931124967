import { filter } from 'lodash-es';

import auth from '@/utils/auth';

import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-video';
type MeetingRoomProps = {
	meeting_token: string;
	room_name: string;
};

export type GetMeetingRoomsRes = (MeetingRoomProps & {
	description?: string;
	is_owner: boolean;
	type: 'single' | 'group';
})[];

const getVideoCallRooms = async () => {
	return httpClient.post<GetMeetingRoomsRes>(`${BASE_URL}-rooms/getVideoCallRooms`, {
		client_id: auth.getUserInfo().id,
	});
};

const getSingleClientVideoRoom = ({ signal }: { signal?: AbortSignal }) =>
	httpClient.post<MeetingRoomProps>(
		`${BASE_URL}-rooms/getSingleClientVideoRoom`,
		{
			client_id: auth.getClientID(),
		},
		{ signal }
	);

const getWorkshopsVideoRooms = async () => {
	const allRooms = await getVideoCallRooms();
	return filter(allRooms, { type: 'group' });
};

export const videoRoomsApi = {
	getVideoCallRooms,
	getSingleClientVideoRoom,
	getWorkshopsVideoRooms,
};
