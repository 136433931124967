import * as yup from 'yup';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormikProps, FormikTouched } from 'formik';
import { get, includes, isEmpty, omit, pick, values } from 'lodash-es';

import { Api } from '@/api';
import { ClientResponsesScore } from '@/api/forms';

import auth from './auth';
import { DATE_FORMAT, Roles } from './constants';

export const getFullName = (obj = {} as { first_name?: string; last_name?: string }) => {
	return `${obj?.first_name || ''} ${obj?.last_name || ''}`.trim();
};

export const getNellieUserFullName = ({ nellie_user } = {} as { nellie_user: NellieUser }) => {
	return getFullName(nellie_user);
};

export const getNellieUserInitials = ({ nellie_user } = {} as { nellie_user: NellieUser }) => {
	return nellie_user?.first_name?.charAt(0) + ' ' + nellie_user?.last_name?.charAt(0);
};

export const formIsCbwById = (formId: number) => {
	// TODO: replace with network request check
	const CBW_FORM_ID = 8;
	return formId === CBW_FORM_ID;
};

export function openHandoutFormPreview(formId: ID) {
	return Api.forms.getFormById(formId).then(({ attributes }) => {
		window.open(
			(process.env.REACT_APP_API_URL as string) + attributes.form_data.formHeader.formURL
		);
	});
}

dayjs.extend(utc);
export const formatDate = (date: string | Date, format = DATE_FORMAT) => {
	const isInvalidDate = isNaN(new Date(date).getTime());
	if (!date || isInvalidDate) return '';

	// get timeZone from Date object
	const timeZone = new Date(date).toTimeString().split(' ')[2];
	return dayjs(date).utcOffset(timeZone).format(format);
};

export const openEmailClient = () => {
	window.location.href = 'mailto:info@nelliehealth.com';
};

/**
 * It takes an HTML element, converts it to a canvas, then converts the canvas to a PDF and downloads it.
 * @param element - The element you want to convert to PDF.
 * @returns A function that returns a promise.
 */
export async function generatePDF(element: Maybe<HTMLElement>, documentName = 'Consent Treatment') {
	if (!element) return;

	const [{ default: html2canvas }, { default: jsPDF }] = await Promise.all([
		import('html2canvas'),
		import('jspdf'),
	]);

	const canvas = await html2canvas(element);
	const imgData = canvas.toDataURL('image/png');

	/*
	Here are the numbers (paper width and height) that I found to work.
	It still creates a little overlap part between the pages, but good enough for me.
	if you can find an official number from jsPDF, use them.
	*/
	const imgWidth = 210;
	const pageHeight = 295;
	const imgHeight = (canvas.height * imgWidth) / canvas.width;
	let heightLeft = imgHeight;

	const doc = new jsPDF('p', 'mm');
	let position = 0;

	doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
	heightLeft -= pageHeight;

	while (heightLeft >= 0) {
		position = heightLeft - imgHeight;
		doc.addPage();
		doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
		heightLeft -= pageHeight;
	}
	doc.save(`${documentName}.pdf`);
}

export function getAllFieldsAreFilled<T extends Record<string, any>>(
	formValues: T,
	fields: (keyof T)[]
) {
	const sectionValues = values(pick(formValues, fields));

	if (isEmpty(sectionValues)) return false;

	return sectionValues.every((value) => {
		return value !== '' && value !== null;
	});
}

/**
 * 1. We take the current form values and omit the fields
 * 2. We reset the form with the new values and the current errors and touched
 * 3. We set the values of the form to the current values, so the current values are not resetted
 */
export function resetFormFields<T extends Record<string, any>>(
	formikProps: FormikProps<T>,
	fields: (keyof T)[],
	updatedValue: Partial<T>
) {
	const formCurrentValues = omit(formikProps.values, fields);
	formikProps.resetForm({
		values: {
			...formikProps.initialValues,
			...updatedValue,
		},

		errors: formikProps.errors,
		touched: omit(formikProps.touched, fields) as FormikTouched<T>,
	});

	setTimeout(() => {
		formikProps.setValues((v) => ({
			...v,
			...formCurrentValues,
		}));
	});
}

export const getMyTimezone = () => {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return timezone;
};

/**
 * It returns the form title for the clinician or the client, depending on the user's role
 * @returns The form title
 */
export const getFormTitleByRole = ({
	form_title,
	form_title_client,
}: Pick<NellieForm, 'form_title' | 'form_title_client'>) => {
	return auth.getUserRole() === Roles.clinician
		? form_title
		: form_title_client
		? form_title_client
		: form_title;
};

export const slugify = (str: string) => {
	return str.replace(/[^a-zA-Z0-9-]/g, '-').toLowerCase();
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getTitleFromFilename = (filename) => {
	return filename.split('.')[0];
};

export const PASSWORD_MIN_LENGTH = 8;
export const NEW_PASSWORD_HELPER_TEXT = `Use ${PASSWORD_MIN_LENGTH} or more characters with a mix of letters, numbers & symbols`;

export const schemaHelpers = {
	dateValidation: yup
		.date()
		.transform(function (value, originalValue) {
			if (this.isType(value)) {
				return value;
			}
			const result = dayjs(originalValue, 'DD.MM.YYYY');
			return result;
		})
		.typeError('Required'),

	stringArrayValidation: yup
		.array()
		// make sure that the value is an array always
		.test(function (value = []) {
			if (value.length === 0) {
				return this.createError({
					message: 'At least one option must be selected',
				});
			}

			return true;
		})
		.of(yup.string())
		.min(1, 'At least one option must be selected'),

	emailValidation: yup.string().email('Must be a valid e-mail address.'),

	passwordValidation: yup
		.string()
		.required('Password is required')
		.min(PASSWORD_MIN_LENGTH, `Must be at least ${PASSWORD_MIN_LENGTH} characters`)
		.test(function (value = '') {
			// Must contain a letter
			if (!/[a-zA-Z]/.test(value)) {
				return this.createError({
					message: 'Must contain a letter',
				});
			}

			// Must contain a number
			if (!/[0-9]/.test(value)) {
				return this.createError({
					message: 'Must contain a number',
				});
			}

			// Must contain a non-alphanumeric character
			if (!/\W/.test(value)) {
				return this.createError({
					message: 'Must contain a symbol (e.g., %, &, !)',
				});
			}

			// Must not contain username
			if (includes(value.toLowerCase(), this.parent?.email?.toLowerCase())) {
				return this.createError({
					message: 'Must not contain your email username',
				});
			}

			return true;
		}),

	passwordConfirmationValidation: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
};

export function submitWithMetaKeyAndEnter(
	handleSubmit: (() => Promise<void>) | (() => unknown)
): React.KeyboardEventHandler<HTMLElement> | undefined {
	return (e) => {
		// make command + enter submit form
		if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
			handleSubmit();
		}
	};
}

export const getFormResponseScoreSum = (score: ClientResponsesScore) => {
	return get(score, 'sum.value', score.sum) as number;
};

export function downloadFile(file: File) {
	const link = document.createElement('a');
	link.href = URL.createObjectURL(new Blob([file]));
	link.setAttribute('download', file.name);
	link.click();
	URL.revokeObjectURL(link.href);
}

export function isFile<T>(file: File | T): file is File {
	return file instanceof File;
}

export const htmlStringToText = (htmlString = '') => {
	return (
		htmlString
			// removes html
			.split(/<\/?[^>]+>|&nbsp;/g)
			.filter((str) => str.trim())
			.join(' ')
			// removes this characters
			// [, ], (, ), =, ., and ;
			.replace(/[[\]()=.;]*/g, '')
			.trim()
	);
};

class AESCryptoHandler {
	private SECRET_KEY = 'my-secret-key';
	private encryptionReplacement = '____';
	private encryptionReplaceableKey = '/';

	private cache = {};

	encrypt = (decryptedValue: string | number) => {
		if (!decryptedValue) return decryptedValue;

		if (this.cache[decryptedValue]) return this.cache[decryptedValue];

		const encryptedValue = AES.encrypt(decryptedValue + '', this.SECRET_KEY)
			.toString()
			.replaceAll(this.encryptionReplaceableKey, this.encryptionReplacement);

		this.cache[decryptedValue] = encryptedValue;
		this.cache[encryptedValue] = decryptedValue;

		return encryptedValue;
	};

	decrypt = (encryptedMessage?: string) => {
		if (!encryptedMessage) return;

		if (this.cache[encryptedMessage]) return this.cache[encryptedMessage];

		const decryptedBytes = AES.decrypt(
			encryptedMessage.replaceAll(this.encryptionReplacement, this.encryptionReplaceableKey),
			this.SECRET_KEY
		);

		return enc.stringify(decryptedBytes);
	};
}

export const { encrypt, decrypt } = new AESCryptoHandler();

export const getPhoneNumberIsVerified = (num1: string, num2: string) => {
	num1 = formatPhoneNumber(num1);
	num2 = formatPhoneNumber(num2);
	return Boolean(num1 && num2 && num1 === num2);
};

export const formatPhoneNumber = (number: any) => {
	if (!number) return '';

	try {
		number = number.replace(/\D/g, '');

		return `+${number.slice(0, 1)} (${number.slice(1, 4)})  ${number.slice(4, 7)}-${number.slice(
			7
		)}`;
	} catch {
		return '';
	}
};

export const generateBookingUrl = (
	clinician: { id: number; username: string },
	client: { email: string; first_name: string; id: number }
): string => {
	const urlFields = `/${clinician.username}/?email=${client.email}&name=${client.first_name}&fx=zo&metadata[clientId]=${client.id}&metadata[clinicianId]=${clinician.id}`;
	return `${process.env.REACT_APP_NELLIE_CAL_URL}${urlFields}`;
};

export async function exportCSV<T extends Record<string, any>>(data: T[], filename: string) {
	const convertToCSV = (data: T[]) => {
		const header = `${Object.keys(data[0]).join(',')}`;
		const rows = data.map((item) => {
			return Object.values(item)
				.map((value) => {
					if (typeof value === 'string' && value.includes(',')) {
						return `"${value}"`;
					}
					return value;
				})
				.join(',');
		});
		return `${header}\n${rows.join('\n')}`;
	};

	const { saveAs } = await import('file-saver').then((module) => module.default);
	const csv = convertToCSV(data);
	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	saveAs(blob, filename);
}
