import { FC } from 'react';

import { Tooltip, TooltipProps } from '@chakra-ui/react';

export type NavItemTooltipProps = Partial<TooltipProps>;

export const NavItemTooltip: FC<NavItemTooltipProps> = ({ children, ...props }) => {
	return (
		<Tooltip
			hasArrow
			borderRadius="md"
			lineHeight="1.5rem"
			offset={[0, 15]}
			paddingX={5}
			paddingY={2}
			placement="right"
			{...props}
		>
			{children}
		</Tooltip>
	);
};
