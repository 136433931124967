import {
	RadioGroup as ChakraRadioGroup,
	RadioGroupProps as ChakraRadioGroupProps,
	Radio,
	RadioProps,
	Stack,
	StackDirection,
} from '@chakra-ui/react';

import { ValueTransformer, useNellieController } from '../hooks/use-nellie-controller';
import { FormFieldProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export type RadioGroupProps = Omit<ChakraRadioGroupProps, 'children'> & {
	/**
	 *  render some field based on what option is selected
	 */
	additionalField?: (value: string, options: string[]) => React.ReactNode;
	direction?: StackDirection | undefined;
	itemProps?: RadioProps;
	options: string[];
	transformer?: ValueTransformer;
};

export function RadioGroup<AcceptedNames extends string>({
	name,
	label,
	isDisabled,
	isRequired,
	isReadOnly,
	helperText,
	options,
	direction,
	gap,
	shouldUnregister,
	additionalField,
	transformer,
	itemProps,
	...props
}: FormFieldProps<RadioGroupProps, AcceptedNames>) {
	const {
		field: { ref, value = '', ...field },
		fieldState,
	} = useNellieController({
		name,
		transformer,
		shouldUnregister,
	});

	gap = direction ? undefined : ['0', '0', '5'];
	direction = direction ?? ['column', 'column', 'row'];

	return (
		<>
			<FieldWrapper
				errorMessage={fieldState.error?.message}
				helperText={helperText}
				isDisabled={isDisabled}
				isReadOnly={isReadOnly}
				isRequired={isRequired}
				label={label}
			>
				<ChakraRadioGroup
					as={Stack}
					colorScheme="purple"
					direction={direction}
					gap={gap}
					mt="3"
					{...field}
					value={value}
					{...props}
				>
					{options.map((option, index) => (
						<Radio
							key={option}
							ref={index === 0 ? ref : undefined}
							size="lg"
							value={option}
							width="fit-content"
							{...itemProps}
						>
							{option}
						</Radio>
					))}
				</ChakraRadioGroup>
			</FieldWrapper>
			{additionalField?.(value, options)}
		</>
	);
}
