import { appointmentsApi } from './appointments';
import { assignmentsApi } from './assignments';
import { authApi } from './auth';
import { formsApi } from './forms';
import { notesApi } from './notes';
import { stripeApi } from './stripe';
import { templatesApi } from './templates';
import { termsApi } from './terms';
import { usersApi } from './users';
import { videoRoomsApi } from './videoRooms';

export const Api = {
	notes: notesApi,
	forms: formsApi,
	users: usersApi,
	assignments: assignmentsApi,
	auth: authApi,
	templates: templatesApi,
	appointments: appointmentsApi,
	stripe: stripeApi,
	videoRooms: videoRoomsApi,
	terms: termsApi,
};
window['Api'] = Api;
