import React, { lazy, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { Center, Checkbox, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

import { Api } from '@/api';
import { useAppMutation } from '@/app-react-query';
import auth from '@/utils/auth';

import { ConfirmButton } from '../ui/Buttons/Buttons';

const Agreement = lazy(() => import('./components/Agreement'));

type Props = {
	children: (privacyTermsAcknowledged: boolean) => React.ReactNode;
};

export const AgreementModal = ({ children }: Props) => {
	const [isAgreed, toggleIsAgreed] = useToggle(false);

	const [privacyTermsAcknowledged, setPrivacyTermsAcknowledged] = useState(true);
	const { mutate: updateTermsAgreement, isLoading } = useAppMutation(
		Api.terms.updateTermsAgreement,
		{
			awaitInvalidateQueries: true,
			invalidateQueries: [['Api.users.getMyInfo']],
			onSettled: (_, error) => {
				console.error(error);
				setPrivacyTermsAcknowledged(true);
				auth.setUserInfo({ ...auth.getUserInfo(), privacy_terms_acknowledged: true });
			},
		}
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (auth.getToken() && !auth.getUserInfo()?.privacy_terms_acknowledged) {
			setPrivacyTermsAcknowledged(false);
		}
	});

	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={!privacyTermsAcknowledged}
				size={{ base: 'full', lg: '4xl', xl: '4xl' }}
				onClose={console.log}
			>
				<ModalOverlay style={{ backdropFilter: 'blur(10px)' }} />
				<ModalContent padding={10} paddingTop={0}>
					<Agreement
						footer={
							<>
								<Center>
									<Checkbox
										borderColor="black"
										checked={isAgreed}
										marginBottom={10}
										marginTop={20}
										size="lg"
										onChange={toggleIsAgreed}
									>
										<Text>
											I understand and agree to Nellie Health’s{' '}
											<Text
												_hover={{
													color: '#2F2E8A',
												}}
												as="a"
												href="#TermsOfUse"
												textDecoration="underline"
											>
												Terms of Service
											</Text>{' '}
											and{' '}
											<Text
												_hover={{
													color: '#2F2E8A',
												}}
												as="a"
												href="#PrivacyPolicy"
												textDecoration="underline"
											>
												Privacy Policy
											</Text>
										</Text>
									</Checkbox>
								</Center>
								<Center>
									<ConfirmButton
										isDisabled={!isAgreed}
										isLoading={isLoading}
										onClick={isAgreed ? () => updateTermsAgreement() : undefined}
									>
										Confirm
									</ConfirmButton>
								</Center>
							</>
						}
					/>
				</ModalContent>
			</Modal>
			{children(privacyTermsAcknowledged)}
		</>
	);
};
