import { ReactNode, Suspense, createContext, lazy, memo, useContext, useState } from 'react';

import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';

import { LoadingContainer } from '../ui';

const Agreement = lazy(() => import('./components/Agreement'));

type AgreementModalProviderProps = {
	openModal: VoidFunction;
	openPrivacyPolicyModal: VoidFunction;
	openTermsModal: VoidFunction;
};

const AgreementModalContext = createContext<AgreementModalProviderProps>({
	openModal: () => {
		throw new Error('openModal is not implemented');
	},
	openTermsModal: () => {
		throw new Error('openTermsModal is not implemented');
	},
	openPrivacyPolicyModal: () => {
		throw new Error('openPrivacyPolicyModal is not implemented');
	},
});

export const useAgreementModalControls = () => useContext(AgreementModalContext);

export const AgreementModalProvider = memo(({ children }: { children: ReactNode }) => {
	const [state, setState] = useState({
		hidePrivacyPolicy: false,
		hideTermsOfUse: false,
	});
	const privacyTermsModal = useDisclosure();

	return (
		<>
			<AgreementModalContext.Provider
				value={{
					openModal: () => {
						privacyTermsModal.onOpen();
						setState({
							hidePrivacyPolicy: false,
							hideTermsOfUse: false,
						});
					},

					openTermsModal: () => {
						privacyTermsModal.onOpen();
						setState({
							hidePrivacyPolicy: true,
							hideTermsOfUse: false,
						});
					},

					openPrivacyPolicyModal: () => {
						privacyTermsModal.onOpen();
						setState({
							hidePrivacyPolicy: false,
							hideTermsOfUse: true,
						});
					},
				}}
			>
				{children}
			</AgreementModalContext.Provider>
			<Modal
				isOpen={privacyTermsModal.isOpen}
				size={{ base: 'full', lg: '4xl', xl: '4xl' }}
				onClose={privacyTermsModal.onClose}
			>
				<ModalOverlay />
				<ModalContent padding={10} paddingTop={0}>
					<Suspense fallback={<LoadingContainer isLoading />}>
						<Agreement {...state} />
					</Suspense>
				</ModalContent>
			</Modal>
		</>
	);
});
