import React, { ReactNode, memo, useCallback, useContext, useMemo, useRef } from 'react';
import { FormProvider as ReactHookFormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BoxProps, Heading, VStack, useColorModeValue } from '@chakra-ui/react';
import { DevTool } from '@hookform/devtools';
import { cloneDeep, isEqual } from 'lodash-es';

import { ROUTES } from '@/routes';

import { UseNellieFormReturn } from '../hooks/use-nellie-form';
import { useIsDisabled } from '../hooks/useIsDisabled';

type FormProps = Omit<BoxProps, 'onBlur'> & {
	children: React.ReactNode;
	form: UseNellieFormReturn;
	/**
	 * the better option is to pass onSubmit
	 * from context this will mostly be used for page changes
	 */
	onSubmit?: (data: any) => void | Promise<FormIsCompleted>;
};

export type FormIsCompleted = boolean | undefined;

type FormContextProps = {
	saveOnBlur?: (values: any) => Promise<unknown>;
	saveOnSubmit?: ((values: any) => Promise<FormIsCompleted>) | undefined;
};
export const formContext = React.createContext<FormContextProps>({} as FormContextProps);

export const FormContextProvider = memo<FormContextProps & { children: ReactNode }>(
	({ children, ...rest }) => {
		const value = useMemo(() => rest, [rest]);

		return <formContext.Provider value={value}>{children}</formContext.Provider>;
	}
);

export const Form = memo(({ form, children, title, onSubmit, ...props }: FormProps) => {
	const bg = useColorModeValue('white', '#2d3748');

	const { isDisabled } = useIsDisabled();
	const { saveOnBlur, saveOnSubmit } = useContext(formContext);
	const navigate = useNavigate();
	const { getValues, handleSubmit } = form;
	const prevValues = useRef(cloneDeep(getValues()));

	const handleOnBlur = useCallback(async () => {
		const currentValues = getValues();

		if (!isEqual(currentValues, prevValues.current)) {
			prevValues.current = cloneDeep(currentValues);
			await saveOnBlur?.(currentValues);
		}
	}, [getValues, saveOnBlur]);

	const handleOnSubmit = useMemo(() => {
		const submitFn = onSubmit || saveOnSubmit;

		return submitFn
			? handleSubmit(
					async (args) => {
						const formIsCompleted = await submitFn(args);

						if (formIsCompleted) navigate(ROUTES.FORM_COMPLETE, { replace: true });
					},
					(err) => console.error(err)
			  )
			: undefined;
	}, [onSubmit, saveOnSubmit, handleSubmit, navigate]);

	return (
		<ReactHookFormProvider {...form}>
			<VStack
				as="form"
				bg={bg}
				gap={4}
				maxW="4xl"
				pointerEvents={isDisabled ? 'none' : undefined}
				px={{ base: '20px', md: '40px' }}
				py="40px"
				onBlur={handleOnBlur}
				onReset={form.reset}
				onSubmit={handleOnSubmit}
				{...props}
			>
				{title && (
					<Heading as="h3" textAlign="center">
						{title}
					</Heading>
				)}
				{children}
			</VStack>
			{process.env.NODE_ENV === 'development' && <DevTool control={form.control} />}
		</ReactHookFormProvider>
	);
});
