import { useDisclosure } from '@chakra-ui/react';

import { useGetClients } from '@/hooks';
import { getNellieUserFullName } from '@/utils';

import { Select, SelectProps } from '../ui';

export const ClientsSelect = (props: SelectProps<Client>) => {
	const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen: false });
	const { isLoading, data, error } = useGetClients({
		enabled: isOpen,
	});

	return (
		<Select<Client>
			closeMenuOnSelect
			isClearable
			getOptionLabel={getNellieUserFullName}
			getOptionValue={(option) => option.id.toString()}
			isLoading={isLoading}
			placeholder="Select or Search Clients..."
			selectedOptionColor="purple"
			size={{ base: 'sm', md: 'lg' }}
			onMenuClose={onClose}
			onMenuOpen={onOpen}
			{...props}
			options={(error ? [] : data) as Client[]}
		/>
	);
};
