import { Suspense } from 'react';

import { lazy } from '@/utils/react-wrappers';

import type { DropzoneProps } from './DropzoneUI';

export type { DropzoneProps } from './DropzoneUI';
const DropzoneUI = lazy(() => import('./DropzoneUI'));

export const Dropzone = (props: DropzoneProps) => (
	<Suspense>
		<DropzoneUI {...props} />
	</Suspense>
);
