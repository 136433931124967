import { memo, useContext } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { BsDot } from 'react-icons/bs';
import { FiChevronLeft, FiChevronRight, FiChevronUp, FiHelpCircle, FiInfo } from 'react-icons/fi';
import { NavLink, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import {
	Avatar,
	Box,
	BoxProps,
	Button,
	Circle,
	Divider,
	Flex,
	HStack,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Spacer,
	Square,
	Stack,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';

import shortLogo from '@/assets/NellieLogoBG.png';
import fullLogo from '@/assets/NellieLogoH.svg';
import { HelpAndFeedbackModal, useAgreementModalControls } from '@/components';
import { GlobalContext } from '@/context';
import { ROUTES } from '@/routes';
import { Roles, VideoCallStatus } from '@/utils';

import { useSidebarColors } from '../hooks';
import { SCROLLABLE_CLASSNAME, SIDEBAR_WIDTH } from '../utils/constants';

import { AuthenticatedNavItems } from './AuthenticatedNavItems';
import { ClientNavItems } from './ClientNavItems';
import { ClinicianNavItems } from './ClinicianNavItems';
import { CoordinatorNavItems } from './CoordinatorNavItems';
import { NavItemTooltip } from './NavItemTooltip';

export interface SidebarContentProps extends BoxProps {
	initials?: string;
	isCollapsed?: boolean;
	isOpen: boolean;
	logout: () => void;
	onClose: () => void;
	role: Roles;
	toggleIsCollapsed?: VoidFunction;
}

export const SidebarContent = (props: SidebarContentProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<SidebarContentInner locationPathname={location.pathname} navigate={navigate} {...props} />
	);
};

const SidebarContentInner = memo<
	SidebarContentProps & { locationPathname: string; navigate: NavigateFunction }
>(
	({
		onClose,
		isOpen,
		initials = '',
		role,
		logout,
		isCollapsed,
		toggleIsCollapsed,
		locationPathname,
		navigate,
		...rest
	}) => {
		const helpAndFeedbackModal = useDisclosure();
		const { openPrivacyPolicyModal, openTermsModal } = useAgreementModalControls();

		const { bgColor, color, _linkHover } = useSidebarColors();
		// const bgRightColor = mode('gray.200', 'gray.700');

		const { videoCallStatus } = useContext(GlobalContext);

		const logoElement = (
			<Box mb={12} px={4}>
				<NavLink
					to={videoCallStatus === VideoCallStatus.inMeeting ? locationPathname : ROUTES.ROOT}
				>
					{isCollapsed ? (
						<Image alt="Nellie Health" maxW="42px" mt={3} src={shortLogo} />
					) : (
						<Image alt="Nellie Health" maxW="150px" mt={3} mx="auto" src={fullLogo} />
					)}
				</NavLink>
			</Box>
		);

		const linksElement = (
			<>
				{role === Roles.authenticated && (
					<AuthenticatedNavItems isCollapsed={isCollapsed} onClose={onClose} />
				)}
				{role === Roles.client && (
					<ClientNavItems isCollapsed={isCollapsed} isMobileView={isOpen} onClose={onClose} />
				)}
				{role === Roles.clinician && (
					<ClinicianNavItems isCollapsed={isCollapsed} onClose={onClose} />
				)}
				{role === Roles.coordinator && <CoordinatorNavItems isCollapsed={isCollapsed} />}
			</>
		);

		const collapseIconElement = (
			<Circle
				_hover={_linkHover}
				bg={bgColor}
				borderWidth={1}
				color={color}
				cursor="pointer"
				data-cy="sidebar-collapse"
				position="absolute"
				right="0px"
				size={30}
				top="10px"
				transform="translateX(50%)"
				onClick={toggleIsCollapsed}
			>
				{isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
			</Circle>
		);

		const avatarElement = (
			<Menu>
				<MenuButton
					data-cy="my-info-menu-button"
					display={{ base: 'none', md: 'flex' }}
					transition="all 0.3s"
					{...(!isCollapsed && { width: 'full' })}
					_expanded={_linkHover}
					_focus={{ boxShadow: 'none' }}
					_hover={_linkHover}
					px={isCollapsed ? 2 : 3}
					py={isCollapsed ? 2 : 3}
					rounded="lg"
				>
					<HStack justifyContent="space-between" rounded="lg" transition="all 0.3s">
						<Avatar
							bg="#E6AF6E"
							color="gray.700"
							name={initials.charAt(0) + ' ' + initials.charAt(1)}
							pt={0.3}
							size="sm"
						/>

						{!isCollapsed && (
							<>
								<HStack alignItems="flex-start" display={{ base: 'none', md: 'flex' }}>
									{/* <Text fontSize='sm'>{rest.initials}</Text> */}
									<Text color="gray.600" fontSize="xs">
										{role === Roles.clinician
											? 'Clinician'
											: role === Roles.coordinator
											? 'Coordinator'
											: role === Roles.client
											? 'Client'
											: null}
									</Text>
								</HStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronUp />
								</Box>
							</>
						)}
					</HStack>
				</MenuButton>
				<MenuList bg={bgColor}>
					<MenuItem onClick={() => navigate(ROUTES.SETTINGS)}>Settings</MenuItem>
					<MenuDivider />
					{isCollapsed && (
						<>
							<MenuItem onClick={openPrivacyPolicyModal}>Privacy Policy</MenuItem>
							<MenuDivider />
							<MenuItem onClick={openTermsModal}>Terms of Use</MenuItem>
							<MenuDivider />
						</>
					)}

					<MenuItem data-cy="logout-button" onClick={logout}>
						Sign out
					</MenuItem>
				</MenuList>
			</Menu>
		);

		const commonLinkItems = (
			<HStack alignItems="center" pb={1} width="100%">
				<VStack alignItems="center" width="100%">
					{avatarElement}
					{isCollapsed ? (
						<>
							<NavItemTooltip label={<Text as="span">Help & Feedback</Text>}>
								<Square
									_hover={_linkHover}
									color={color}
									cursor="pointer"
									rounded="lg"
									size={45}
									onClick={helpAndFeedbackModal.onOpen}
								>
									<Icon as={FiHelpCircle} boxSize={5} m={0} p={0} />
								</Square>
							</NavItemTooltip>
							<NavItemTooltip label={<Text as="span">Report a bug or issue</Text>}>
								<Square
									_hover={_linkHover}
									color={color}
									cursor="pointer"
									rounded="lg"
									size={45}
									onClick={() => {
										throw new Error('Bug/Issue Submission Feedback');
									}}
								>
									<Icon as={AiOutlineWarning} boxSize={5} m={0} p={0} />
								</Square>
							</NavItemTooltip>
							<NavItemTooltip
								label={<Text as="span">Version {process.env.REACT_APP_VERSION}</Text>}
							>
								<Square _hover={_linkHover} color={color} cursor="pointer" rounded="lg" size={45}>
									<Icon as={FiInfo} boxSize={5} m={0} p={0} />
								</Square>
							</NavItemTooltip>
						</>
					) : (
						<>
							<Divider borderColor="gray.300" display={{ base: 'none', md: 'block' }} />
							<Button
								colorScheme="green"
								size="sm"
								variant="outline"
								width="100%"
								onClick={helpAndFeedbackModal.onOpen}
							>
								Help & Feedback
							</Button>
							<Button
								size="sm"
								width="100%"
								onClick={() => {
									throw new Error('Bug/Issue Submission Feedback');
								}}
							>
								Report a bug or issue
							</Button>

							<HStack spacing={0}>
								<Link fontSize="xs" fontWeight="light" textAlign="center" onClick={openTermsModal}>
									Terms of Use
								</Link>
								<Icon as={BsDot} mx={0} />
								<Link fontSize="xs" fontWeight="light" onClick={openPrivacyPolicyModal}>
									Privacy Policy
								</Link>
							</HStack>
							<Text fontSize="xs" fontWeight="light">
								Copyright &copy; {new Date().getFullYear()} Nellie Health
							</Text>
							<Text fontSize="0.6em" fontWeight="light">
								Version {process.env.REACT_APP_VERSION}
							</Text>
						</>
					)}
				</VStack>
			</HStack>
		);

		return (
			<>
				{helpAndFeedbackModal.isOpen && (
					<HelpAndFeedbackModal onClose={helpAndFeedbackModal.onClose} />
				)}
				<Flex
					bg={bgColor}
					borderRightWidth="1px"
					bottom={0}
					data-cy="sidebar"
					direction="column"
					display={isOpen ? { base: 'flex', md: 'none' } : { base: 'none', md: 'flex' }}
					pos="fixed"
					py={4}
					top={0}
					width={
						isCollapsed ? SIDEBAR_WIDTH.collapsed : { base: 'full', md: SIDEBAR_WIDTH.expanded }
					}
					zIndex={999}
					{...rest}
				>
					{!isOpen && collapseIconElement}
					{logoElement}
					<Stack
						className={SCROLLABLE_CLASSNAME}
						flex={1}
						overflowY="auto"
						paddingLeft={4}
						paddingRight={4}
					>
						<Stack flex={1}>
							{linksElement}
							<Spacer />
							{commonLinkItems}
						</Stack>
					</Stack>
				</Flex>
			</>
		);
	}
);
