import { memo, useCallback, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { MdAttachFile, MdOutlineAddCircle, MdOutlineLibraryAdd } from 'react-icons/md';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormErrorMessage,
	HStack,
	IconButton,
	Input,
	Text,
	Tooltip,
	VStack,
} from '@chakra-ui/react';
import { useIsMutating } from '@tanstack/react-query';
import { concat, groupBy, isEmpty, pick, trim } from 'lodash-es';

import { CreateSectionParams, UpdateNoteSectionParams } from '@/api/notes';
import { Dropzone, Editor, FileListItem, OutlineButton } from '@/components/ui';
import { useIsLightMode } from '@/hooks';
import { getFullName, isFile } from '@/utils';

import { useNoteApi } from './hooks';

type NoteItemSectionsProps = {
	addSection: any;
	canAddAttachment?: boolean;
	children?: JSX.Element;
	isReadOnly?: boolean;
	moveSectionDown?: VoidFunction;
	moveSectionUp?: VoidFunction;
	namePrefix: `sections.${number}`;
	removeSection?: (sectionIsEmpty) => Promise<void>;
};
export const NoteItemSection = memo(
	({
		namePrefix,
		isReadOnly,
		removeSection,
		moveSectionDown,
		moveSectionUp,
		addSection,
		canAddAttachment = true,
	}: NoteItemSectionsProps) => {
		const isLightMode = useIsLightMode();
		const { watch, getValues, setValue } = useFormContext<Note>();

		const { downloadNoteAttachment, createNoteSection, updateNote, uploadNoteSectionAttachments } =
			useNoteApi();

		const isCreating = Boolean(useIsMutating(['createNoteSection']));
		const isUploadingAttachments = Boolean(useIsMutating(['uploadNoteSectionAttachments']));

		/** this is a flag which should prevent creating the same section twice */
		const shouldTriggerUpdateOnSuccess = useRef(false);

		const noteId = getValues('id');
		const section = watch(namePrefix);

		const updateSection = useCallback(
			async (section: UpdateNoteSectionParams) => {
				return updateNote({ id: getValues('id'), sections: [section as NoteSection] });
			},
			[updateNote, getValues]
		);

		const createSection = useCallback(
			async (params: CreateSectionParams) => {
				if (isCreating) return (shouldTriggerUpdateOnSuccess.current = true);

				try {
					const newNoteSection = await createNoteSection({
						...params,
						order: getValues(`${namePrefix}.order`),
					});

					// @ts-ignore
					setValue(`${namePrefix}.id`, newNoteSection.id);

					if (shouldTriggerUpdateOnSuccess.current) {
						await updateSection({
							id: newNoteSection.id,
							...newNoteSection,
						});
						shouldTriggerUpdateOnSuccess.current = false;
					}

					return newNoteSection;
				} catch (error) {
					//
				}
			},
			[createNoteSection, getValues, isCreating, namePrefix, setValue, updateSection]
		);

		const sectionAuthorIsNotNoteAuthor =
			section?.author && +getValues('author') !== +section?.author.id;

		return (
			<Box background="gray200" borderRadius="md" position="relative" w="100%">
				<HStack alignItems="start" spacing={0}>
					<Box flex={1} px={{ base: '2', md: '7' }} py={{ base: '2.5', md: '5' }}>
						<Controller
							name={`${namePrefix}.title`}
							render={({ field, fieldState }) => (
								<FormControl isInvalid={Boolean(fieldState.error?.message)}>
									<Input
										border="none"
										fontSize="2xl"
										fontWeight={700}
										isReadOnly={isReadOnly}
										placeholder="Title"
										px={{
											base: '3',
										}}
										{...field}
										value={field.value || ''}
										onBlur={
											isReadOnly
												? undefined
												: (e) => {
														field.onBlur();
														if (noteId) {
															const title = trim(e.target.value);
															if (section?.id) updateSection({ id: section.id, title });
															else if (title)
																createSection({
																	note: noteId,
																	title,
																});
														}
												  }
										}
									/>
									<FormErrorMessage fontWeight="semibold">
										Section title is required
									</FormErrorMessage>
								</FormControl>
							)}
						/>

						<Controller
							name={`${namePrefix}.description`}
							render={({ field }) => {
								if (isReadOnly && !field.value) return <></>;

								return (
									<Box minHeight="150px" overflow="scroll" w="100%">
										<Editor
											{...field}
											isReadOnly={isReadOnly}
											placeholder="Description"
											value={field.value || ''}
											onBlur={
												isReadOnly
													? undefined
													: (value) => {
															field.onBlur();
															if (noteId) {
																const description = trim(value);
																if (section?.id) updateSection({ id: section.id, description });
																else if (description)
																	createSection({
																		note: noteId,
																		description,
																	});
															}
													  }
											}
										/>
									</Box>
								);
							}}
						/>

						{sectionAuthorIsNotNoteAuthor && (
							<Text color="gray.400" fontSize="sm" textAlign="end" w="100%">
								<b>Author:</b> {getFullName(section.author)}
							</Text>
						)}

						<Box w="100%">
							<Controller
								name={`${namePrefix}.attachments`}
								render={({ field }) => {
									if (isEmpty(field.value)) return <></>;

									const { files = [], attachments = [] } = groupBy(
										field.value,
										(fileOrAttachment) => (isFile(fileOrAttachment) ? 'files' : 'attachments')
									) as {
										attachments: Attachment[];
										files: File[];
									};

									return (
										<VStack spacing="10px">
											{!isEmpty(attachments) && (
												<VStack
													background={isLightMode ? 'whiteAlpha.900' : 'whiteAlpha.100'}
													borderRadius="md"
													px="20px"
													py="10px"
													w="100%"
												>
													<HStack alignItems="center" justifyContent="space-between" w="100%">
														<Text alignSelf="start" fontSize="xl" fontWeight="semibold">
															Attachments
														</Text>
													</HStack>
													{attachments.map((attachment) => (
														<FileListItem
															key={attachment.id}
															borderBottomStyle="dashed"
															date={attachment.createdAt}
															filename={attachment.label}
															onClick={() => downloadNoteAttachment(attachment)}
														/>
													))}
												</VStack>
											)}
											{!isEmpty(files) && (
												<Dropzone
													uploadButton={
														Boolean(noteId) && (
															<OutlineButton
																isDisabled={isUploadingAttachments}
																isLoading={isUploadingAttachments}
																w="100%"
																onClick={async () => {
																	const uploadedAttachments = await uploadNoteSectionAttachments({
																		id: section.id,
																		attachments: section.attachments,
																	});
																	field.onChange([...uploadedAttachments, ...attachments]);
																}}
															>
																UPLOAD
															</OutlineButton>
														)
													}
													value={files}
													onChange={(files) =>
														field.onChange(
															isEmpty(field.value) ? attachments : [...files, ...attachments]
														)
													}
												/>
											)}
										</VStack>
									);
								}}
							/>
						</Box>
					</Box>

					{!isReadOnly && (
						<VStack
							alignSelf="stretch"
							background={isLightMode ? 'gray.300' : 'gray.600'}
							borderLeft={isLightMode ? undefined : '1px solid'}
							borderLeftColor={isLightMode ? undefined : 'whiteAlpha.300'}
							borderRightRadius="md"
							color={isLightMode ? 'gray.600' : 'gray.200'}
							p={{ base: '1', md: '2' }}
						>
							{moveSectionUp && (
								<Tooltip label={<Text p="1">Move section up</Text>} placement="left">
									<IconButton
										aria-label=""
										bg="inherit"
										fontSize="3xl"
										icon={<ChevronUpIcon />}
										onClick={moveSectionUp}
									/>
								</Tooltip>
							)}
							<Tooltip label={<Text p="1">Add a new text block below</Text>} placement="left">
								<IconButton
									aria-label=""
									bg="inherit"
									fontSize="2xl"
									icon={<MdOutlineAddCircle />}
									onClick={() => {
										addSection();
									}}
								/>
							</Tooltip>
							<Tooltip label={<Text p="1">Duplicate the text block</Text>} placement="left">
								<IconButton
									aria-label=""
									bg="inherit"
									fontSize="2xl"
									icon={<MdOutlineLibraryAdd />}
									onClick={() => {
										addSection(pick(section, 'title', 'description'));
									}}
								/>
							</Tooltip>

							{canAddAttachment && (
								<Tooltip label={<Text p="1">Add an attachment</Text>} placement="left">
									<Box>
										<IconButton
											aria-label=""
											bg="inherit"
											fontSize="2xl"
											icon={<MdAttachFile />}
											onClick={(e) => {
												(e.currentTarget.nextSibling as HTMLInputElement).click();
											}}
										/>
										<Input
											multiple
											display="none"
											type="file"
											onChange={async (e) => {
												const files = e.target.files;
												if (!files) return;
												setValue(
													`${namePrefix}.attachments`,
													concat(section.attachments || [], Array.from(files))
												);
												// reset value here because if you remove files from the dropzone
												// and then try to upload previously selected file the input will
												// not trigger onChange because the value is the same
												e.target.value = '';
											}}
										/>
									</Box>
								</Tooltip>
							)}
							{removeSection && (
								<Tooltip label={<Text p="1">Remove section</Text>} placement="left">
									<IconButton
										aria-label=""
										bg="inherit"
										fontSize="2xl"
										icon={<BsTrash />}
										onClick={() => {
											// if the section is empty, just remove it
											const sectionIsEmpty =
												!trim(section.description) &&
												!trim(section.title) &&
												isEmpty(section.attachments);

											return removeSection(sectionIsEmpty);
										}}
									/>
								</Tooltip>
							)}
							{moveSectionDown && (
								<Tooltip label={<Text p="1">Move section down</Text>} placement="left">
									<IconButton
										aria-label=""
										bg="inherit"
										fontSize="3xl"
										icon={<ChevronDownIcon />}
										onClick={moveSectionDown}
									/>
								</Tooltip>
							)}
						</VStack>
					)}
				</HStack>
			</Box>
		);
	}
);
