import { useMemo } from 'react';

import { Button, ButtonProps, Skeleton } from '@chakra-ui/react';

type AppButtonProps = ButtonProps & {
	showSkeleton?: boolean;
};

export const AppButton = ({ showSkeleton, children, ...props }: AppButtonProps) => {
	const showSkeletonProps: ButtonProps = useMemo(
		() =>
			showSkeleton
				? {
						isDisabled: true,
						onClick: undefined,
						onTouchEnd: undefined,
				  }
				: {},
		[showSkeleton]
	);

	return (
		<Button {...props} {...showSkeletonProps}>
			<Skeleton isLoaded={!showSkeleton}>{children}</Skeleton>
		</Button>
	);
};

export const OutlineButton = (props: AppButtonProps) => (
	<AppButton colorScheme="purple" variant="outline" {...props} />
);

export const ConfirmButton = (props: AppButtonProps) => (
	<AppButton colorScheme="purple" {...props} />
);

export const PayButton = (props: AppButtonProps) => (
	<AppButton colorScheme="green" variant="solid" {...props} />
);

export const InfoButton = (props: AppButtonProps) => (
	<AppButton colorScheme="blue" variant="outline" {...props} />
);

export const DangerButton = (props: AppButtonProps) => (
	<AppButton colorScheme="red" variant="outline" {...props} />
);
