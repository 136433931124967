import useDebounce from 'react-use/lib/useDebounce';

export { default as useLatest } from 'react-use/lib/useLatest';

export const useDebounceEffect = useDebounce;

export * from './queries';
export * from './useCustomToast';
export * from './useInterval';
export * from './useIsFirstRender';
export * from './useKeyPress';
export * from './useMountEffect';
export * from './usePagination';
export * from './useToggle';
export * from './useUpdateEffect';
export * from './useSearchParamsObject';
export * from './useFocusedClientId';
export * from './useDebounce';
export * from './useTableQuery';
export * from './useUnreadMessages';
export * from './useIsLightMode';
export * from './useSwipeToClose';
