import { memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
	IconButton,
	Menu,
	MenuButton,
	MenuButtonProps,
	MenuItem,
	MenuList,
	useDisclosure,
} from '@chakra-ui/react';
import { isEqual } from 'lodash-es';

import { openConfirmationModal } from '@/components/ui';
import { useErrorToast, useSuccessToast } from '@/hooks';
import { ROUTES } from '@/routes';
import { decrypt, encrypt } from '@/utils';

import { CreateTemplateModal } from '../CreateTemplateModal';

import { useNoteApi } from './hooks';

type NoteItemActionsMenuProps = {
	note: Note;
} & Omit<MenuButtonProps, 'id' | 'status'>;

export const NoteItemActionsMenu = (props: NoteItemActionsMenuProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { noteId: encryptedNoteId } = useParams<{ noteId: string }>();
	const selectedNoteId = decrypt(encryptedNoteId);

	const isAmendingCurrentNote = Boolean(
		props.note?.id &&
			selectedNoteId === props.note.id.toString() &&
			searchParams.get('amend') === '1' &&
			props.note.status === 'Published'
	);

	const isDeletingCurrentNote = selectedNoteId === props.note?.id?.toString();

	return (
		<NoteItemActionsMenuInner
			{...props}
			isAmendingCurrentNote={isAmendingCurrentNote}
			isDeletingCurrentNote={isDeletingCurrentNote}
			navigate={navigate}
		/>
	);
};

const NoteItemActionsMenuInner = memo(
	({
		note,
		navigate,
		isAmendingCurrentNote,
		isDeletingCurrentNote,
		...buttonProps
	}: NoteItemActionsMenuProps & {
		isAmendingCurrentNote: boolean;
		isDeletingCurrentNote: boolean;
		navigate: NavigateFunction;
	}) => {
		const menu = useDisclosure();
		const { archiveNote, duplicateNote, deleteNote, exportNotePdf } = useNoteApi();

		const onArchiveNote = useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				openConfirmationModal({
					title: 'Archive Note',
					body: 'Are you sure you want to archive this note?',
					initialFocusedButton: 'submit',
					onSubmit: () => archiveNote(note.id),
				});
			},
			[archiveNote, note]
		);

		const onDuplicateNote = useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				openConfirmationModal({
					title: 'Duplicate Note',
					body: 'Are you sure you want to duplicate this note?',
					initialFocusedButton: 'submit',
					onSubmit: () =>
						duplicateNote(note.id as unknown as number).then((createdNote) => {
							navigate(`${ROUTES.CLIENT_NOTES}/${encrypt(createdNote.id)}`);
						}),
				});
			},
			[duplicateNote, note, navigate]
		);

		const successToast = useSuccessToast();
		const errorToast = useErrorToast();
		const onExportNote = useCallback(
			async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				try {
					successToast({ title: 'Download started' });
					await exportNotePdf({ id: note.id, filename: note.title });
				} catch {
					errorToast({
						title: 'Something went wrong!',
						description: 'Pleas try again later',
					});
				}
			},
			[errorToast, exportNotePdf, note.id, note.title, successToast]
		);

		const saveTemplateModal = useDisclosure();
		const onSaveTemplate = useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();

				saveTemplateModal.onOpen();
			},
			[saveTemplateModal]
		);

		const onAmendNote = useCallback(
			(e) => {
				e.stopPropagation();
				isAmendingCurrentNote
					? navigate(`${ROUTES.CLIENT_NOTES}/${encrypt(note.id)}`)
					: navigate(`${ROUTES.CLIENT_NOTES}/${encrypt(note.id)}?amend=1`);
			},
			[isAmendingCurrentNote, navigate, note]
		);

		const onDeleteNote = useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				openConfirmationModal({
					title: 'Delete Note',
					body: 'Are you sure you want to delete this note?',
					initialFocusedButton: 'cancel',
					submitBtnColor: 'red',
					submitBtnText: 'DELETE',
					onSubmit: async () => {
						await deleteNote(note.id);
						if (isDeletingCurrentNote) {
							navigate(ROUTES.CLIENT_NOTES, { replace: true });
						}
					},
				});
			},
			[deleteNote, isDeletingCurrentNote, navigate, note.id]
		);

		const canDelete = note.status === 'Draft';
		const canArchive = note.status === 'Published';
		const canDuplicate = Boolean(note.type !== 'Upload' && note.id);
		const canSaveTemplate = note.type !== 'Upload';
		const canAmend = note.type !== 'Upload' && note.status === 'Published';
		const canExport = note.status !== 'Draft';

		if (!note.id || (note.type === 'Upload' && note.status === 'Archived')) return <></>;

		return (
			<>
				<Menu isLazy gutter={4} isOpen={menu.isOpen} onClose={menu.onClose}>
					<MenuButton
						_active={{ bg: 'none', color: 'gray.500' }}
						_hover={{ bg: 'none', color: 'gray.500' }}
						aria-label="Options"
						as={IconButton}
						border="none"
						color="gray.300"
						fontSize="20px"
						height="fit-content"
						icon={<BsThreeDotsVertical />}
						py={0}
						variant="outline"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							menu.onToggle();
						}}
						{...buttonProps}
					/>
					{createPortal(
						<MenuList zIndex={9999}>
							{canArchive && <MenuItem onClick={onArchiveNote}>Archive</MenuItem>}
							{canAmend && (
								<MenuItem onClick={onAmendNote}>
									{isAmendingCurrentNote ? 'Cancel Amendment' : 'Amend'}
								</MenuItem>
							)}
							{canDuplicate && <MenuItem onClick={onDuplicateNote}>Duplicate</MenuItem>}
							{canExport && <MenuItem onClick={onExportNote}>Export</MenuItem>}
							{canSaveTemplate && <MenuItem onClick={onSaveTemplate}>Save Template</MenuItem>}
							{canDelete && <MenuItem onClick={onDeleteNote}>Delete</MenuItem>}
						</MenuList>,
						document.body
					)}
				</Menu>
				{saveTemplateModal.isOpen && (
					<CreateTemplateModal
						noteId={note.id}
						selectedNoteType={note.type}
						onClose={saveTemplateModal.onClose}
					/>
				)}
			</>
		);
	},
	isEqual
);
