import { CSSObject, useColorModeValue as mode } from '@chakra-ui/react';

export const useSidebarColors = () => {
	const color = mode('blackAlpha.800', 'whiteAlpha.800');
	const bgColor = mode('white', '#1a202c');

	const _linkHover: CSSObject = {
		bg: mode('gray.100', 'teal.800'),
		color: mode('black', 'white'),
	};

	const _linkActive: CSSObject = {
		bg: mode('#3A38A6', 'teal.900'),
		color: mode('white', 'teal.200'),
	};

	return {
		color,
		bgColor,
		_linkHover,
		_linkActive,
	};
};
