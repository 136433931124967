import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { GetClientsResponse } from '@/api/users';
import { useAppQuery } from '@/app-react-query';
import { ONE_MINUTE_IN_MS } from '@/utils';

/**
 * `useGetForms` is a React hook that returns the result of calling `useQuery` with the
 * `Api.users.getClients` function
 */
export const useGetClients = <ActualResponse = GetClientsResponse, TError = AxiosError>(
	options?: Omit<
		UseQueryOptions<GetClientsResponse, TError, ActualResponse>,
		'queryKey' | 'queryFn'
	>
) => {
	return useAppQuery<GetClientsResponse, TError, ActualResponse, any>(
		useGetClients.getQueryKey(),
		Api.users.getClients,
		{
			refetchOnMount: true,
			staleTime: 0,
			cacheTime: 5 * ONE_MINUTE_IN_MS,
			...options,
		}
	);
};
useGetClients.getQueryKey = () => ['clients'];
