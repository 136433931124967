import { useContext } from 'react';

import { GlobalContext } from '@/context';

export const useFocusedClientId = () => {
	const { client } = useContext(GlobalContext);

	return +(client?.user_id as string);
};

export const useFocusedClient = () => useContext(GlobalContext).client as NellieUser;
