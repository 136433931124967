import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { FormikValues } from 'formik';
import { isEmpty, merge } from 'lodash-es';

import { Api } from '@/api';
import { useAppMutation, useAppQuery } from '@/app-react-query';
import { FormBuilderBox } from '@/components/FormBuilderBox/FormBuilderBox';
import { LoadingContainer } from '@/components/ui';
import { useErrorToast } from '@/hooks';
import { ROUTES } from '@/routes';

import { useFormProvider } from './useFormProvider';

export enum ProgressStatus {
	completed = 'completed',
	inProgress = 'in progress',
}

export type FormProviderProps = {
	client_id?: ID;
	form_id?: ID;
	isDisabled_?: boolean;
	response_id?: ID;
};

export default function FormProvider(props: FormProviderProps) {
	const errorToast = useErrorToast();
	const navigate = useNavigate();
	const location = useLocation();
	const form_id = (location.state as any)?.form_id;
	const assignment_id = (location.state as any)?.assignment_id;

	const response_id = React.useRef(
		Number(props.response_id ? props.response_id : (location.state as any)?.response_id)
	); // Resuming an unfinished form

	const { data: assignmentDefaultValues, isLoading: assignmentDefaultValuesIsLoading } =
		useAppQuery(
			['getAssignmentDefaultValues', assignment_id?.toString()],
			() => Api.assignments.getAssignmentDefaultValues(assignment_id),
			{
				enabled: Boolean(assignment_id),
			}
		);

	const { data, loading, error } = useFormProvider(form_id, {
		isDisabled_: props.isDisabled_,
		client_id: props.client_id,
		response_id: response_id.current,
	});

	const { formBuilder, formikBuilder, autoSave } = data || {};

	const { mutate: saveFormResponse, isLoading } = useAppMutation(async (values: any) => {
		if (!formBuilder || isLoading) return;

		const id = await Api.forms.saveFormResponse({
			assignment_id: assignment_id,
			form_id: formBuilder?.form_id,
			isScored: formBuilder?.form_data.formHeader.isScored,
			response_data: values,
			response_id: response_id.current,
			status: ProgressStatus.inProgress,
			type: formBuilder?.form_type,
		});

		// set response_id to the id returned from the server
		// this is used to resume the form
		// if the user leaves the page and comes back
		// the response_id is used to get the form data
		response_id.current = id;
	});

	return (
		<LoadingContainer error={error} isLoading={loading || assignmentDefaultValuesIsLoading}>
			{formBuilder && (
				<FormBuilderBox
					footer={({ errors, submitCount }) =>
						!isEmpty(errors) &&
						submitCount > 0 && (
							<Box mb="auto">
								<Alert mt="3" status="warning">
									<AlertIcon />
									Looks like you missed something. Please answer all required questions.
								</Alert>
							</Box>
						)
					}
					formBuilder={formBuilder}
					initialValues={merge(formikBuilder?.initialValues, assignmentDefaultValues)}
					saveOnBlur={autoSave ? (values) => saveFormResponse(values) : undefined}
					validationSchema={formikBuilder?.formSchema}
					onSubmit={async (values: FormikValues) => {
						try {
							await Api.forms.saveFormResponse({
								assignment_id: assignment_id,
								form_id: formBuilder?.form_id,
								isScored: formBuilder?.form_data.formHeader.isScored,
								response_data: values,
								response_id: response_id.current,
								status: ProgressStatus.completed,
								type: formBuilder?.form_type,
							});
						} catch (error) {
							errorToast({ title: 'Error saving form' });
							return;
						}

						navigate(ROUTES.FORM_COMPLETE, {
							state: { submission: 'complete' },
						});
					}}
				/>
			)}
		</LoadingContainer>
	);
}
