import { httpClient } from './http-client';

export type UpdateTemplateParams = Omit<Template, 'isPublic' | 'owner'>;

const BASE_URL = '/api/nellie-notes-templates/';

const getTemplates = (clinician: ID) => {
	return httpClient.post<Template[]>(`${BASE_URL}getTemplates`, { clinician });
};

const deleteTemplate = async (id: ID) => {
	const response = await httpClient.post(`${BASE_URL}deleteTemplate`, { id });

	return response.data;
};

type CreateTemplateParams = {
	clinician: ID;
	note: ID;
	title: string;
	type: NoteType | undefined | null;
};

const createTemplate = async (params: CreateTemplateParams) => {
	return httpClient.post<Template>(`${BASE_URL}createTemplate`, params);
};

const updateTemplate = async (params: {
	id: ID;
	sections: Template['sections'];
	title: string;
}) => {
	return httpClient.post<Template>(`${BASE_URL}updateTemplate`, params);
};

const getTemplate = async (id: ID) => {
	return httpClient.post<Template>(`${BASE_URL}getTemplate`, { id });
};
export const templatesApi = {
	getTemplates,
	deleteTemplate,
	updateTemplate,
	createTemplate,
	getTemplate,
};
