import { Suspense } from 'react';

import { Input } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

import type { DatePickerProps } from './DatePickerUI';

export type { DatePickerProps } from './DatePickerUI';
const DatePickerUI = lazy(() => import('./DatePickerUI'));

export const DatePicker = (props: DatePickerProps) => (
	<Suspense fallback={<Input />}>
		<DatePickerUI {...props} />
	</Suspense>
);
