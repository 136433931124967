// import axios from 'axios';
import { isEmpty } from 'lodash-es';

const TOKEN_KEY = 'jwtToken';
export const USER_INFO = 'userInfo';
const OWN_ID = 'ownID';
const USER_ROLE = '';
// const USER_EMAIL = '';
// const CLIENT_FIRST_NAME = '';
// const CLIENT_LAST_NAME = '';
const CLIENT_ID = 0;

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
	/**
	 * Remove an item from the used storage
	 * @param  {String} key [description]
	 */
	clear(key) {
		if (localStorage && localStorage.getItem(key)) {
			return localStorage.removeItem(key);
		}

		if (sessionStorage && sessionStorage.getItem(key)) {
			return sessionStorage.removeItem(key);
		}

		return null;
	},

	/**
	 * Clear all app storage
	 */
	clearAppStorage() {
		if (localStorage) {
			localStorage.clear();
		}

		if (sessionStorage) {
			sessionStorage.clear();
		}
	},

	clearToken(tokenKey = TOKEN_KEY) {
		return auth.clear(tokenKey);
	},

	clearUserInfo(userInfo = USER_INFO) {
		return auth.clear(userInfo);
	},

	clearClientID(clientID = CLIENT_ID) {
		return auth.clear(clientID);
	},

	get(key: string) {
		if (localStorage && localStorage.getItem(key)) {
			return parse(localStorage.getItem(key) as string) || null;
		}

		if (sessionStorage && sessionStorage.getItem(key)) {
			return parse(sessionStorage.getItem(key) as string) || null;
		}

		return null;
	},

	getToken(tokenKey = TOKEN_KEY) {
		return auth.get(tokenKey);
	},

	getUserInfo(UserInfo = USER_INFO) {
		return auth.get(UserInfo) ?? {};
	},

	getOwnID(ownID = OWN_ID) {
		return auth.get(ownID);
	},

	getUserRole(UserRole = USER_ROLE) {
		return auth.get(UserRole);
	},

	getClientID(clientID = CLIENT_ID) {
		return parseInt(auth.get(clientID.toString()));
	},

	/**
	 * Set data in storage
	 * @param {String|Object}  value    The data to store
	 * @param {String}  key
	 * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
	 */
	set(value: any, key: string, isLocalStorage?: boolean) {
		if (isEmpty(value)) {
			return null;
		}

		if (isLocalStorage && localStorage) {
			return localStorage.setItem(key, stringify(value));
		}

		if (sessionStorage) {
			return sessionStorage.setItem(key, stringify(value));
		}

		return null;
	},

	setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
		return auth.set(value, tokenKey, isLocalStorage);
	},

	setUserInfo(value?: any, isLocalStorage = false, userInfo = USER_INFO) {
		return auth.set(value || '', userInfo, isLocalStorage);
	},

	setOwnID(value = null, isLocalStorage = false, ownID = OWN_ID) {
		return auth.set(value, ownID, isLocalStorage);
	},

	setUserRole(value = '', isLocalStorage = false, UserRole = USER_ROLE) {
		return auth.set(value, UserRole, isLocalStorage);
	},

	setClientID(value: number | string = 0, isLocalStorage = false, clientID = CLIENT_ID) {
		return auth.set(value.toString(), clientID.toString(), isLocalStorage);
	},

	setClient(client?: NellieUser & { email: string }) {
		if (client) return auth.set(JSON.stringify(client), 'client');

		return this.clear('client');
	},

	getClient(): (NellieUser & { email: string }) | undefined {
		const client = auth.get('client');

		return client ? JSON.parse(client) : undefined;
	},

	setTrainee(trainee?: NellieUser) {
		if (trainee) return auth.set(JSON.stringify(trainee), 'trainee');

		return this.clear('trainee');
	},

	getTrainee(): NellieUser | undefined {
		const trainee = auth.get('trainee');

		return trainee ? JSON.parse(trainee) : undefined;
	},
};

export default auth;
