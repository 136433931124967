import { useContext } from 'react';
import { FiCalendar, FiCheckSquare, FiMessageSquare, FiVideo } from 'react-icons/fi';
import { RiSettingsLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { useUnreadMessagesContext } from '@/App';
import { GlobalContext } from '@/context';
import { ROUTES } from '@/routes';

import { NavItem } from '../components/NavItem';
import { NavItemTooltip } from '../components/NavItemTooltip';

import { AuthenticatedNavItems, DETAILED_EXPLANATION } from './AuthenticatedNavItems';

export const ClientNavItems = ({ isCollapsed, onClose, isMobileView }) => {
	const { unreadMessagesCount } = useUnreadMessagesContext();
	const { generalInfoComplete } = useContext(GlobalContext);
	const navigate = useNavigate();

	const genericProps = {
		isCollapsed,
		onClick: onClose,
		py: 3,
	};

	return (
		<>
			<AuthenticatedNavItems isCollapsed={isCollapsed} onClose={onClose} />
			<NavItemTooltip
				isDisabled={generalInfoComplete}
				label="Functions disabled, please complete the Intake Survey"
				offset={isMobileView ? [0, -100] : isCollapsed ? undefined : [0, -10]}
				placement="end"
				w={isMobileView ? '180px' : undefined}
			>
				<Box
					cursor={generalInfoComplete ? undefined : 'not-allowed'}
					onClick={
						generalInfoComplete
							? undefined
							: () => {
									onClose?.();
									navigate(`${ROUTES.ROOT}?${DETAILED_EXPLANATION}=1`);
							  }
					}
				>
					<Box
						_disabled={{
							opacity: '0.4',
							pointerEvents: 'none',
						}}
						aria-disabled={!generalInfoComplete}
					>
						<NavItem
							icon={FiCheckSquare}
							label="Assignments"
							to={ROUTES.ASSIGNMENTS}
							{...genericProps}
						/>
						<NavItem
							icon={FiCalendar}
							label="Appointments"
							to={ROUTES.APPOINTMENTS_CLIENT}
							{...genericProps}
						/>
						<NavItem
							count={unreadMessagesCount}
							icon={FiMessageSquare}
							label="Messages"
							to={ROUTES.CHAT}
							{...genericProps}
						/>
						<NavItem icon={FiVideo} label="Video Call" to={ROUTES.VIDEOCALL} {...genericProps} />
					</Box>
				</Box>
			</NavItemTooltip>
			<NavItem icon={RiSettingsLine} label="Settings" to={ROUTES.SETTINGS} {...genericProps} />
		</>
	);
};
