import * as React from 'react';

import sudsData from './sudsData.json';

const determineState = (activeIndex, index) => {
	if (activeIndex < index) return 'complete';
	if (activeIndex === index) return 'active';
	return 'incomplete';
};

export const useProgressState = (startingState?: string) => {
	const [activeStep, setActiveStep] = React.useState<number | undefined>(undefined);
	const [isClicked, setIsClicked] = React.useState(false);

	React.useEffect(() => {
		if (startingState) {
			const i = sudsData.findIndex((el) => el.rating === startingState);
			setActiveStep(i);
			setIsClicked(true);
		}
	}, [startingState]);

	return {
		// value: (100 / factor) * activeStep,
		getState: (index) => determineState(activeStep, index),
		onClick: (index) => () => {
			setActiveStep(index);
			setIsClicked(true);
			return index;
		},
		activeStep,
		isClicked,
	};
};
