import { ReactNode, memo, useMemo } from 'react';

import {
	Box,
	BoxProps,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	Text,
} from '@chakra-ui/react';
import { CSSProperties } from '@emotion/serialize';
import { useFormikContext } from 'formik';

import { FormElementType } from '@/@types/enums';
import { Markdown } from '@/components/ui';

export type InputWrapperProps = BoxProps & {
	borderColor?: CSSProperties['borderColor'];
	children: ReactNode;
	fieldName?: string;
	isDisabled_?: boolean;
	removeReactMarkdownMarginBottom?: boolean;
	saveOnBlur?: (values: unknown) => void;
	saveSpace?: boolean;
	showQuestionText?: boolean;
	thisElement: NellieFormElement;
};

export const InputWrapper = memo<InputWrapperProps>(
	({
		children: propsChildren,
		saveOnBlur,
		saveSpace = false,
		isDisabled_ = false,
		fieldName = null,
		showQuestionText = true,
		borderColor,
		removeReactMarkdownMarginBottom = false,
		thisElement: { qid, helperText = '', text, type, style = {} },
		mb = 3,
		mt = 4,
		...props
	}) => {
		const { errors, values, submitCount } = useFormikContext();
		const tempQid = fieldName ?? qid;

		const isRadio = useMemo(() => {
			return [
				FormElementType.radio,
				FormElementType.radioHStack,
				FormElementType.radioVStack,
				FormElementType.radioVStackWithText,
			].includes(type);
		}, [type]);

		const questionText = showQuestionText && text && (
			<Box
				fontSize="lg"
				fontWeight="normal"
				mb={saveSpace ? 0 : isRadio ? 5 : undefined}
				ml={{ base: 0, md: 10 }}
				textAlign="left"
			>
				<Markdown skipHtml>{text}</Markdown>
			</Box>
		);

		// apply marginTop with minus sign to remove the default marginBottom of ReactMarkdown children
		const children =
			removeReactMarkdownMarginBottom && text ? (
				<div style={{ marginTop: '-10px' }}>{propsChildren}</div>
			) : (
				propsChildren
			);

		if (isDisabled_)
			return (
				<Box
					border={saveSpace ? '0px' : '2px'}
					borderColor={borderColor || 'transparent'}
					borderRadius="md"
					mb={mb}
					mt={mt}
					mx="auto"
					// eslint-disable-next-line react/jsx-sort-props
					ml={style.marginLeft}
					p={saveSpace ? 0 : undefined}
					pl={style.paddingLeft}
					w="100%"
					{...props}
				>
					{questionText}
					{children}
					{!saveSpace && (
						<Text alignSelf="start" color="gray.500" textAlign="start">
							{helperText}
						</Text>
					)}
					{!saveSpace && <FormErrorMessage>{errors[tempQid]}</FormErrorMessage>}
				</Box>
			);

		return (
			<FormControl
				border={saveSpace ? '0px' : '2px'}
				borderColor={borderColor || (errors[tempQid] && submitCount > 0 ? 'red' : 'transparent')}
				borderRadius="md"
				isInvalid={errors[tempQid] && submitCount > 0}
				mb={mb}
				mt={mt}
				mx="auto"
				// eslint-disable-next-line react/jsx-sort-props
				ml={style.marginLeft}
				p={saveSpace ? 0 : undefined}
				pl={style.paddingLeft}
				onBlur={
					saveOnBlur === undefined //undefined if form_header does not have autoSave: true
						? undefined
						: () => saveOnBlur(values)
				}
				{...props}
			>
				{questionText}
				{children}
				{!saveSpace && (
					<FormHelperText alignSelf="start" textAlign="start">
						{helperText}
					</FormHelperText>
				)}
				{!saveSpace && <FormErrorMessage>{errors[tempQid]}</FormErrorMessage>}
			</FormControl>
		);
	}
);
