import React, { Suspense, createContext, lazy, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CustomSpinner } from '@/components/ui';
import { GlobalContext, useGlobalActions } from '@/context';
import { useMountEffect } from '@/hooks';
import { ROUTES } from '@/routes';
import { Roles } from '@/utils';
import auth from '@/utils/auth';

//Private Routes used to wrap Routes that are protected
import AuthenticatedRoute from './PrivateRoutes/AuthenticatedRoute';
import { RoleBasedAuthenticatedRoute } from './PrivateRoutes/RoleBaseAuthenticatedRoute';

// Public
const AuthPage = lazy(() => import('../Auth'));
const Registration = lazy(() => import('../Auth/UserRegistration'));
const ForgotPass = lazy(() => import('../Auth/ForgotPass'));
const ResetPass = lazy(() => import('../Auth/ResetPass'));

// Authenticated
const Dashboard = lazy(() => import('../Dashboard'));
const UserSettings = lazy(() => import('../UserSettings/UserSettings'));
const Chat = lazy(() => import('../Chat'));
const VideoCall = lazy(() => import('../VideoCall'));
const CreateAppointment = lazy(() => import('../Appointments/CreateAppointment'));

// Coordinator Only
const ClientManager = lazy(() => import('../ClientManager/ClientManger'));

// Client Only
const ClientAssignmentView = lazy(() => import('../Assignments/ClientAssignmentView'));
const FormProvider = lazy(() => import('../FormProvider'));
const FormEngineV2Container = lazy(() => import('../../forms/core/FormEngineV2Container'));
const GeneralInfo = lazy(() => import('../GeneralInfo/GeneralInfo'));
const FormCompleted = lazy(() => import('../FormProvider/FormComponents/FormCompleted'));
const ClientAppointments = lazy(() => import('../Appointments/ClientAppointments'));

// Clinician Only .
const TableClients = lazy(() => import('../ClientsTable'));
const ClinicianAssignmentView = lazy(() => import('../Assignments/ClinicianAssignmentView'));
const Workshops = lazy(() => import('../Workshops'));
const Notes = lazy(() => import('../Notes'));
const Note = lazy(() => import('../Notes/components/NoteItem/NoteItemPage'));
const NotesLandingPage = lazy(() => import('../Notes/LandingPage'));
const CreateNote = lazy(() => import('../Notes/components/NoteItem/CreateNoteItemPage'));
const ClientProfile = lazy(() => import('../ClientProfilePage/ClientProfilePage'));
const ClinicianAppointments = lazy(() => import('../Appointments/ClinicianAppointments'));
const NellieAnalytics = lazy(() => import('../Analytics'));

const public_route_group = (
	<>
		<Route element={<AuthPage />} path={ROUTES.LOGIN} />
		<Route element={<Registration />} path={ROUTES.REGISTER} />
		<Route element={<ForgotPass />} path={ROUTES.FORGOT} />
		<Route element={<ResetPass />} path={ROUTES.RESET} />
	</>
);

const authenticated_route_group = (
	<>
		<Route element={<Dashboard />} path={ROUTES.ROOT} />
		<Route element={<UserSettings />} path={ROUTES.SETTINGS} />
		<Route element={<FormProvider />} path={ROUTES.FORMS} />
		<Route element={<FormEngineV2Container />} path={ROUTES.FORMS_V2} />
		<Route element={<GeneralInfo />} path={ROUTES.GENERAL_INFO} />
		<Route element={<CreateAppointment />} path={ROUTES.APPOINTMENTS_CREATE} />
	</>
);

const client_route_group = (
	<>
		<Route element={<ClientAssignmentView />} path={ROUTES.ASSIGNMENTS} />
		<Route element={<FormCompleted />} path={ROUTES.FORM_COMPLETE} />
		<Route element={<ClientAppointments />} path={ROUTES.APPOINTMENTS_CLIENT} />
		<Route element={<Chat />} path={ROUTES.CHAT} />
		<Route element={<VideoCall />} path={ROUTES.VIDEOCALL} />
	</>
);

const clinician_route_group = (
	<>
		<Route element={<Chat />} path={ROUTES.CHAT} />
		<Route element={<VideoCall />} path={ROUTES.VIDEOCALL} />
		<Route element={<TableClients />} path={ROUTES.CLIENTS} />
		<Route element={<ClinicianAssignmentView />} path={ROUTES.CLIENT_ASSIGNMENTS} />
		<Route element={<Workshops />} path={ROUTES.WORKSHOPS} />
		<Route element={<Notes />} path={ROUTES.CLIENT_NOTES}>
			<Route index element={<NotesLandingPage />} />
			<Route element={<Note />} path=":noteId" />
			<Route element={<CreateNote />} path="create" />
		</Route>
		<Route element={<ClientProfile />} path={ROUTES.CLIENT_INFO} />
		<Route element={<ClinicianAppointments />} path={ROUTES.APPOINTMENTS_CLINICIAN} />
	</>
);

const coordinator_route_group = (
	<>
		<Route element={<ClientManager />} path={ROUTES.CCM} />
	</>
);

const AllRoutes = () => {
	const { generalInfoComplete } = useContext(GlobalContext);

	return (
		<Routes>
			{public_route_group}
			<Route element={<AuthenticatedRoute />}>
				{authenticated_route_group}
				<Route element={<RoleBasedAuthenticatedRoute requiredRoles={[Roles.client]} />}>
					{generalInfoComplete && client_route_group}
				</Route>
				<Route element={<RoleBasedAuthenticatedRoute requiredRoles={[Roles.clinician]} />}>
					{clinician_route_group}
				</Route>
				<Route element={<RoleBasedAuthenticatedRoute requiredRoles={[Roles.coordinator]} />}>
					{coordinator_route_group}
				</Route>
				<Route
					element={
						<RoleBasedAuthenticatedRoute requiredRoles={[Roles.coordinator, Roles.clinician]} />
					}
				>
					<Route element={<NellieAnalytics />} path={ROUTES.ANALYTICS} />
				</Route>
				<Route
					element={
						<Navigate
							replace
							state={{ fromPath: location.pathname }}
							to={auth.getUserInfo()?.id ? ROUTES.ROOT : ROUTES.LOGIN}
						/>
					}
					path="*"
				/>
			</Route>
		</Routes>
	);
};

export type VideoObject = {
	videoRef: React.RefObject<HTMLDivElement>;
};

const setCallObjectContext = createContext<React.Dispatch<React.SetStateAction<VideoObject>>>(
	() => void 0
);

export const useSetVideoObjectContext = () => useContext(setCallObjectContext);
export const videoRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export default function () {
	const { setTrainee, setClient } = useGlobalActions();

	useMountEffect(() => {
		if (auth.getTrainee()) {
			setTrainee(auth.getTrainee());
		}

		const client = auth.getClient();
		if (client) {
			const { email, ...nellie_user } = client;
			setClient({ nellie_user, email });
		}
	});

	return (
		<Suspense fallback={<CustomSpinner />}>
			<AllRoutes />
		</Suspense>
	);
}
