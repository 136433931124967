import { memo, useCallback, useEffect, useState } from 'react';
import type { ITimezoneOption } from 'react-timezone-select';

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Stack,
	StackDivider,
	Switch,
	Text,
	Tooltip,
	VStack,
	useColorModeValue,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Form, Formik, type FormikHelpers, type FormikProps } from 'formik';
import { capitalize, find, isEmpty, isEqual, merge, pick } from 'lodash-es';

import { Api } from '@/api';
import {
	AppField,
	ColorModeSwitcher,
	DatePicker,
	FieldGroup,
	FormikPhoneInput,
	type FormikPhoneInputProps,
	Select,
	type SelectProps,
	SignaturePad,
	TimezoneSelect,
	openConfirmationModal,
} from '@/components/ui';
import { EditableField, EditableFieldProvider } from '@/components/ui/EditableField';
import '@/styles/nellie.css';
import {
	Roles,
	clientStatusOptions,
	clientStatusOptionsForCoordinator,
	formatDate,
	formatPhoneNumber,
	getAllFieldsAreFilled,
	getMyTimezone,
	getPhoneNumberIsVerified,
	resetFormFields,
} from '@/utils';
import auth from '@/utils/auth';

import { SettingWithInput } from './SettingWithInput';
import { type FormValues, getSectionIsValid, sections, validationSchema } from './utils';

export { getInitialValues } from './utils';
export type ProfileValues = FormValues;

const defaultEditModeSections = {
	profile: { isEditMode: false, isLoading: false },
	clientStatus: { isEditMode: false, isLoading: false },
	address: { isEditMode: false, isLoading: false },
	emergency: { isEditMode: false, isLoading: false },
	timezone: { isEditMode: false, isLoading: false },
};

export type ProfileProps = {
	isUpdating?: boolean;
	onUpdate: (profile: Partial<FormValues & { clinician_id?: number }>) => Promise<{
		notifications_sms?: boolean;
		two_factor_enabled?: boolean;
		verify_phone_number?: string;
	}>;
	profile: FormValues & { clinician?: Clinician; hasAssignedClinician?: boolean };
	showSignature?: boolean;
	viewingOwnProfile?: boolean;
};

const accountTypes = [
	{
		label: 'Clinician',
		value: 'clinician',
	},
	{
		label: 'Client',
		value: 'client',
	},
];

const Profile = ({
	profile,
	onUpdate,
	isUpdating,
	viewingOwnProfile = true,
	showSignature,
}: ProfileProps) => {
	const viewingClientProfile = !viewingOwnProfile;

	const editSectionBtnColor = useColorModeValue('#2F2E8A', 'teal.300');

	const [editableSections, setEditableSections] = useState(defaultEditModeSections);
	const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(profile.verified_phone_number);
	useEffect(() => {
		setVerifiedPhoneNumber(profile.verified_phone_number);
	}, [profile.verified_phone_number]);

	useEffect(() => {
		if (!profile) return;
		setEditableSections((prev) => ({
			address: {
				isEditMode: !getAllFieldsAreFilled(profile, sections.address),
				isLoading: prev.address.isLoading,
			},
			emergency: {
				isEditMode: !getAllFieldsAreFilled(profile, sections.emergency),
				isLoading: prev.emergency.isLoading,
			},
			profile: {
				isEditMode: !getAllFieldsAreFilled(profile, sections.profile),
				isLoading: prev.profile.isLoading,
			},
			timezone: {
				isEditMode: !getAllFieldsAreFilled(profile, sections.timezone),
				isLoading: prev.timezone.isLoading,
			},
			clientStatus: {
				isEditMode: !getAllFieldsAreFilled(profile, sections.clientStatus),
				isLoading: prev.clientStatus.isLoading,
			},
		}));
	}, [profile]);

	const saveForm = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
		const updates = await onUpdate(values);
		actions.resetForm({
			values: merge(values, pick(updates, Object.keys(values))) as FormValues,
			touched: {},
			errors: {},
		});
	};

	const saveSection = async (
		sectionFields: (keyof FormValues)[],
		formikProps: FormikProps<FormValues>
	) => {
		const sectionIsValid = getSectionIsValid(sectionFields, formikProps);

		if (!sectionIsValid) return;

		const response = await onUpdate(pick(formikProps.values, sectionFields));

		const updatedValue = sectionFields.reduce((partialValues, field) => {
			return {
				...partialValues,
				[field]: response[field] ?? formikProps.values[field],
			};
		}, {} as Partial<FormValues>);

		resetFormFields(formikProps, sectionFields, updatedValue);
	};

	const getSectionEditButton = (section: keyof typeof editableSections) => (
		<HStack alignSelf="end" mt={3}>
			<Text
				_hover={{ textDecoration: 'underline' }}
				as="span"
				color={editSectionBtnColor}
				cursor="pointer"
				fontSize="large"
				onClick={() =>
					setEditableSections((prev) => ({
						...prev,
						[section]: { ...prev[section], isEditMode: true },
					}))
				}
			>
				Edit section
			</Text>
		</HStack>
	);

	const getSectionSaveButton = (
		section: keyof typeof editableSections,
		formikProps: FormikProps<FormValues>
	) => {
		const sectionFields = sections[section];

		const saveSectionChanges = async () => {
			const setSectionLoading = (isLoading: boolean) => {
				return setEditableSections((prev) => ({
					...prev,
					[section]: { ...prev[section], isLoading },
				}));
			};

			try {
				setSectionLoading(true);
				await saveSection(sectionFields, formikProps);
			} catch (error) {
				console.error(error);
			} finally {
				setSectionLoading(false);
			}
		};

		const initialValues = pick(formikProps.initialValues, sectionFields);
		const currentValues = pick(formikProps.values, sectionFields);

		const sectionHasErrors = !isEmpty(pick(formikProps.errors, sectionFields));
		const sectionIsDirty = !isEqual(initialValues, currentValues);
		const sectionIsSaving = editableSections[section].isLoading;

		const saveBtnIsDisabled = !sectionIsDirty || sectionIsSaving || sectionHasErrors;
		return (
			<HStack justify="center" mb={5}>
				<Button
					_hover={
						saveBtnIsDisabled
							? undefined
							: {
									cursor: 'pointer',
									bg: '#2F2E8A',
									color: 'white',
							  }
					}
					colorScheme="purple"
					disabled={saveBtnIsDisabled}
					isLoading={sectionIsSaving}
					variant="outline"
					onClick={async () => {
						await saveSectionChanges();

						setEditableSections((prev) => ({
							...prev,
							[section]: {
								...prev[section],
								isEditMode: !getAllFieldsAreFilled(formikProps.values, sections[section]),
							},
						}));
					}}
				>
					Save Changes
				</Button>
			</HStack>
		);
	};

	const VerifiablePhoneInput = useCallback(
		({ value, ...props }: any) => {
			return (
				<FormikPhoneInput
					isVerified={getPhoneNumberIsVerified(verifiedPhoneNumber, value)}
					onVerify={
						viewingOwnProfile
							? async (code) => {
									await Api.users.verifyVerificationCode({
										code,
										phone_number: value,
									});

									setVerifiedPhoneNumber(value);
							  }
							: undefined
					}
					{...props}
					value={value}
				/>
			);
		},
		[verifiedPhoneNumber, viewingOwnProfile]
	);

	const clientStatusInfo = (formikProps: FormikProps<FormValues>) => {
		type ClientStatusOption = (typeof clientStatusOptions)[number];

		return (
			<EditableFieldProvider isEditMode={editableSections.clientStatus.isEditMode}>
				<FieldGroup
					subtitle={
						!editableSections.clientStatus.isEditMode && getSectionEditButton('clientStatus')
					}
					title="Client Status"
					w="100%"
				>
					<Box w="100%">
						<SettingWithInput<FormValues>
							Input={() => (
								<AppField<FormValues> name="clientStatus">
									{({ field, form }) => {
										const options =
											auth.getUserRole() === Roles.coordinator
												? clientStatusOptionsForCoordinator
												: clientStatusOptions;

										return (
											<>
												<Select
													isClearable={editableSections.clientStatus.isEditMode}
													isReadOnly={!editableSections.clientStatus.isEditMode}
													options={options}
													placeholder="Select Client Status"
													value={find(options, { value: field.value }) as ClientStatusOption}
													onChange={(value) => {
														if (
															(value as ClientStatusOption)?.value === 'inactive' &&
															profile.hasAssignedClinician
														) {
															form.setFieldValue(field.name, field.value);
															return openConfirmationModal({
																title: 'Are you sure?',
																body: 'This client currently is assigned to a provider and will be automatically unmatched from them if they are set to inactive.',
																onSubmit: async () => {
																	await onUpdate({
																		clientStatus: 'inactive',
																		clinician_id: profile.clinician?.id,
																	});
																	form.setFieldValue(field.name, 'inactive');

																	setTimeout(() => {
																		setEditableSections((prev) => ({
																			...prev,
																			clientStatus: {
																				isEditMode: false,
																				isLoading: false,
																			},
																		}));
																	});
																},
															});
														} else {
															form.setFieldValue(field.name, (value as ClientStatusOption)?.value);
														}
													}}
												/>
											</>
										);
									}}
								</AppField>
							)}
							name="clientStatus"
							renderValue={(value) => {
								return (
									<Text fontWeight="medium">
										{
											(find(clientStatusOptionsForCoordinator, { value }) as ClientStatusOption)
												?.label
										}
									</Text>
								);
							}}
							title=""
						/>
					</Box>
				</FieldGroup>
				{editableSections.clientStatus.isEditMode &&
					getSectionSaveButton('clientStatus', formikProps)}
			</EditableFieldProvider>
		);
	};

	const personalInformation = (formikProps: FormikProps<FormValues>) => (
		<EditableFieldProvider isEditMode={editableSections.profile.isEditMode}>
			<FieldGroup
				subtitle={!editableSections.profile.isEditMode && getSectionEditButton('profile')}
				title="Personal Information"
			>
				<VStack w="100%">
					<VStack px={{ base: '0', md: '0' }} py={{ base: '5', md: '10' }} spacing="3" width="full">
						<Stack direction={{ base: 'column', md: 'row' }} spacing="6" w="100%">
							<SettingWithInput<FormValues> name="first_name" title="First Name" />
							<SettingWithInput<FormValues> name="last_name" title="Last Name" />
						</Stack>

						{/* TODO: at this point email should always be non editable */}
						<EditableFieldProvider isEditMode={false}>
							<SettingWithInput<FormValues> name="email" title="Email" />
						</EditableFieldProvider>
						<Box alignSelf="self-start" w={{ md: '2xs', base: '100%' }}>
							<EditableFieldProvider
								isEditMode={
									viewingOwnProfile
										? false
										: editableSections.profile.isEditMode &&
										  auth.getUserRole() === Roles.coordinator
								}
							>
								<SettingWithInput<FormValues, SelectProps>
									Input={Select}
									isClearable={false}
									name="accountType"
									options={accountTypes}
									renderValue={(accountType) => capitalize(accountType as string)}
									title="Account Type"
									value={find(accountTypes, { value: formikProps.values.accountType })}
									onChange={({ value }) => formikProps.setFieldValue('accountType', value)}
								/>
							</EditableFieldProvider>
						</Box>
						<Box alignSelf="start" pr="7.5px" w={{ md: 'xs', base: '100%' }}>
							<EditableFieldProvider
								isEditMode={viewingOwnProfile ? editableSections.profile.isEditMode : false}
							>
								<SettingWithInput<FormValues>
									Input={VerifiablePhoneInput}
									isReadOnly={!viewingOwnProfile}
									name="phone"
									renderValue={formatPhoneNumber}
									title="Phone"
								/>
							</EditableFieldProvider>
						</Box>
						{viewingOwnProfile && (
							<Tooltip
								hasArrow
								isDisabled={Boolean(
									!viewingOwnProfile ||
										!editableSections.profile.isEditMode ||
										formikProps.values.phone === verifiedPhoneNumber
								)}
								label={
									<Text p={2}>
										Please verify your phone number to enable Multi-Factor Authentication
									</Text>
								}
								placement="bottom"
							>
								<FormControl alignItems="center" alignSelf="start" display="flex" w="fit-content">
									<AppField<PickFrom<FormValues, boolean>> name="two_factor_enabled">
										{({ field: { value, ...field }, form }) => (
											<Switch
												colorScheme="purple"
												isChecked={value}
												isDisabled={
													!verifiedPhoneNumber ||
													!editableSections.profile.isEditMode ||
													(form.values as any).phone !== verifiedPhoneNumber
												}
												{...field}
												mr="5"
												onChange={() => {
													form.setFieldValue(field.name, !value);
												}}
											/>
										)}
									</AppField>
									<FormLabel fontSize="sm" mb="0">
										Enable Multi-Factor Authentication
									</FormLabel>
								</FormControl>
							</Tooltip>
						)}
						{viewingOwnProfile && (
							<Tooltip
								hasArrow
								isDisabled={Boolean(
									!viewingOwnProfile ||
										!editableSections.profile.isEditMode ||
										formikProps.values.phone === verifiedPhoneNumber
								)}
								label={
									<Text p={2}>Please verify your phone number to enable SMS notifications</Text>
								}
								placement="bottom"
							>
								<FormControl alignItems="center" alignSelf="start" display="flex" w="fit-content">
									<AppField<PickFrom<FormValues, boolean>> name="notifications_sms">
										{({ field: { value, ...field }, form }) => (
											<Switch
												colorScheme="purple"
												isChecked={value}
												isDisabled={
													!verifiedPhoneNumber ||
													!editableSections.profile.isEditMode ||
													(form.values as any).phone !== verifiedPhoneNumber
												}
												{...field}
												mr="5"
												onChange={() => {
													form.setFieldValue(field.name, !value);
												}}
											/>
										)}
									</AppField>
									<FormLabel fontSize="sm" mb="0">
										Enable Text Notifications
									</FormLabel>
								</FormControl>
							</Tooltip>
						)}

						<FormControl alignItems="center" alignSelf="start" display="flex" w="fit-content">
							<AppField<PickFrom<FormValues, boolean>> name="notifications_email">
								{({ field: { value, ...field }, form }) => (
									<Switch
										colorScheme="purple"
										isChecked={value}
										isDisabled={!editableSections.profile.isEditMode}
										{...field}
										mr="5"
										onChange={() => {
											form.setFieldValue(field.name, !value);
										}}
									/>
								)}
							</AppField>
							<FormLabel fontSize="sm" mb="0">
								Enable Email Notifications
							</FormLabel>
						</FormControl>

						<EditableField<FormValues>
							label="Date of Birth"
							name="dob"
							renderValue={(date) => {
								return date ? formatDate(new Date(date as string), 'MM/D/YYYY') : '';
							}}
						>
							{({ field: { value, ...field }, form }) => {
								return (
									<DatePicker
										peekNextMonth
										showDisabledMonthNavigation
										showMonthDropdown
										showYearDropdown
										customInput={<Input maxW={{ md: '2xs', base: '100%' }} />}
										dropdownMode="select"
										maxDate={dayjs().toDate()}
										minDate={dayjs().subtract(120, 'years').toDate()}
										selected={
											value
												? new Date(value as string).toString() === 'Invalid Date'
													? null
													: new Date(value as string)
												: null
										}
										{...field}
										onChange={(option) => {
											form.setFieldValue(field.name, option);
										}}
									/>
								);
							}}
						</EditableField>

						{showSignature && (
							<EditableField<FormValues>
								label="Signature"
								name="signature"
								renderValue={(signature) => {
									return (
										<Box border="1px solid" borderColor="gray.100" p="4" w="fit-content">
											<img height={(100 * 16) / 9} src={signature as string} width={100} />
										</Box>
									);
								}}
							>
								{({ field: { value, ...field }, form }) => (
									<SignaturePad
										size={300}
										value={value as string}
										onChange={(option) => {
											form.setFieldValue(field.name, option);
										}}
									/>
								)}
							</EditableField>
						)}
					</VStack>
				</VStack>
			</FieldGroup>
			{editableSections.profile.isEditMode && getSectionSaveButton('profile', formikProps)}
		</EditableFieldProvider>
	);

	const addressInformation = (formikProps: FormikProps<FormValues>) => (
		<EditableFieldProvider isEditMode={editableSections.address.isEditMode}>
			<FieldGroup
				subtitle={!editableSections.address.isEditMode && getSectionEditButton('address')}
				title="Address"
			>
				<VStack w="100%">
					<Stack px={{ base: '0', md: '0' }} py={{ base: '5', md: '10' }} spacing="3" width="full">
						<Stack direction={{ base: 'column', md: 'row' }} spacing="6">
							<SettingWithInput<FormValues> name="address_street" title="Street" />
							<Input display={{ base: 'none', md: 'unset' }} m="0" visibility="hidden" />
						</Stack>

						<Stack direction={{ base: 'column', md: 'row' }} spacing="6">
							<SettingWithInput<FormValues> name="address_city" title="City" />
							<SettingWithInput<FormValues>
								name="address_province_state"
								title="State / Province"
							/>
						</Stack>
						<Stack direction={{ base: 'column', md: 'row' }} spacing="6">
							<SettingWithInput<FormValues> name="address_country" title="Country" />
							<SettingWithInput<FormValues> name="address_postal" title="ZIP / Postal Code" />
						</Stack>
					</Stack>
				</VStack>
			</FieldGroup>
			{editableSections.address.isEditMode && getSectionSaveButton('address', formikProps)}
		</EditableFieldProvider>
	);

	const emergencyContactInformation = (formikProps: FormikProps<FormValues>) => (
		<EditableFieldProvider isEditMode={editableSections.emergency.isEditMode}>
			<FieldGroup
				subtitle={!editableSections.emergency.isEditMode && getSectionEditButton('emergency')}
				title="Emergency Contact"
			>
				<VStack w="100%">
					<Stack px={{ base: '0', md: '0' }} py={{ base: '5', md: '10' }} spacing="3" width="full">
						<Stack direction={{ base: 'column', md: 'row' }} spacing="6">
							<SettingWithInput<FormValues> name="emerg_first_name" title="First Name" />
							<SettingWithInput<FormValues> name="emerg_last_name" title="Last Name" />
						</Stack>
						<Stack direction={{ base: 'column', md: 'row' }} spacing="6">
							<SettingWithInput<FormValues, FormikPhoneInputProps>
								Input={FormikPhoneInput}
								name="emerg_phone"
								renderValue={formatPhoneNumber}
								title="Phone"
							/>
							<SettingWithInput<FormValues> name="emerg_email" title="Email" />
						</Stack>
						<SettingWithInput<FormValues>
							helperText="Please provide the name of a hospital in your geographic area. This should be a hospital with an emergency department. In the event of an emergency, your Nellie clinician would contact this hospital on your behalf."
							maxW="3xl"
							name="emerg_local_hospital_name"
							title="Local hospital name"
						/>
						<SettingWithInput<FormValues, FormikPhoneInputProps>
							helperText="Please provide the phone number for the emergency department at your selected hospital. This information can typically be found on the hospital’s website."
							Input={FormikPhoneInput}
							maxW="3xl"
							name="emerg_local_hospital_number"
							renderValue={formatPhoneNumber}
							title="Phone number of emergency department at selected local hospital"
						/>
					</Stack>
				</VStack>
			</FieldGroup>
			{editableSections.emergency.isEditMode && getSectionSaveButton('emergency', formikProps)}
		</EditableFieldProvider>
	);

	const accessibilityInformation = viewingOwnProfile && (
		<FieldGroup title="Accessibility">
			<HStack spacing="4" width="full">
				<ColorModeSwitcher />
				<FormLabel cursor="pointer" htmlFor="toggleTheme" mb="0">
					Toggle Dark/Light Theme
				</FormLabel>
			</HStack>
		</FieldGroup>
	);

	const timeZoneInformation = (formikProps: FormikProps<FormValues>) => (
		<EditableFieldProvider isEditMode={editableSections.timezone.isEditMode}>
			<FieldGroup
				subtitle={!editableSections.timezone.isEditMode && getSectionEditButton('timezone')}
				title="Time Zone"
			>
				<VStack w="100%">
					<Stack px={{ base: '0', md: '0' }} py={{ base: '5', md: '10' }} spacing="3" width="full">
						<EditableField<FormValues>
							label="Your Time Zone"
							name="timezone"
							renderValue={(v) => (v as ITimezoneOption)?.label}
						>
							{({ field, form }) => (
								<TimezoneSelect
									{...field}
									{...form}
									value={field.value || getMyTimezone()}
									onChange={(timezone) => {
										form.setFieldValue(field.name, timezone);
									}}
								/>
							)}
						</EditableField>
					</Stack>
				</VStack>
			</FieldGroup>
			{editableSections.timezone.isEditMode && getSectionSaveButton('timezone', formikProps)}
		</EditableFieldProvider>
	);

	return (
		<Stack divider={<StackDivider />} spacing="4">
			<Formik<FormValues>
				initialValues={profile as FormValues}
				validationSchema={validationSchema}
				onSubmit={saveForm}
			>
				{(formikProps) => {
					const saveButtonIsDisabled = !formikProps.dirty || isUpdating;
					return (
						<Form autoComplete="off" onSubmit={formikProps.handleSubmit}>
							<Box>
								<Flex direction="column">
									{viewingClientProfile && (
										<>
											{clientStatusInfo(formikProps)}
											<Divider />
										</>
									)}

									{personalInformation(formikProps)}

									<Divider />

									{addressInformation(formikProps)}

									<Divider />

									{emergencyContactInformation(formikProps)}

									<Divider />

									{timeZoneInformation(formikProps)}

									<Divider />

									{accessibilityInformation}

									<Box mt="8" textAlign="center">
										<Button
											_hover={
												saveButtonIsDisabled
													? undefined
													: {
															cursor: 'pointer',
															bg: '#2F2E8A',
															color: 'white',
													  }
											}
											colorScheme="purple"
											isDisabled={saveButtonIsDisabled}
											isLoading={formikProps.isSubmitting}
											size="lg"
											type="submit"
											variant="outline"
											w="50%"
										>
											Save Changes
										</Button>
									</Box>
								</Flex>
							</Box>
						</Form>
					);
				}}
			</Formik>
		</Stack>
	);
};

export default memo(Profile);
