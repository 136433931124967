import { UseToastOptions, useToast } from '@chakra-ui/react';

export const useSuccessToast = (options?: UseToastOptions) => {
	return useToast({
		status: 'success',
		title: `Success!`,
		// id: toastId,
		isClosable: true,
		duration: 3800,
		position: 'bottom',
		...options,
	});
};

export const useInfoToast = (options?: UseToastOptions) => {
	return useToast({
		status: 'info',
		title: `Success!`,
		// id: toastId,
		variant: 'subtle',
		isClosable: true,
		duration: 3800,
		position: 'bottom',
		...options,
	});
};

export const useErrorToast = (options?: UseToastOptions) => {
	return useToast({
		status: 'error',
		title: `An error occurred`,
		// id: toastId,
		isClosable: true,
		duration: 5000,
		position: 'bottom',
		...options,
	});
};
