import { useLocation } from 'react-router-dom';

import { useUnreadMessagesContext } from '@/App';
import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';
import { ROUTES } from '@/routes';
import { Roles } from '@/utils';
import auth from '@/utils/auth';

import { useFocusedClientId } from './useFocusedClientId';

const ONE_MINUTE = 60 * 1000;

export const useUnreadMessages = () => {
	const { pathname } = useLocation();
	const focusedClientId = useFocusedClientId();
	const { setUnreadMessagesCount } = useUnreadMessagesContext();

	const enabledForClient = auth.getUserRole() === Roles.client;
	const enabledForClinician = auth.getUserRole() === Roles.clinician && Boolean(focusedClientId);
	useAppQuery(
		['getUnreadMessagesCount', focusedClientId, auth.getUserInfo()?.id],
		() => {
			return Api.users.getUnreadMessagesCount({
				recipient_id: auth.getUserInfo().id,
				sender_id: focusedClientId ? focusedClientId.toString() : undefined,
			});
		},
		{
			enabled: pathname !== ROUTES.CHAT && (enabledForClient || enabledForClinician),
			refetchInterval: ONE_MINUTE,
			onSuccess: setUnreadMessagesCount,
		}
	);
};
