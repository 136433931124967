import { ReactNode } from 'react';

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { isFunction } from 'lodash-es';

import { AppField, AppFieldProps } from './AppField';

type Props<T extends Record<string, any>> = {
	children: AppFieldProps<T>['children'];
	label?: ReactNode;
	name: keyof T;
	showErrorOnTouched?: boolean;
};

export function FormField<T extends Record<string, any> = Record<string, any>>({
	children,
	name,
	label,
	showErrorOnTouched = true,
}: Props<T>) {
	return (
		<AppField<T> name={name}>
			{(fieldProps) => (
				<FormControl
					isInvalid={
						showErrorOnTouched
							? fieldProps.meta.touched && Boolean(fieldProps.meta.error)
							: Boolean(fieldProps.meta.error)
					}
				>
					{label && <FormLabel>{label}</FormLabel>}
					{isFunction(children) ? children(fieldProps) : children}
					<FormErrorMessage>{fieldProps.meta.error}</FormErrorMessage>
				</FormControl>
			)}
		</AppField>
	);
}
