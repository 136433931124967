import { Badge } from '@chakra-ui/react';

export const noteTypesBadges = {
	Assessment: <Badge>Assessment</Badge>,
	Other: <Badge colorScheme="orange">Other</Badge>,
	Telephone: <Badge colorScheme="purple">Telephone</Badge>,
	Treatment: <Badge colorScheme="green">Treatment</Badge>,
	Upload: <Badge colorScheme="blue">Upload</Badge>,
};

export const noteTypes = [
	'Assessment' as const,
	'Other' as const,
	'Telephone' as const,
	'Treatment' as const,
];
