import { SearchIcon } from '@chakra-ui/icons';
import { Icon, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';

import { useIsLightMode } from '@/hooks';

export const SearchInput = ({ size, w, width, flex, ...props }: InputProps) => {
	const isLightMode = useIsLightMode();

	return (
		<InputGroup flex={flex} size={size} w={w} width={width}>
			<InputLeftElement pointerEvents="none">
				<Icon as={SearchIcon} color="#3A38A6" />
			</InputLeftElement>
			<Input
				bg={isLightMode ? 'white' : 'gray.800'}
				borderRadius="md"
				focusBorderColor="#3A38A6"
				placeholder="Search"
				{...props}
			/>
		</InputGroup>
	);
};
