import React from 'react';

import { Box } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';

import { FormElementProps } from '../FormElement';
import { InputWrapper } from '../InputWrapper';

import { Step } from './Step';
import sudsData from './sudsData.json';
import { useProgressState } from './useProgressState';

export const Suds = ({ thisElement, ...rest }: FormElementProps) => {
	const { values }: { values: FormikValues } = useFormikContext();

	const startingState: string | undefined = React.useMemo(() => {
		if (!rest.isDisabled_) return;
		if (!values) return;
		const initialValuesArr = Object.entries(values);
		const startingStateIndex = initialValuesArr.findIndex((el) => el[0] === thisElement.qid);
		return initialValuesArr[startingStateIndex][1];
	}, [rest.isDisabled_, thisElement.qid, values]);

	const { getState, onClick, isClicked } = useProgressState(startingState);

	return (
		<InputWrapper thisElement={thisElement}>
			<Box w="2xl">
				{sudsData.map((item, index) => (
					<Step
						key={index.toString()}
						desc={item.desc}
						isClicked={isClicked}
						isDisabled_={rest.isDisabled_}
						qid={thisElement.qid}
						rating={item.rating}
						state={getState(index)}
						onClick={onClick(index)}
					/>
				))}
			</Box>
		</InputWrapper>
	);
};
