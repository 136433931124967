import { useRef, useState } from 'react';

import { useMountEffect } from '@/hooks';

export const ShowContentWithDelay = ({ children, delayMs }) => {
	const [showContent, setShowContent] = useState(false);
	const isUnMounted = useRef(false);

	useMountEffect(() => {
		setTimeout(() => {
			if (isUnMounted.current) return;
			setShowContent(true);
		}, delayMs);

		return () => {
			isUnMounted.current = true;
		};
	});

	return showContent ? children : null;
};
