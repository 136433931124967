import { memo, useCallback, useMemo } from 'react';

import {
	Flex,
	Slider,
	SliderFilledTrack,
	SliderMark,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { FieldInputProps, useFormikContext } from 'formik';

export type CustomSliderProps = {
	_disabled?: CSSObject;
	field: FieldInputProps<any>;
	isDisabled_?: boolean;
	saveOnBlur?: (values: unknown) => void;
	sliderOptions: SliderOptions;
};

export const CustomSlider = memo<CustomSliderProps>(
	({ sliderOptions, field, isDisabled_ = false, _disabled, saveOnBlur }) => {
		const { max, min, step, defaultValue, order = 'asc' } = sliderOptions;
		const { setFieldTouched, setFieldValue } = useFormikContext();
		const onChange = useCallback(
			(value: number): void => {
				const value_ = order === 'desc' ? max - value : value;
				setFieldTouched(field.name, true);
				setFieldValue(field.name, value_);
			},
			[order, max, setFieldTouched, field, setFieldValue]
		);
		const defaultValue_ = useMemo(() => {
			if (field.value) return order === 'desc' ? max - field.value : field.value;

			return order === 'desc' ? max - defaultValue : defaultValue;
		}, [defaultValue, field.value, max, order]);

		return (
			<Slider
				_disabled={_disabled}
				aria-label="slider-ex-5"
				defaultValue={defaultValue_}
				focusThumbOnChange={false}
				isDisabled={isDisabled_}
				max={max}
				mb={10}
				min={min}
				step={step}
				onBlur={saveOnBlur}
				onChangeEnd={(value) => onChange(value)}
			>
				{Array.from({ length: max / step + 1 }, (_, i) => {
					const value_ = order === 'desc' ? max - i * step : i * step;
					return (
						<SliderMark
							key={i}
							fontSize="md"
							mt="5"
							pointerEvents="all"
							position="relative"
							style={{ pointerEvents: 'all', position: 'relative' }}
							transform="translateX(-50%)"
							value={i * step}
						>
							{value_}
						</SliderMark>
					);
				})}
				<SliderTrack>
					<SliderFilledTrack bg="gray.400" />
				</SliderTrack>
				<SliderThumb bg="gray.500" boxSize={5} />
			</Slider>
		);
	}
);

export const CustomSliderWithText = memo<CustomSliderProps>(
	({ sliderOptions, field, isDisabled_ = false, _disabled, saveOnBlur }) => {
		const {
			max,
			min,
			step,
			defaultValue,
			order = 'asc',
			textLeft,
			textRight,
			fontSize = 'lg',
		} = sliderOptions;
		const { setFieldTouched, setFieldValue } = useFormikContext();
		const onChange = useCallback(
			(value: number): void => {
				const value_ = order === 'desc' ? max - value : value;
				setFieldTouched(field.name, true);
				setFieldValue(field.name, value_);
			},
			[order, max, setFieldTouched, field, setFieldValue]
		);
		const defaultValue_ = useMemo(() => {
			if (field.value) return order === 'desc' ? max - field.value : field.value;

			return order === 'desc' ? max - defaultValue : defaultValue;
		}, [defaultValue, field.value, max, order]);

		return (
			<Flex flexDirection="row" flexWrap="nowrap" justifyContent="center" w="full">
				<VStack alignItems="flex-end" flexShrink={1} mr={5}>
					{textLeft?.map((el, i) => (
						<Text key={i} fontSize={fontSize} minW={{ md: '120px' }} textAlign="right">
							{el}
						</Text>
					))}
				</VStack>
				<Slider
					_disabled={_disabled}
					aria-label="slider-ex-5"
					defaultValue={defaultValue_}
					flexBasis="500px"
					flexGrow={3}
					flexShrink={2}
					focusThumbOnChange={false}
					isDisabled={isDisabled_}
					max={max}
					mb={10}
					min={min}
					minWidth="100px"
					step={step}
					onBlur={saveOnBlur}
					onChangeEnd={(value) => onChange(value)}
				>
					{Array.from({ length: max / step + 1 }, (_, i) => {
						const value_ = order === 'desc' ? max - i * step : i * step;
						return (
							<SliderMark
								key={i}
								fontSize="md"
								mt="5"
								pointerEvents="all"
								position="relative"
								style={{ pointerEvents: 'all', position: 'relative' }}
								transform="translateX(-50%)"
								value={i * step}
							>
								{value_}
							</SliderMark>
						);
					})}
					<SliderTrack>
						<SliderFilledTrack bg="gray.400" />
					</SliderTrack>
					<SliderThumb bg="gray.500" boxSize={5} />
				</Slider>
				<VStack alignItems="flex-start" flexShrink={1} ml={5}>
					{textRight?.map((el, i) => (
						<Text key={i} fontSize={fontSize} minW={{ md: '120px' }} textAlign="left">
							{el}
						</Text>
					))}
				</VStack>
			</Flex>
		);
	}
);
