import { CSSProperties } from 'react';

import { Box, Center, HStack, Highlight, Text, VStack } from '@chakra-ui/react';

import { useAppMutation } from '@/app-react-query';
import { CustomSpinnerTiny } from '@/components/ui';
import { formatDate } from '@/utils';

type FileListItemProps = {
	borderBottomStyle?: CSSProperties['borderStyle'];
	date?: Date;
	filename?: string;
	focusOnInput?: VoidFunction;
	onClick: () => Promise<void>;
	searchTerm?: string;
	title?: string;
};

export const FileListItem = ({
	date,
	filename,
	title,
	searchTerm,
	focusOnInput,
	onClick,
	borderBottomStyle,
}: FileListItemProps) => {
	const downloadLink = useAppMutation(onClick);

	const hoverStyles = downloadLink.isLoading
		? undefined
		: {
				borderLeft: '3px solid',
				borderColor: 'purple.500',
				cursor: 'pointer',
		  };

	const download = async () => {
		if (downloadLink.isLoading) return;
		downloadLink.mutateAsync();
	};

	return (
		<Box
			_focusVisible={{ outline: 'none', backgroundColor: 'gray.200' }}
			_hover={{ backgroundColor: 'gray.200' }}
			maxH="300"
			position="relative"
			role="group"
			tabIndex={0}
			w="100%"
			onClick={download}
			onKeyDown={(e) => {
				if (e.key === 'ArrowDown') {
					(e.currentTarget.nextElementSibling as HTMLDivElement)?.focus();
					return;
				}
				if (e.key === 'ArrowUp') {
					const sibling = e.currentTarget.previousElementSibling as HTMLDivElement | null;

					if (sibling) sibling.focus();
					else {
						focusOnInput?.();
					}
					return;
				}
				if (e.key === 'Enter') {
					download();
				}
			}}
		>
			{downloadLink.isLoading && (
				<Center bg="gray.200" bottom={0} left={0} position="absolute" right={0} top={0}>
					<CustomSpinnerTiny />
					<Text ml={2}>downloading ...</Text>
				</Center>
			)}
			<Box
				_groupFocus={hoverStyles}
				_hover={hoverStyles}
				borderBottom="1px solid"
				borderBottomColor="gray.200"
				borderBottomStyle={borderBottomStyle}
				borderLeft="3px solid"
				borderLeftColor="white"
				px={{ base: 2, md: 2 }}
				py={{ base: 2, md: 2 }}
			>
				<VStack gap="5px">
					{title && (
						<Text w="100%">
							{searchTerm ? (
								<Highlight query={searchTerm} styles={{ bg: 'orange.100' }}>
									{title || ''}
								</Highlight>
							) : (
								title
							)}
						</Text>
					)}
					<HStack w="100%">
						{filename && (
							<Text alignSelf="start" fontSize="xs" noOfLines={2}>
								{searchTerm ? (
									<Highlight query={searchTerm} styles={{ bg: 'orange.100' }}>
										{filename || ''}
									</Highlight>
								) : (
									filename
								)}
							</Text>
						)}
						{date && (
							<Text alignSelf="end" fontSize="xs" marginLeft="auto !important" whiteSpace="pre">
								{formatDate(date, 'MMM D, YYYY  h:mmA')}
							</Text>
						)}
					</HStack>
				</VStack>
			</Box>
		</Box>
	);
};
