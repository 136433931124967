import { ReactNode, useRef, useState } from 'react';
import { InstanceProps, create } from 'react-modal-promise';

import {
	Button,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
	chakra,
} from '@chakra-ui/react';

import { submitWithMetaKeyAndEnter } from '@/utils';

type ModalTypes = {
	body?: ReactNode;
	cancelBtnText?: string;
	closeOnOverlayClick?: boolean;
	initialFocusedButton?: 'submit' | 'cancel';
	modalProps?: Partial<ModalProps>;
	/** button will show loading spinner until the promise resolves */
	onSubmit?: (() => Promise<unknown>) | (() => unknown);
	submitBtnColor?: string;
	submitBtnText?: string;
	title?: ReactNode;
};

const ConfirmationModal: React.FC<ModalTypes & InstanceProps<unknown>> = ({
	isOpen,
	onResolve,
	onReject,
	title,
	body,
	cancelBtnText = 'Cancel',
	submitBtnText = 'Confirm',
	submitBtnColor = 'purple',
	closeOnOverlayClick = true,
	onSubmit,
	modalProps,
	initialFocusedButton,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const initialFocusRef = useRef(null);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			await onSubmit?.();
			onResolve();
		} catch (error) {
			onReject(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal
			closeOnOverlayClick={isLoading ? false : closeOnOverlayClick}
			initialFocusRef={initialFocusRef}
			isOpen={isLoading ? true : isOpen}
			onClose={isLoading ? () => undefined : onReject}
			{...modalProps}
		>
			<ModalOverlay />
			<ModalContent
				as={chakra.form}
				onKeyDown={submitWithMetaKeyAndEnter(handleSubmit)}
				onReset={onReject}
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				<ModalHeader mt={6}>{title ? title : 'Are you sure?'}</ModalHeader>
				<ModalCloseButton tabIndex={-1} />
				<ModalBody as={FormControl} isDisabled={isLoading}>
					{body ? body : 'This action cannot be undone.'}
				</ModalBody>

				<ModalFooter>
					<Button
						ref={initialFocusedButton === 'cancel' ? initialFocusRef : undefined}
						_focus={{ boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)' }}
						isDisabled={isLoading}
						mr={3}
						type="reset"
					>
						{cancelBtnText}
					</Button>
					<Button
						ref={initialFocusedButton === 'submit' ? initialFocusRef : undefined}
						_focus={{ boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)' }}
						colorScheme={submitBtnColor}
						isDisabled={isLoading}
						isLoading={isLoading}
						type="submit"
					>
						{submitBtnText}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export const confirmationModal = create(ConfirmationModal);

/**
 *  a wrapper function for `confirmationModal` with try/catch block
 */
export const openConfirmationModal = async (
	props: Parameters<typeof confirmationModal>[0] & {
		onSubmit: (() => Promise<unknown>) | (() => unknown);
	}
) => {
	try {
		await confirmationModal(props);
	} catch {
		//
	}
};
