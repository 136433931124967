import { ReactNode, useState } from 'react';
import { InstanceProps, create } from 'react-modal-promise';

import {
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

import { SignaturePad } from '@/components/ui';

import { ConfirmButton, OutlineButton } from '../Buttons/Buttons';

type SignatureModalProps = {
	footer?: ReactNode;
	header?: ReactNode;
	image?: string;
	onClose?: VoidFunction;
	onSubmit: (base64Signiture: string) => unknown | Promise<unknown>;
	setImage?: React.Dispatch<React.SetStateAction<string | undefined>>;
	submitBtnText?: string;
};

export function SignatureModal({
	onSubmit,
	onClose,
	header = 'Add Signature',
	footer,
	image: propsImage,
	setImage: propsSetImage,
	submitBtnText = 'Done',
}: SignatureModalProps) {
	const [localImage, setLocalImage] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);

	const image = propsImage || localImage;
	const setImage = propsSetImage || setLocalImage;

	footer = footer || (
		<>
			<OutlineButton mr={3} onClick={isLoading ? undefined : onClose}>
				Close
			</OutlineButton>

			<ConfirmButton
				flex={1}
				isLoading={isLoading}
				onClick={() => {
					const result = onSubmit?.(image as string);
					if (result instanceof Promise) {
						setIsLoading(true);
						result.then(() => {
							onClose?.();
							setIsLoading(false);
						});
					} else {
						onClose?.();
					}
				}}
			>
				{submitBtnText}
			</ConfirmButton>
		</>
	);

	return (
		<Modal
			isOpen
			size={{ base: 'full', md: 'xl' }}
			onClose={
				isLoading
					? () => {
							//
					  }
					: onClose ||
					  (() => {
							//
					  })
			}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody
					_disabled={{ opacity: 0.5, pointerEvents: 'none' }}
					aria-disabled={isLoading}
					as={Center}
				>
					<SignaturePad value={image} onChange={setImage} />
				</ModalBody>

				<ModalFooter>{footer}</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

const modal = create(
	({
		isOpen,
		onReject,
		onResolve,
		onSubmit,
		submitBtnText,
		...rest
	}: InstanceProps<any, any> & SignatureModalProps) => {
		const [isLoading, setIsLoading] = useState(false);
		const handleSubmit = async (value: string) => {
			try {
				await onSubmit?.(value);
				onResolve?.('');
			} catch (error) {
				onReject?.('');
			}
		};
		const [image, setImage] = useState<string>();

		if (!isOpen) return <></>;

		const footer = (
			<>
				<OutlineButton mr={3} onClick={isLoading ? undefined : onReject}>
					Close
				</OutlineButton>

				<ConfirmButton
					flex={1}
					isDisabled={!image}
					isLoading={isLoading}
					onClick={() => {
						const result = onSubmit?.(image as string);
						if (result instanceof Promise) {
							setIsLoading(true);
							result.then(() => {
								onReject?.();
								setIsLoading(true);
							});
						}
					}}
				>
					{submitBtnText}
				</ConfirmButton>
			</>
		);

		return (
			<SignatureModal
				footer={footer}
				image={image}
				setImage={setImage}
				onClose={onReject}
				onSubmit={handleSubmit}
				{...rest}
			/>
		);
	}
);

export const openSignatureModal = async (props: Parameters<typeof modal>[0]) => {
	try {
		await modal(props);
	} catch {
		//
	}
};
