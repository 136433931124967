import { filter, forEach, isUndefined } from 'lodash-es';

import { downloadFile, isFile } from '@/utils';

import { httpClient } from './http-client';

const BASE_URL = '/api/nellie-notes';

export type CreateNoteParams = IsAssignedClientPolicy<{
	amender?: Clinician['id'];
	attachments?: File[];
	owner?: number;
	response?: ID;
	sections: NoteSection[];
	signatory?: Clinician['id'];
	status: NoteStatus;
	title: string;
	type: NoteType;
}>;

const createNote = ({ sections, attachments, ...note }: CreateNoteParams) => {
	const formData = new FormData();

	Object.entries(note).forEach(([key, value]) => {
		if (!isUndefined(value)) formData.append(key, value.toString());
	});
	forEach(attachments, (file) => {
		formData.append(`attachments`, file);
	});

	forEach(sections, (section, index) => {
		formData.append(`sections[${index}][title]`, section.title ?? '');
		formData.append(`sections[${index}][description]`, section.description ?? '');
		formData.append(`sections[${index}][order]`, (section.order || 0)?.toString());

		forEach(section.attachments, (file) => {
			formData.append(`sections[${index}][attachments]`, file);
		});
	});

	return httpClient.post<Note>(`${BASE_URL}/createNote`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export type UpdateNoteParams = { id: number } & Partial<CreateNoteParams>;

const updateNote = ({ sections, attachments, ...note }: UpdateNoteParams) => {
	const formData = new FormData();

	Object.entries(note).forEach(([key, value]) => {
		if (!isUndefined(value)) formData.append(key, value.toString());
	});

	forEach(attachments, (file) => {
		formData.append(`attachments`, file);
	});

	forEach(sections, (section, index) => {
		if (section.id) {
			formData.append(`sections[${index}][id]`, section.id);
		}
		if (section.title) {
			formData.append(`sections[${index}][title]`, section.title ?? '');
		}
		if (section.description) {
			formData.append(`sections[${index}][description]`, section.description ?? '');
		}
		if (!isUndefined(section.order)) {
			formData.append(`sections[${index}][order]`, section.order?.toString());
		}

		formData.append(`sections[${index}][type]`, section.type || 'section');

		forEach(filter(section.attachments, isFile), (file) => {
			formData.append(`sections[${index}][attachments]`, file);
		});
	});

	return httpClient.post<Note>(`${BASE_URL}/updateNote`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const archiveNote = (params: IsAssignedClientPolicy<{ id: number }>) => {
	return httpClient.post<Note>(`${BASE_URL}/archiveNote`, params);
};

const deleteNote = (params: IsAssignedClientPolicy<{ id: number }>) => {
	return httpClient.post<Note>(`${BASE_URL}/deleteNote`, params);
};

const getNote = (params: IsAssignedClientPolicy<{ id: number }>) =>
	httpClient.post<Note>(`${BASE_URL}/getNote`, params);

const getNotes = ({ clientId, searchQuery, type, clinicianId }) => {
	return httpClient.post<Note[]>(`${BASE_URL}/getNotes`, {
		client: clientId,
		clinician: clinicianId,
		...(searchQuery && { q: searchQuery }),
		...(type && { type }),
	});
};

const duplicateNote = (params: IsAssignedClientPolicy<{ id: number }>) => {
	return httpClient.post<Note>(`${BASE_URL}/duplicateNote`, params);
};

const exportNotePdf = async ({
	filename,
	...params
}: IsAssignedClientPolicy<{
	client?: ID;
	filename: string;
	id?: ID;
	status?: NoteStatus;
}>) => {
	const blob = await httpClient.post(`${BASE_URL}/exportNotePdf`, params, {
		responseType: 'blob',
	});

	downloadFile(new File([blob], filename + '.pdf'));

	return true;
};

const downloadNoteAttachment = async ({
	filename,
	...params
}: IsAssignedClientPolicy<{ filename: string; id: ID }>) => {
	const file = await httpClient.post<Blob>(
		`${BASE_URL}/downloadNoteAttachment`,
		{
			id: params.id,
			client: params.client,
			clinician: params.clinician,
		},
		{
			responseType: 'blob',
		}
	);
	return downloadFile(new File([file], filename));
};

type NoteUploadParams = {
	files: File[];
} & Record<string, any>;

const uploadAttachment = ({ files, ...payload }: NoteUploadParams) => {
	const formData = new FormData();
	files.forEach((file) => {
		formData.append('files', file);
	});

	Object.entries(payload).forEach(([key, value]) => {
		formData.append(key, value.toString());
	});

	return httpClient.post(`${BASE_URL}/upload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export type UpdateNoteSectionParams = {
	description?: string;
	id: any;
	title?: string;
};
const updateNoteSection = (params: IsAssignedClientPolicy<UpdateNoteSectionParams>) => {
	return httpClient.post<NoteSection>(`${BASE_URL}/updateNoteSection`, params);
};

const deleteNoteSection = (params: IsAssignedClientPolicy<{ id: ID }>) => {
	return httpClient.post<Note>(`${BASE_URL}/deleteNoteSection`, params);
};

export type CreateSectionParams = {
	description?: string;
	note: any;
	order?: number;
	title?: string;
};

const createNoteSection = (params: IsAssignedClientPolicy<CreateSectionParams>) => {
	return httpClient.post<NoteSection>(`${BASE_URL}/createNoteSection`, params);
};

const noteLog = (params: IsAssignedClientPolicy<{ id: ID }>) => {
	return httpClient.post<Pick<Note, 'histories'>>(`${BASE_URL}/noteLog`, params);
};

export const notesApi = {
	createNote,
	getNote,
	getNotes,
	updateNote,
	archiveNote,
	deleteNote,
	duplicateNote,
	exportNotePdf,
	downloadNoteAttachment,
	noteLog,
	uploadAttachment,
	updateNoteSection,
	deleteNoteSection,
	createNoteSection,
};
