import { memo } from 'react';

import {
	Box,
	Button,
	Checkbox,
	Input, // eslint-disable-next-line no-restricted-imports
	Select,
	Stack,
	Textarea,
	VStack,
	useColorModeValue as mode,
	useConst,
} from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { FastField, Field, FieldArray } from 'formik';
import { map } from 'lodash-es';

import { FormElementType } from '@/@types/enums';
import { AppField, DatePicker, Markdown } from '@/components/ui';

import { CustomSlider, CustomSliderWithText } from './CustomSlider';
import { InputWrapper, InputWrapperProps } from './InputWrapper';
import { List } from './List';
import { RadioHStack, RadioStandard, RadioVStack, RadioVStackWithText } from './RadioElements';
import { Signature } from './Signature';
import { Suds } from './Suds';
import { TextareaSplit } from './TextareaSplit';

export type FormElementProps = Partial<InputWrapperProps> & {
	isDisabled_?: boolean;
	saveOnBlur?: (values: unknown) => void;
	saveSpace?: boolean;
	thisElement: NellieFormElement;
};

const fontSize = ['18px'];
export const FormElement = memo<FormElementProps>((props) => {
	const {
		attribute,
		text,
		type,
		style = {},
		qid,
		responseLabels,
		fieldProps,
		fieldWrapperProps,
		inputWrapperProps,
	} = props.thisElement;

	const { isDisabled_, thisElement } = props;
	const colorTextInput = mode('gray.800', 'gray.100');

	// TODO maybe the color attr should only affect text input?
	const disableStyle: CSSObject = useConst({
		cursor: 'default',
		color: colorTextInput,
	});

	switch (type) {
		case FormElementType.date:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<AppField<Record<typeof qid, Date | null>> name={qid}>
						{({ field, form }) => (
							<DatePicker
								showMonthDropdown
								showYearDropdown
								disabled={isDisabled_}
								dropdownMode="select"
								selected={field.value ? new Date(field.value) : null}
								onBlur={field.onBlur}
								onChange={(date) => {
									form.setFieldValue(field.name, date, true);
								}}
							/>
						)}
					</AppField>
				</InputWrapper>
			);
		case FormElementType.br:
			return <br />;
		case FormElementType.checkbox:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<Stack direction={['column', 'row']} spacing={['8px', '30px']} {...fieldWrapperProps}>
						{responseLabels.map((option, index) => (
							<Field key={index} _disabled={disableStyle} isDisabled={isDisabled_} name={qid}>
								{({ field }) => (
									<Checkbox
										alignItems={style?.alignItems}
										defaultChecked={field.value?.includes(option)}
										disabled={isDisabled_}
										name={qid}
										textAlign={style?.textalign}
										value={option}
										onBlur={field.onBlur}
										onChange={field.onChange}
										{...fieldProps}
									>
										{option}
									</Checkbox>
								)}
							</Field>
						))}
					</Stack>
				</InputWrapper>
			);
		case FormElementType.fieldArray:
			return (
				<FieldArray
					name={qid}
					render={({ push, form, remove }) => {
						if (!form.values) {
							return (
								<Box
									border="1px solid"
									borderColor="gray.400"
									borderRadius="8px"
									position="relative"
									px="2"
									w="100%"
								>
									{responseLabels.map((option, index) => (
										<FormElement key={index} isDisabled_={isDisabled_} thisElement={option} />
									))}
								</Box>
							);
						}

						const optionSample: Record<string, string> = Object.keys(
							form?.values?.[qid]?.[0]
						).reduce((result, key) => {
							result[key] = '';
							return result;
						}, {});

						return (
							<VStack gap="10px" pb="20px">
								{form?.values?.[qid]?.map((options: Record<string, string>, index) => {
									return (
										<Box
											key={index}
											border="1px solid"
											borderColor="gray.400"
											borderRadius="8px"
											position="relative"
											px="2"
											w="100%"
										>
											{form?.values?.[qid]?.length > 1 && !isDisabled_ && (
												<Button
													_hover={{ color: 'gray.600' }}
													color="gray.400"
													position="absolute"
													right="10px"
													top={0}
													variant="unstyled"
													zIndex={10}
													onClick={() => remove(index)}
												>
													Remove
												</Button>
											)}
											{map(options, (value, name) => {
												const thisElement = responseLabels.find((e) => e.qId === name);
												const thisElementName = qid + '.' + index + '.' + name;

												return (
													<FormElement
														key={thisElementName}
														isDisabled_={isDisabled_}
														saveOnBlur={props.saveOnBlur}
														thisElement={{
															...thisElement,
															qid: thisElementName,
														}}
													/>
												);
											})}
										</Box>
									);
								})}
								{!isDisabled_ && (
									<Button type="button" onClick={() => push(optionSample)}>
										ADD ANOTHER
									</Button>
								)}
							</VStack>
						);
					}}
				/>
			);
		case FormElementType.hr:
			return <hr style={{ borderColor: style?.borderColor }} />;
		case FormElementType.image: {
			const imageStyle = {
				width: `${style.width}px`,
				height: `${style.height}px`,
			};
			return <img alt={style.alternateText} src={thisElement.source} style={imageStyle} />;
		}
		case FormElementType.list:
			return (
				<List fontSize={fontSize} items={responseLabels} maxColumns={style?.maxColumns || 1} />
			);
		case FormElementType.plaintext:
			return (
				<Box
					// TODO fontsize should automatically match radio fontsize somehow
					fontSize={style.fontsize || fontSize}
					fontWeight={style.fontweight}
					pl={style.paddingLeft}
					pt={style.paddingTop}
					textAlign={style.textalign}
					{...fieldProps}
				>
					<Markdown>{text}</Markdown>
				</Box>
			);
		case FormElementType.radio:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<RadioStandard
								disableStyle={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								thisElement={thisElement}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.radioHStack:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<RadioHStack
								disableStyle={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								thisElement={thisElement}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.radioVStack:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<RadioVStack
								disableStyle={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								thisElement={thisElement}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.radioVStackWithText:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<RadioVStackWithText
								disableStyle={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								thisElement={thisElement}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.select:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<Select
								_disabled={disableStyle}
								borderColor="gray.200"
								isDisabled={isDisabled_}
								placeholder="Select option"
								{...field}
							>
								{responseLabels?.map((option, index) => (
									<option key={option} value={`${index + 1}`}>
										{option}
									</option>
								))}
							</Select>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.signature:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<Field
						comp_disabled={disableStyle}
						component={({ field, form }) => {
							return (
								<Signature
									value={field.value}
									onChange={
										isDisabled_
											? undefined
											: (value) => {
													form.setFieldTouched(field.name, true);
													form.setFieldValue(field.name, value);
											  }
									}
								/>
							);
						}}
						isDisabled={isDisabled_}
						name={qid}
					/>
				</InputWrapper>
			);
		case FormElementType.slider:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<CustomSlider
								_disabled={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								saveOnBlur={props.saveOnBlur}
								sliderOptions={thisElement.sliderOptions as SliderOptions}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.sliderWithText:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<CustomSliderWithText
								_disabled={disableStyle}
								field={field}
								isDisabled_={isDisabled_}
								saveOnBlur={props.saveOnBlur}
								sliderOptions={thisElement.sliderOptions as SliderOptions}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.suds:
			return <Suds {...props} />;
		case FormElementType.text:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<Input
								_disabled={disableStyle}
								borderColor="gray.400"
								isDisabled={isDisabled_}
								maxLength={attribute?.maxLength || 2000}
								{...fieldProps}
								{...field}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.textarea:
			return (
				<InputWrapper {...props} {...inputWrapperProps}>
					<FastField name={qid}>
						{({ field }) => (
							<Textarea
								_disabled={disableStyle}
								borderColor="gray.400"
								isDisabled={isDisabled_}
								maxLength={attribute?.maxLength || 2000}
								resize={style?.textareaResize}
								rows={style?.rows}
								{...fieldProps}
								{...field}
							/>
						)}
					</FastField>
				</InputWrapper>
			);
		case FormElementType.textareasplit:
			return <TextareaSplit _disabled={disableStyle} {...props} />;
		default:
			return <div>Unsupported field</div>;
	}
});
