import { memo } from 'react';

import { Flex, Radio, RadioGroup, Stack, Text, VStack } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { FieldInputProps } from 'formik';

type RadioElementProps = {
	disableStyle: CSSObject;
	field: FieldInputProps<any>;
	isDisabled_?: boolean;
	thisElement: NellieFormElement;
};

const vstackRadioStyles = { marginInlineStart: '0.5rem' };

export const RadioStandard = memo<RadioElementProps>(
	({
		disableStyle,
		field: { onChange, ...rest },
		isDisabled_,
		thisElement: { scoring, scoreStart, style = {}, responseLabels, fieldProps, fieldWrapperProps },
	}) => {
		return (
			<RadioGroup isDisabled={isDisabled_} {...rest}>
				<Stack
					direction={style.stackDirection || ['column', 'column', 'column', 'row']}
					justifyContent={style.justifyContent || ['center']}
					mt={5}
					px={3}
					spacing={style.spacing || ['8px', '8px', '8px', '26px']}
					{...fieldWrapperProps}
				>
					{responseLabels.map((option, index) => (
						<Radio
							key={index}
							_disabled={disableStyle}
							_invalid={{
								all: 'none',
							}}
							size="lg"
							value={
								scoring === 'none'
									? 'noscore' + String(index)
									: scoring === 'reverse'
									? `${scoreStart - index}`
									: `${index + scoreStart}`
							}
							onChange={onChange}
							{...fieldProps}
						>
							{option}
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		);
	}
);

export const RadioHStack = memo<RadioElementProps>(
	({
		disableStyle,
		field: { onChange, ...rest },
		isDisabled_,
		thisElement: { scoring, scoreStart, responseLabels },
	}) => {
		return (
			<RadioGroup isDisabled={isDisabled_} {...rest}>
				<VStack
					alignItems="flex-start"
					gap={['8px', '8px', '8px', '26px']}
					mt={5}
					px={3}
					textAlign="left"
				>
					{responseLabels.map((option, index) => (
						<Radio
							key={index}
							_disabled={disableStyle}
							_invalid={{
								all: 'none',
							}}
							alignItems="start"
							size="lg"
							style={{ marginTop: 5 }}
							value={
								scoring === 'none'
									? 'noscore' + String(index)
									: scoring === 'reverse'
									? `${scoreStart - index}`
									: `${index + scoreStart}`
							}
							onChange={onChange}
						>
							{option}
						</Radio>
					))}
				</VStack>
			</RadioGroup>
		);
	}
);

export const RadioVStack = memo<RadioElementProps>(
	({
		disableStyle,
		field: { onChange, ...rest },
		isDisabled_,
		thisElement: { scoring, scoreStart, style = {}, responseLabels },
	}) => {
		return (
			<RadioGroup isDisabled={isDisabled_} {...rest} mx="auto">
				<Stack
					direction={['column', 'column', 'column', 'row']}
					gap={style.gap || [3]}
					justifyContent="center"
					pl={{ base: 10, lg: 0 }}
					spacing={style.spacing || ['8px', '8px', '8px', '26px']}
				>
					{responseLabels.map((option, index) => (
						<Radio
							key={index}
							_disabled={disableStyle}
							_invalid={{
								all: 'none',
							}}
							flexDirection={['row', 'row', 'row', 'column']}
							gap={2}
							size="lg"
							style={vstackRadioStyles}
							value={
								scoring === 'none'
									? 'noscore' + String(index)
									: scoring === 'reverse'
									? `${scoreStart - index}`
									: `${index + scoreStart}`
							}
							onChange={onChange}
						>
							{option}
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		);
	}
);

export const RadioVStackWithText = memo<RadioElementProps>(
	({
		disableStyle,
		field: { onChange, ...rest },
		isDisabled_,
		thisElement: { scoring, scoreStart, style, responseLabels, radioOptions },
	}) => {
		const { textLeft, textRight, fontSize } = radioOptions as RadioOptions;

		return (
			<Flex
				alignItems="center"
				flexDirection={['column', 'column', 'column', 'row']}
				flexWrap="nowrap"
				justifyContent="center"
				w="full"
			>
				<VStack
					alignItems={['center', 'center', 'center', 'flex-end']}
					flexShrink={1}
					mr={[0, 0, 0, 5]}
				>
					{textLeft?.map((el, i) => (
						<Text
							key={i}
							fontSize={fontSize}
							minW={{ md: '120px' }}
							textAlign={['center', 'center', 'center', 'right']}
						>
							{el}
						</Text>
					))}
				</VStack>
				<RadioGroup isDisabled={isDisabled_} {...rest}>
					<Stack
						direction={['column', 'column', 'column', 'row']}
						gap={style.gap || [3]}
						px={3}
						spacing={style.spacing || ['8px', '8px', '8px', '26px']}
					>
						{responseLabels.map((option, index) => (
							<Radio
								key={index}
								_disabled={disableStyle}
								_invalid={{
									all: 'none',
								}}
								flexDirection={['row', 'row', 'row', 'column']}
								gap={2}
								size="lg"
								style={vstackRadioStyles}
								value={
									scoring === 'none'
										? 'noscore' + String(index)
										: scoring === 'reverse'
										? `${scoreStart - index}`
										: `${index + scoreStart}`
								}
								onChange={onChange}
							>
								{option}{' '}
							</Radio>
						))}
					</Stack>
				</RadioGroup>
				<VStack
					alignItems={['center', 'center', 'center', 'flex-start']}
					flexShrink={1}
					ml={[0, 0, 0, 5]}
				>
					{textRight?.map((el, i) => (
						<Text
							key={i}
							fontSize={fontSize}
							minW={{ md: '120px' }}
							textAlign={['center', 'center', 'center', 'left']}
						>
							{el}
						</Text>
					))}
				</VStack>
			</Flex>
		);
	}
);
