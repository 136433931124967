import { AxiosRequestConfig } from 'axios';

import { axiosClient, axiosToHttpAdapter } from './axiosClient';

interface HttpService {
	delete: <T = any>(url: string, config?: AxiosRequestConfig<T>) => Promise<T>;
	get: <T = any>(url: string, config?: AxiosRequestConfig<T>) => Promise<T>;
	post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig<T>) => Promise<T>;
	put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig<T>) => Promise<T>;
}

export interface HttpServiceError {
	data: any;
	message: string;
	status?: number;
}

export const httpClient: HttpService = {
	delete: axiosToHttpAdapter(axiosClient.delete),
	get: axiosToHttpAdapter(axiosClient.get),
	post: axiosToHttpAdapter(axiosClient.post),
	put: axiosToHttpAdapter(axiosClient.put),
};
