import { Suspense } from 'react';

import { ErrorBoundary } from '@/components';
import { lazy } from '@/utils/react-wrappers';

import type { LineChartProps } from './LineChartUI';

export type { LineChartProps } from './LineChartUI';

const LineChartUI = lazy(() => import('./LineChartUI'));

export const LineChart = (props: LineChartProps) => (
	<ErrorBoundary fallback={<></>}>
		<Suspense>
			<LineChartUI {...props} />
		</Suspense>
	</ErrorBoundary>
);
