import { useState } from 'react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
	Button,
	ButtonProps,
	Card,
	CardBody,
	CardHeader,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';

import { OutlineButton } from '@/components/ui';
import { formatDate } from '@/utils';

import { NoteItem } from '../Notes/components/NoteItem/NoteItem';

type ViewNotesButtonProps = Partial<ButtonProps> & {
	notes: Note[];
};

export function ViewNotesButton({ notes, ...buttonProps }: ViewNotesButtonProps) {
	const notesHistoryModal = useDisclosure();
	const [selectedNote, setSelectedNote] = useState<Note>();

	const closeModal = () => {
		notesHistoryModal.onClose();
		setSelectedNote(undefined);
	};

	return (
		<>
			<OutlineButton ml={2} onClick={notesHistoryModal.onOpen} {...buttonProps}>
				View Notes
			</OutlineButton>

			<Modal isOpen={notesHistoryModal.isOpen} size="2xl" onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Notes</ModalHeader>
					<ModalCloseButton />
					<ModalBody as={VStack} p={selectedNote ? 0 : undefined}>
						{selectedNote ? (
							<Card boxShadow="none" m="0" p="0" w="100%">
								<NoteItem
									header={
										<CardHeader as={HStack} pb="0">
											<ChevronLeftIcon
												cursor="pointer"
												fontSize="3xl"
												onClick={() => setSelectedNote(undefined)}
											/>
											<Text fontSize="xl" fontWeight="semibold">
												{selectedNote.title}
											</Text>
										</CardHeader>
									}
									isUpload={false}
									values={selectedNote}
								/>
							</Card>
						) : (
							notes.map((note) => (
								<Card
									key={note.id}
									_hover={{ bg: 'brand.100', color: 'white' }}
									cursor="pointer"
									w="100%"
									onClick={() => setSelectedNote(note)}
								>
									<CardBody pb="3">
										<Text fontWeight="semibold">{note.title}</Text>

										<Text color="gray.400" fontSize="sm" fontWeight="light" textAlign="end">
											{formatDate(note.createdAt)}
										</Text>
									</CardBody>
								</Card>
							))
						)}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={closeModal}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
