import * as yup from 'yup';

import { getYupSchemaFromMetaData } from './yupSchemaCreator';

export const generateFormikRequirements = (questions) => {
	const newQuestionsList = generateNewQuestionsList(questions);

	const initialValues = {};
	for (const q of newQuestionsList) {
		if (q.type === 'fieldArray') {
			initialValues[q.qid] = [
				q.responseLabels.reduce((result, option) => {
					result[option.qId] = '';
					return result;
				}, {}),
			];
		} else {
			initialValues[q.qid] = '';
		}
	}
	return { initialValues, formSchema: getYupSchemaFromMetaData(newQuestionsList, [], []) };
};

export const generateNewQuestionsList = (questions) => {
	const newQuestionsList = [];
	for (const q of questions) {
		if (q.formikRequired) {
			if (q.type === 'textareasplit') {
				q.responseLabels.map((_, index) => {
					const newQid = `${q.qid}-${index}`;
					const questionObject = { ...q, qid: newQid };
					newQuestionsList.push(questionObject);
				});
			} else {
				newQuestionsList.push(q);
			}
		}
	}
	return newQuestionsList;
};

export const generateFormikRequirementsAlt = (questions) => {
	const newQuestionsList = generateNewQuestionsList(questions);

	const initialValues = {},
		schemaObj = {};
	for (const q of newQuestionsList) {
		initialValues[q.qid] = '';
		schemaObj[q.qid] = yup.string().required(q.validations[0].params[0]);
	}

	return [initialValues, yup.object(schemaObj)];
};

/**@deprecated */
export const generateFormikRequirementsNoteInput = (list) => {
	const initialValues = {},
		schemaObj = {};
	for (const l of list) {
		initialValues[`T${l.id}`] = '';
		schemaObj[`T${l.id}`] = yup.string().required('Required');
	}

	return [initialValues, yup.object(schemaObj)];
};
