import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	Box,
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';

import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';
import { GlobalContext } from '@/context';
import { formatDate, getFullName } from '@/utils';

const noteHistoryActionsMap: Record<NoteHistory['action'], string> = {
	'created-and-signed': 'Created and signed',
	created: 'Created',
	amended: 'Amended',
	archived: 'Archived',
	signed: 'Signed',
};

export const SignaturesLogButton = () => {
	const { getValues } = useFormContext<Note>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { loggedInUser, client, trainee } = useContext(GlobalContext);

	const { data, isLoading, isRefetching } = useAppQuery(
		['notes', getValues('id'), 'noteLog'],
		() =>
			Api.notes.noteLog({
				id: getValues('id'),
				client: (client as NellieUser).id,
				clinician: trainee?.id || loggedInUser.id,
			}),
		{
			enabled: isOpen,
		}
	);

	const skeleton = (
		<>
			<Box w="100%">
				<Text as={Skeleton} fontSize={30} height="31px" my="7px" w="60%">
					?
				</Text>
				<Text as={Skeleton} fontSize={20} w="50%">
					?
				</Text>
			</Box>
			<Box w="100%">
				<Text as={Skeleton} fontSize={30} height="31px" my="7px" w="60%">
					?
				</Text>
				<Text as={Skeleton} fontSize={20} w="50%">
					?
				</Text>
			</Box>
		</>
	);

	return (
		<>
			{isOpen && (
				<Modal isOpen onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Details</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<VStack align="start" maxH={600} minH={200} overflow="auto" spacing="15px">
								{isLoading || isRefetching
									? skeleton
									: data?.histories.map(({ id, action, clinician, createdAt }) => (
											<Box key={id}>
												<Text color="gray.600" fontSize={30} fontWeight="bold">
													{getFullName(clinician)}
												</Text>
												<HStack color="gray.400">
													<Text fontSize={20} fontWeight="semibold">
														{noteHistoryActionsMap[action]}:{' '}
													</Text>
													<Text>{formatDate(createdAt, 'MMM D, YYYY h:mmA')}</Text>
												</HStack>
											</Box>
									  ))}
							</VStack>
						</ModalBody>
						<ModalFooter />
					</ModalContent>
				</Modal>
			)}
			<Button
				_hover={{ color: 'gray.600', textDecoration: 'underline' }}
				color="gray.400"
				fontSize="sm"
				textDecoration="underline"
				variant="link"
				onClick={onOpen}
			>
				Details
			</Button>
		</>
	);
};
