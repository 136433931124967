import { useContext, useMemo } from 'react';

import {
	Button,
	Card,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { Api } from '@/api';
import { useAppMutation } from '@/app-react-query';
import { GlobalContext } from '@/context';
import { formatDate } from '@/utils';
import auth from '@/utils/auth';

import { NoteItem, NoteItemFormValues, newSection } from './NoteItem/NoteItem';

function CreateNoteModal({ onSuccess, responseId }) {
	const { loggedInUser, trainee, client: focusedClient } = useContext(GlobalContext);
	const clinician = trainee || loggedInUser;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const createNote = useAppMutation(
		async (values: NoteItemFormValues) => {
			await Api.notes.createNote({
				sections: values.sections as NoteSection[],
				title: values.title,
				type: 'Assignment',
				clinician: clinician?.id,
				owner: auth.getUserInfo()?.id,
				client: (focusedClient as NellieUser).id,
				status: 'Published',
				response: responseId,
			});

			return onSuccess?.();
		},
		{
			successMessage: true,
			errorMessage: true,
			onSettled: () => {
				onClose();
			},
		}
	);

	const formValues = useMemo(
		() =>
			({
				// loggedInUser can be a supervisor or a clinician
				title: `${formatDate(new Date())} `,
				type: 'Assignment',
				clinician: clinician?.id,
				sections: [newSection],
				client: (focusedClient as NellieUser)?.id,
				status: 'Draft',
			} as unknown),
		[clinician, focusedClient]
	);

	return (
		<>
			<Button
				colorScheme="yellow"
				ml={1}
				size="md"
				variant="outline"
				onClick={() => {
					onOpen();
				}}
			>
				Add Note
			</Button>

			<Modal
				closeOnOverlayClick={false}
				isOpen={createNote.isLoading ? true : isOpen}
				size={{ base: 'full', lg: '3xl' }}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader pb="0">
						<Text fontWeight="light" mt={2}>
							Add notes to this Assignment.
						</Text>
						<Text fontWeight="light">
							Notes entered here are{' '}
							<Text as="span" fontWeight="bold">
								visible to your client
							</Text>{' '}
							and should{' '}
							<Text as="span" fontWeight="bold">
								not be included{' '}
							</Text>
							<Text as="span" fontWeight="light">
								as part of the medical record.
							</Text>
						</Text>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody as={Card}>
						<NoteItem
							canAddAttachment={false}
							createButton={(handleSubmit) => (
								<Button
									_active={{}}
									_hover={{}}
									bg="#1D1671"
									color="white"
									isLoading={createNote.isLoading}
									onClick={handleSubmit((values) => createNote.mutateAsync(values))}
								>
									Save
								</Button>
							)}
							isReadOnly={createNote.isLoading}
							isUpload={false}
							values={formValues as Note}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CreateNoteModal;
