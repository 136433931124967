import { ReactNode, memo } from 'react';

import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';

export type FieldWrapperProps<T extends Record<string, any>> = T & {
	helperText?: ReactNode;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	isRequired?: boolean;
	label?: ReactNode;
};

type FieldWrapperInnerProps = FieldWrapperProps<{
	children: ReactNode;
	errorMessage?: string;
}>;

const isJSXElement = (node: ReactNode): node is React.ReactElement => {
	return typeof node === 'object' && node !== null && 'type' in node;
};

export const FieldWrapper = memo<FieldWrapperInnerProps>(
	({ isDisabled, isReadOnly, isRequired, errorMessage, label, helperText, children }) => {
		return (
			<FormControl
				isDisabled={isDisabled}
				isInvalid={Boolean(errorMessage)}
				isReadOnly={isReadOnly}
				isRequired={isRequired}
				role="group"
			>
				{label && (isJSXElement(label) ? label : <FormLabel maxW="fit-content">{label}</FormLabel>)}
				{children}
				{!errorMessage && helperText && (
					<FormHelperText alignSelf="start" color="gray.500" textAlign="start">
						{helperText}
					</FormHelperText>
				)}
				<FormErrorMessage fontWeight="semibold">{errorMessage}</FormErrorMessage>
			</FormControl>
		);
	}
);

FieldWrapper.displayName = 'FieldWrapper';
