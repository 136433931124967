import { Field, FieldConfig, FieldProps } from 'formik';

export type AppFieldProps<T = any> = Omit<FieldConfig<T>, 'name' | 'render' | 'children'> & {
	children?: ((props: FieldProps<T[keyof T], T>) => React.ReactNode) | React.ReactNode;
	name: keyof T;
	render?: (props: FieldProps<ValueOf<T>, T>) => React.ReactNode;
};

/**
 * It's a wrapper around the Field component from Formik that adds some type safety
 * @param props - AppFieldProps<T>
 * @returns <Field {...props} />
 * @example ```tsx
 *  // this will throw an error because the name prop is not a valid key of the type T
 * 	<AppField<{email: string}> name='emaail'>
 * 		{({field, form}) => ()}
 * 	</AppField>
 * ```
 */
export function AppField<T>(props: AppFieldProps<T>) {
	return <Field {...props} />;
}
