import { Input, InputProps, Text } from '@chakra-ui/react';

import { EditableField } from '@/components/ui/EditableField';

type SettingWithInputProps<
	T extends Record<string, any>,
	Props extends { isDisabled?: boolean; name?: string } = InputProps
> = Props & {
	Input?: React.FC<Props>;
	helperText?: string;
	maxW?: InputProps['maxW'];
	name: keyof T;
	renderValue?: (value: T[keyof T]) => any;
	title: string;
};

export function SettingWithInput<
	T extends Record<string, any>,
	Props extends { isDisabled?: boolean; name?: string } = InputProps
>({
	maxW = { md: '100%', base: '2xl' },
	name,
	title,
	helperText,
	renderValue,
	isDisabled,
	...props
}: SettingWithInputProps<T, Props>) {
	const Component = props.Input || (Input as any);

	return (
		<>
			<EditableField<T>
				isDisabled={isDisabled}
				label={title}
				maxW={maxW}
				name={name}
				renderValue={renderValue}
			>
				{({ field }) => (
					<>
						<Component {...field} {...props} maxW={maxW} />
						{helperText && (
							<Text color="gray.500" fontSize="sm" maxW={maxW} mt={2} textAlign="start">
								{helperText}
							</Text>
						)}
					</>
				)}
			</EditableField>
		</>
	);
}
