import { useState } from 'react';

import {
	Slider as ChakraSlider,
	SliderFilledTrack,
	SliderMark,
	SliderProps,
	SliderThumb,
	SliderTrack,
	Tooltip,
} from '@chakra-ui/react';

import { useNellieController } from '../hooks/use-nellie-controller';
import { FormFieldProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export function Slider<AcceptedNames extends string>({
	name,
	label,
	isDisabled,
	isRequired,
	isReadOnly,
	helperText,
	shouldUnregister,
	...props
}: FormFieldProps<SliderProps, AcceptedNames>) {
	const { field, fieldState } = useNellieController({
		name,
		shouldUnregister,
	});

	// const [sliderValue, setSliderValue] = useState(5);
	const [showTooltip, setShowTooltip] = useState(false);
	return (
		<FieldWrapper
			errorMessage={fieldState.error?.message}
			helperText={helperText}
			isDisabled={isDisabled}
			isReadOnly={isReadOnly}
			isRequired={isRequired}
			label={label}
		>
			<ChakraSlider
				colorScheme="purple"
				id="slider"
				max={100}
				min={0}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
				{...props}
				{...field}
				value={field.value || 0}
			>
				<SliderMark fontSize="sm" ml="-2.5" mt="1" value={25}>
					25%
				</SliderMark>
				<SliderMark fontSize="sm" ml="-2.5" mt="1" value={50} onClick={console.log}>
					50%
				</SliderMark>
				<SliderMark fontSize="sm" ml="-2.5" mt="1" value={75}>
					75%
				</SliderMark>
				<SliderTrack>
					<SliderFilledTrack />
				</SliderTrack>
				<Tooltip
					hasArrow
					bg="purple.500"
					color="white"
					isOpen={showTooltip}
					label={`${field.value}%`}
					placement="top"
				>
					<SliderThumb bg="gray.500" />
				</Tooltip>
			</ChakraSlider>
		</FieldWrapper>
	);
}
