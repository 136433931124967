import { useUnmountEffect } from '@chakra-ui/react';

import { Api } from '@/api';
import { GetClientFormReviewParams, GetFormReviewParams } from '@/api/forms';
import { useAppQuery } from '@/app-react-query';
import { useGetForm } from '@/hooks';
import { formatDate, generateFormikRequirements, getFormTitleByRole } from '@/utils';

export type UseFormProviderProps = {
	client_id?: ID;
	isDisabled_?: boolean;
	response_id: ID;
};

type FormProviderData = {
	autoSave?: boolean;
	formBuilder: FormBuilder;
	formikBuilder: ReturnType<typeof generateFormikRequirements>;
};

export const useFormProvider = (
	form_id: Maybe<ID>,
	{ isDisabled_ = false, client_id, response_id }: UseFormProviderProps
) => {
	const {
		loading: formGetIsLoading,
		error: formGetError,
		data: formData,
	} = useGetForm(form_id, {
		enabled: Boolean(form_id),
		staleTime: Infinity,
		select: (data): FormProviderData => {
			const title = getFormTitleByRole(data.attributes);
			return {
				autoSave: data.attributes.autoSave,
				formBuilder: {
					form_id: data.id,
					form_data: data.attributes.form_data,
					form_title: title,
					form_instructions: data.attributes.form_instructions,
					form_type: data.attributes.type,
					isDisabled_,
				},

				formikBuilder: generateFormikRequirements(data.attributes.form_data.elements),
			};
		},
	});

	const {
		isLoading: formGetReviewIsLoading,
		isFetching: formGetReviewIsFetching,
		error: formGetReviewError,
		data: formReview,
		isRefetching: formGetReviewIsRefetching,
		remove: formGetReviewRemove,
	} = useAppQuery(
		[
			client_id ? 'clientFormReview' : 'formReview',
			{ form_id, response_id, client_id: client_id || undefined },
		],
		() => {
			const params: GetFormReviewParams = {
				response_id,
			};

			if (client_id) {
				return Api.forms.getClientFormReview({ ...params, client_id } as GetClientFormReviewParams);
			}

			return Api.forms.getFormReview(params);
		},
		{
			refetchOnMount: true,
			enabled: Boolean(!form_id),
			select: (response): FormProviderData => {
				const { formSchema } = generateFormikRequirements(response.nellie_form.form_data.elements);
				const title = getFormTitleByRole(response.nellie_form);
				return {
					autoSave: response?.nellie_form?.autoSave,
					formBuilder: {
						form_id: response.nellie_form.id,
						form_data: response.nellie_form.form_data,
						form_title: title,
						form_instructions: response.nellie_form.form_instructions,
						form_type: response.nellie_form.type,
						createdAt: formatDate(response.createdAt),
						isDisabled_,
					},
					formikBuilder: { initialValues: response.response_data, formSchema },
				};
			},
		}
	);

	useUnmountEffect(() => {
		formGetReviewRemove();
	}, []);

	return {
		data: form_id ? formData : formReview,
		loading: form_id
			? formGetIsLoading
			: // do not show loading when refetching
			formGetReviewIsRefetching
			? false
			: formGetReviewIsLoading || formGetReviewIsFetching,
		error: form_id ? formGetError : formGetReviewError,
	};
};
