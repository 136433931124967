import { memo, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useUnmountEffect } from '@chakra-ui/react';
import { isEmpty, merge, omit } from 'lodash-es';

import { Api } from '@/api';
import { GetClientFormReviewParams, GetFormReviewParams } from '@/api/forms';
import { useAppMutation, useAppQuery } from '@/app-react-query';
import { LoadingContainer } from '@/components/ui';
import { ProgressStatus } from '@/containers/FormProvider';
import { useGetForm, useLatest } from '@/hooks';
import { formatDate } from '@/utils';
import auth from '@/utils/auth';

import { FormIsCompleted } from '../form-elements';
import { SubmitButton } from '../form-elements/SubmitButton';

import { FormEngineV2 } from './FormEngineV2';

type Props = {
	assignment_id?: ID;
	form_id?: ID;
	response_id?: ID;
};

const FormEngineV2Container = ({
	form_id: propsFormId,
	assignment_id: propsAssignmentId,
	response_id: propsResponseId,
}: Props) => {
	const state: Props = useLocation().state;
	const assignmentId = propsAssignmentId || state?.assignment_id;
	const [formId, setFormId] = useState(propsFormId || state?.form_id);
	// store response_id in order to resume form
	// after saveFormResponse mutation
	// saveFormResponse will create or update a response
	const responseId = useRef(Number(propsResponseId || state?.response_id));

	const client_id = auth.getClientID();

	const { data: assignmentDefaultValues, isLoading: assignmentDefaultValuesIsLoading } =
		useAppQuery(
			['getAssignmentDefaultValues', (assignmentId as number)?.toString()],
			() => Api.assignments.getAssignmentDefaultValues(assignmentId as number),
			{
				enabled: Boolean(assignmentId),
			}
		);

	const {
		loading: formGetIsLoading,
		error: formGetError,
		data: formData,
	} = useGetForm(formId, {
		enabled: Boolean(formId),
		staleTime: Infinity,
		select: (data) => ({
			autoSave: data.attributes.autoSave,
			formBuilder: {
				form_id: data.id,
				form_data: data.attributes.form_data,
				form_type: data.attributes.type,
			},
		}),
	});

	const {
		isLoading: formGetReviewIsLoading,
		error: formGetReviewError,
		data: formReview,
		remove: removeFormReview,
	} = useAppQuery(
		[
			client_id ? 'clientFormReview' : 'formReview',
			{ response_id: responseId.current, client_id: client_id || undefined },
		],
		() => {
			const params: GetFormReviewParams = {
				response_id: responseId.current as number,
			};

			if (client_id) {
				return Api.forms.getClientFormReview({ ...params, client_id } as GetClientFormReviewParams);
			}

			return Api.forms.getFormReview(params);
		},
		{
			refetchOnMount: true,
			enabled: Boolean(!formId),
			select: (response) => ({
				autoSave: response.nellie_form.autoSave,
				formBuilder: {
					form_id: response.nellie_form.id,
					form_data: response.nellie_form.form_data,
					form_type: response.nellie_form.type,
					createdAt: formatDate(response.createdAt),
				},
				defaultValues: response.response_data,
			}),
		}
	);

	const formReviewRef = useLatest(formReview);
	useUnmountEffect(() => {
		if (formReviewRef.current?.autoSave) {
			removeFormReview();
		}
	});

	const isLoading = formGetIsLoading || formGetReviewIsLoading;
	const error = formGetError || formGetReviewError;
	const data = formData || formReview;

	const { mutateAsync: saveFormResponse } = useAppMutation(
		async ({ values, status = ProgressStatus.completed }: any) => {
			if (!data?.formBuilder || isLoading || isEmpty(values)) return;

			const id = await Api.forms.saveFormResponse({
				assignment_id: assignmentId as number,
				form_id: data.formBuilder?.form_id,
				isScored: data.formBuilder?.form_data?.formHeader?.isScored,
				response_data: omit(values, 'score'),
				score: values.score,
				response_id: responseId.current as number,
				status,
				type: data.formBuilder?.form_type,
			});

			responseId.current = id;
			return id;
		},
		{
			errorMessage: true,
		}
	);

	const saveOnBlur = useCallback(
		async (values: Record<string, any>) => {
			if (data?.autoSave) {
				return saveFormResponse({ values, status: ProgressStatus.inProgress });
			}
		},
		[data?.autoSave, saveFormResponse]
	);

	const saveOnSubmit = useCallback(
		async (values: Record<string, any>): Promise<FormIsCompleted> => {
			await saveFormResponse({ values, status: ProgressStatus.completed });
			if (data?.formBuilder?.form_data?.next_form) {
				setFormId(data?.formBuilder?.form_data?.next_form);

				return false;
			}
			return true;
		},
		[data?.formBuilder?.form_data?.next_form, saveFormResponse]
	);

	return (
		<LoadingContainer error={error} isLoading={isLoading || assignmentDefaultValuesIsLoading}>
			<FormEngineV2
				Component={data?.formBuilder?.form_data?.Component as string}
				defaultValues={merge(formReview?.defaultValues, assignmentDefaultValues)}
				footer={
					data?.formBuilder?.form_data?.next_form ? (
						<SubmitButton>Continue</SubmitButton>
					) : undefined
				}
				nextFormId={data?.formBuilder?.form_data?.next_form}
				responseId={responseId.current}
				saveOnBlur={saveOnBlur}
				saveOnSubmit={saveOnSubmit}
			/>
		</LoadingContainer>
	);
};

export default memo(FormEngineV2Container);
