import { memo, useCallback, useRef } from 'react';
import type ReactSignatureCanvas from 'react-signature-canvas';

import { Button, VStack } from '@chakra-ui/react';

import { useMountEffect } from '@/hooks';
import { lazy } from '@/utils/react-wrappers';

const OriginalSignaturePad = lazy(() => import('react-signature-canvas'));

export type SignaturePadProps = {
	onChange: (base64Signiture: string) => unknown;
	size?: number;
	value?: string;
};

export const SIGNATURE_PAD_DEFAULT_SIZE = 500;

const SignaturePad = memo<SignaturePadProps>(
	({ onChange, value, size = SIGNATURE_PAD_DEFAULT_SIZE }) => {
		const sigCanvas = useRef<ReactSignatureCanvas>({} as ReactSignatureCanvas);

		useMountEffect(() => {
			if (value) sigCanvas.current.fromDataURL(value);
		});

		const formatIntoPng = useCallback(() => {
			if (sigCanvas.current) {
				const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('data:image/png');

				onChange(dataURL);
			}
		}, [onChange]);

		const onClear = useCallback(() => {
			sigCanvas.current.clear();

			onChange('');
		}, [onChange]);

		return (
			<VStack w="fit-content">
				<OriginalSignaturePad
					ref={sigCanvas}
					canvasProps={{
						width: size,
						height: (size / 16) * 9,
						style: {
							border: '0.5px dotted black',
							cursor: 'crosshair',
						},
					}}
					penColor="navy"
					onEnd={formatIntoPng}
				/>
				<Button
					_active={{ bg: 'transparent' }}
					_hover={{ bg: 'transparent', color: 'black' }}
					alignSelf="end"
					color="#718096"
					fontSize="sm"
					size="sm"
					variant="ghost"
					onClick={onClear}
				>
					Clear
				</Button>
			</VStack>
		);
	}
);

export default SignaturePad;
