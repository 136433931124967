import { Suspense, forwardRef } from 'react';

import { Input } from '@chakra-ui/react';

import { lazy } from '@/utils/react-wrappers';

import type { PhoneInputProps } from './PhoneInputUI';

export type { PhoneInputProps } from './PhoneInputUI';

const PhoneInputUI = lazy(() => import('./PhoneInputUI'));

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => (
	<Suspense fallback={<Input />}>
		<PhoneInputUI {...props} ref={ref} />
	</Suspense>
));
