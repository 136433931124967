import { createContext, useContext } from 'react';

import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';

import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';
import { LoadingContainer } from '@/components/ui';
import { isFormEngineV2 } from '@/forms/core/FormEngineV2';
import FormEngineV2Container from '@/forms/core/FormEngineV2Container';
import { formIsCbwById } from '@/utils';

import CreateNoteModal from '../Notes/components/CreateNoteModal';

import FormProvider, { FormProviderProps } from './';
import { ViewNotesButton } from './ViewNotesButton';

const FormReviewModalContext = createContext(false);
/** returns true if `FormReviewModal` is open */
export const useIsFormReviewModalOpen = () => useContext(FormReviewModalContext);

export type ModalInfoProps = FormProviderProps & {
	Component?: string;
	date?: string;
	isDisabled_?: boolean;
	score?: number;
	version?: number;
};

type FormModalProps = {
	isOpen: boolean;
	modalInfo: ModalInfoProps;
	onClose: VoidFunction;
};

export default function FormModal({ modalInfo, onClose, isOpen }: FormModalProps) {
	const notesQuery = useAppQuery(
		['Api.forms.getResponseNotes', { response_id: modalInfo?.response_id }],
		() => Api.forms.getResponseNotes(modalInfo?.response_id as number),
		{
			enabled: Boolean(isOpen && modalInfo?.response_id),
		}
	);

	return (
		<>
			<Modal
				isOpen={isOpen}
				size={formIsCbwById(modalInfo?.form_id as number) ? 'full' : '4xl'}
				onClose={onClose}
			>
				<FormReviewModalContext.Provider value={true}>
					<ModalOverlay />
					<ModalContent>
						<LoadingContainer isLoading={notesQuery.isLoading} />

						<Box visibility={notesQuery.isLoading ? 'hidden' : undefined}>
							<ModalHeader alignItems="start" as={VStack} mb={0} pb={0} spacing="1">
								{modalInfo.isDisabled_ && (
									<Text fontSize="md" fontWeight="bold">
										Form Review: {modalInfo.date}
									</Text>
								)}

								{!!modalInfo.score && (
									<Text fontSize="md" fontWeight="normal">
										Score: {modalInfo?.score}
									</Text>
								)}

								{!!modalInfo.client_id && (
									<CreateNoteModal
										responseId={modalInfo?.response_id}
										onSuccess={() => notesQuery.refetch()}
									/>
								)}
								{!isEmpty(notesQuery.data) && <ViewNotesButton notes={notesQuery.data as Note[]} />}
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								{isFormEngineV2({ form_data: modalInfo }) ? (
									<FormEngineV2Container response_id={modalInfo.response_id} />
								) : (
									<FormProvider
										client_id={modalInfo.client_id}
										form_id={modalInfo.form_id}
										isDisabled_={modalInfo.isDisabled_}
										response_id={modalInfo.response_id}
									/>
								)}
							</ModalBody>
							<ModalFooter>
								<Button onClick={onClose}>Close</Button>
							</ModalFooter>
						</Box>
					</ModalContent>
				</FormReviewModalContext.Provider>
			</Modal>
		</>
	);
}
