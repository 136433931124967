import { keyframes } from '@chakra-ui/react';
import { HTMLMotionProps } from 'framer-motion';

import { ClientStatus } from '@/@types/enums';
import { EventType } from '@/api/appointments';

export enum Roles {
	authenticated = 'authenticated',
	client = 'client',
	clinician = 'clinician',
	coordinator = 'coordinator',
}

export enum VideoCallStatus {
	inMeeting = 'in meeting',
	leftMeeting = 'left meeting',
}

export const DATE_FORMAT = 'MMMM D, YYYY h:mmA';
export const DATE_FORMAT_FOR_DATEPICKER = 'MMMM d, yyyy h:mma';

export const ONE_MINUTE_IN_MS = 60 * 1000;

export const DEFAULT_EVENT_TYPES: EventType[] = [
	{ length: 30, title: '30 Min Session' },
	{ length: 60, title: '60 Min Session' },
	{ length: 90, title: '90 Min Session' },
	{ length: 120, title: '120 Min Session' },
];

export const motionHeightCollapseAnimation: Partial<HTMLMotionProps<any>> = {
	animate: { opacity: 1, height: 'auto' },
	exit: { opacity: 0, height: 0 },
	initial: { opacity: 0, height: 0 },
};

export const shakeAnimation = `${keyframes`
10%, 30%, 50%, 70%, 90% {
	transform: translate3d(-5px, 0, 0);
}
20%, 40%, 60%, 80% {
	transform: translate3d(5px, 0, 0);
}`} infinite 0.5s linear`;

export const clientStatusOptions = [
	{ value: ClientStatus['pre-treatment'], label: 'Pre-treatment' },
	{ value: ClientStatus['active'], label: 'Active treatment' },
	{ value: ClientStatus['lost'], label: 'Lost (no next session scheduled, reason unknown)' },
	{ value: ClientStatus['paused'], label: 'Treatment paused (client request)' },
	{ value: ClientStatus['ended-early'], label: 'Treatment ended early (client request)' },
	{ value: ClientStatus['complete'], label: 'Treatment complete (symptom improvement)' },
];

export const clientStatusOptionsForCoordinator = [
	{ value: ClientStatus['inactive'], label: 'Inactive' },
	...clientStatusOptions,
];
