import * as yup from 'yup';
import 'yup-phone-lite';

// NOTE
// "validationType": "string",
// "validations": [
// 	{
// 		"type": "required",
// 		"params": ["Required"]
// 	}
// ]
// qid is the question id; should be changed to element or input ID
// validationType: string, number, etc.
// validations.type: required or not
// validations.param: message to display

type Question = {
	formikRequired: boolean;
	qid: string;
	validationType: ValidationType;
	validations: Validation[];
};

export function createYupSchema(schema: Record<string, yup.AnySchema>, config: Question) {
	const { qid, validationType, validations = [] } = config;

	// if validationType is not something that yup accepts
	if (!yup[validationType]) {
		return schema;
	}
	let validator = yup[validationType]();

	validations.forEach((validation) => {
		const { params, type } = validation;
		if (!validator[type]) {
			return;
		}
		validator = validator[type](...params);
	});

	return {
		...schema,
		[qid]: validator,
	};
}

export const getYupSchemaFromMetaData = (
	questionsArray: Question[],
	additionalValidations: Record<string, yup.AnySchema>,
	forceRemove: string[] = []
) => {
	const yepSchema = questionsArray.reduce(createYupSchema, {} as Record<string, yup.AnySchema>);
	const mergedSchema = {
		...yepSchema,
		...additionalValidations,
	};

	forceRemove.forEach((field) => {
		delete mergedSchema[field];
	});

	const validateSchema = yup.object().shape(mergedSchema);

	return validateSchema;
};
