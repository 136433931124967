import { FieldValues, UseFormProps, UseFormReturn, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

import { useIsDisabled } from './useIsDisabled';

export type UseNellieFormReturn = UseFormReturn<any>;

type UseNellieFormProps<T extends FieldValues> = Omit<UseFormProps<T>, 'resolver'> & {
	schema?: AnyObjectSchema;
};

export function useNellieForm<T extends FieldValues = FieldValues>(props?: UseNellieFormProps<T>) {
	const { isDisabled } = useIsDisabled();
	const { schema, ...options } = props || {};
	return useForm<T>({
		...options,
		resolver: isDisabled ? undefined : schema ? yupResolver(schema) : undefined,
	});
}
