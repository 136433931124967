import { UseFormProps, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, omit } from 'lodash-es';

import { htmlStringToText } from '@/utils';

import { NoteItemFormValues } from '../NoteItem';

const validationSchema = {
	sections: yup.array().of(
		yup.object().shape({
			title: yup.string().required('Section title is required'),
		})
	),
	newAmendment: yup.string().test(function (value) {
		return isEmpty(htmlStringToText(value)) ? this.createError({ message: 'Required' }) : true;
	}),
	title: yup.string().required('Note title is required'),
};

export const useNoteForm = (props: UseFormProps<NoteItemFormValues, { isAmending: boolean }>) => {
	const prevId = usePrevious(props?.values?.id);
	const isCreateMode = !props?.values?.id;

	return useForm<NoteItemFormValues, { isAmending: boolean }>({
		resolver: (values, context, options) => {
			return yupResolver(
				yup
					.object()
					.shape(context?.isAmending ? validationSchema : omit(validationSchema, 'newAmendment'))
				// @ts-ignore
			)(values, context, options);
		},
		resetOptions: {
			keepDirtyValues: isCreateMode ? false : prevId === props?.values?.id,
			keepValues: isCreateMode ? false : undefined,
		},
		...props,
	});
};

export * from './useNoteApi';
