import { FC, createContext, useContext } from 'react';

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
} from '@chakra-ui/react';

import { FormEngineV2, isFormEngineV2 } from '@/forms/core/FormEngineV2';
import { useErrorToast, useGetForm } from '@/hooks';
import { formIsCbwById } from '@/utils';

import { FormBuilderBox } from '../FormBuilderBox/FormBuilderBox';
import { LoadingContainer } from '../ui/ContainerComponents/ContainerComponents';

type FormPreviewModelProps = {
	form_id: number;
	onClose: VoidFunction;
};

export const FormPreConfigurableModalContext = createContext({
	isPreConfiguration: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	assignForm: async (_options: { default_values?: Record<string, any> }): Promise<unknown> => {
		throw new Error('assignAndClose not implemented');
	},
	closeModal: (): void => {
		throw new Error('closeModal not implemented');
	},
});
export const FormPreviewModalContext = createContext(false);
/** returns true if `FormPreviewModal` is open */
export const useIsFormPreviewModalOpen = () => useContext(FormPreviewModalContext);
export const useIsFormPreConfigurableModalOpen = () => useContext(FormPreConfigurableModalContext);

export const FormModal: FC<FormPreviewModelProps> = ({ form_id, onClose }) => {
	const showError = useErrorToast({ position: 'top' });

	const { data, loading, error } = useGetForm(form_id, {
		onError: (err) => {
			console.error(err);
			onClose();
			showError();
		},
	});

	return (
		<Modal isOpen={true} size={formIsCbwById(form_id) ? 'full' : '4xl'} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					{isFormEngineV2(data?.attributes) ? (
						<FormEngineV2 Component={data?.attributes.form_data.Component as string} />
					) : (
						<LoadingContainer error={error} isLoading={loading}>
							{data && (
								<FormBuilderBox
									formBuilder={{
										form_id: data.id,
										form_data: data.attributes.form_data,
										form_title: data.attributes.form_title,
										form_instructions: data.attributes.form_instructions,
										form_type: data.attributes.type,
										isDisabled_: true,
									}}
									ml="unset"
								/>
							)}
						</LoadingContainer>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose}>Close</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
