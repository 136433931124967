import { Suspense, lazy, memo, useCallback } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button, CardFooter, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { map, pick } from 'lodash-es';

import { openConfirmationModal } from '@/components/ui';

import { newSection } from './NoteItem';
import { NoteItemSection } from './NoteItemSection';
import { useNoteApi } from './hooks';

const DragDropContext = lazy(() =>
	import('react-beautiful-dnd').then((module) => ({ default: module.DragDropContext }))
);
const Draggable = lazy(() =>
	import('react-beautiful-dnd').then((module) => ({ default: module.Draggable }))
);
const Droppable = lazy(() =>
	import('react-beautiful-dnd').then((module) => ({ default: module.Droppable }))
);

type NoteItemSectionsProps = {
	canAddAttachment?: boolean;
	isReadOnly?: boolean;
};

export const NoteItemSections = memo(
	({ isReadOnly = false, canAddAttachment }: NoteItemSectionsProps) => {
		const { getValues, setValue, watch } = useFormContext<Note>();
		const { deleteNoteSection, updateNoteSectionOrder } = useNoteApi();

		const { fields, append, remove, insert, move } = useFieldArray<Note>({
			name: 'sections',
		});

		const updateOrder = useCallback(async () => {
			watch('sections').forEach((_, index) => {
				// set index as order
				setValue(`sections.${index}.order` as any, index);
			});

			if (watch('id')) {
				return updateNoteSectionOrder({
					id: watch('id'),
					// @ts-ignore
					sections: map(watch('sections'), (s) => pick(s, 'id', 'order')) as NoteSection[],
				});
			}
		}, [setValue, updateNoteSectionOrder, watch]);

		const onDragEnd = useCallback(
			(result: DropResult) => {
				if (!result.destination) {
					return;
				}

				move(result.source.index, result.destination.index);

				updateOrder();
			},
			[move, updateOrder]
		);
		return (
			<VStack w="100%">
				<Suspense
					fallback={
						<VStack w="100%">
							<Skeleton h="250px" w="100%" />
							<Skeleton h="50px" w="100%" />
						</VStack>
					}
				>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<VStack w="100%" {...provided.droppableProps} ref={provided.innerRef}>
									{fields.map((field, index) => {
										const namePrefix = `sections.${index}` as const;

										return (
											<Draggable
												key={field.id}
												draggableId={field.id.toString()}
												index={index}
												isDragDisabled={isReadOnly}
											>
												{(provided, snapshot) => (
													<HStack
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														key={field.id}
														borderRadius="md"
														outline={snapshot.isDragging ? '1px solid' : undefined}
														outlineColor="brand.100"
														w="100%"
													>
														<NoteItemSection
															addSection={(section = newSection) => {
																insert(index + 1, section);
																updateOrder();
															}}
															canAddAttachment={canAddAttachment}
															isReadOnly={isReadOnly}
															moveSectionDown={
																index + 1 > fields.length - 1
																	? undefined
																	: () => {
																			onDragEnd({
																				source: { index },
																				destination: { index: index + 1 },
																			} as DropResult);
																	  }
															}
															moveSectionUp={
																index - 1 < 0
																	? undefined
																	: () => {
																			onDragEnd({
																				source: { index },
																				destination: { index: index - 1 },
																			} as DropResult);
																	  }
															}
															namePrefix={namePrefix}
															removeSection={
																fields.length === 1
																	? undefined
																	: async (sectionIsEmpty) => {
																			if (sectionIsEmpty) return remove(index);

																			openConfirmationModal({
																				modalProps: { returnFocusOnClose: false },
																				title: 'Are you sure you want to delete this?',
																				onSubmit: () => {
																					const [noteId, noteSectionId] = [
																						getValues('id'),
																						getValues(`${namePrefix}`).id,
																					];

																					if (noteId && noteSectionId) {
																						return deleteNoteSection(+noteSectionId).then(() =>
																							remove(index)
																						);
																					}
																					remove(index);
																				},
																			});
																	  }
															}
														/>
													</HStack>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</VStack>
							)}
						</Droppable>

						{!isReadOnly && (
							<CardFooter p="0" pb="20px" w="100%">
								<Button
									_active={{
										bg: '#3A38A6',
										color: 'white',
									}}
									_hover={{
										bg: '#3A38A6',
										color: 'white',
									}}
									minH="50px"
									size="lg"
									variant="outline"
									width="100%"
									onClick={() =>
										append({
											...newSection,
											order: fields.length,
										})
									}
								>
									Add new section
								</Button>
							</CardFooter>
						)}
					</DragDropContext>
				</Suspense>
			</VStack>
		);
	}
);
