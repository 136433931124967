import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { useAppQuery } from '@/app-react-query';
import { useGlobalActions } from '@/context';
import { ONE_MINUTE_IN_MS, Roles } from '@/utils';

export const useGetMyInfo = <
	T extends UseQueryOptions<Client | Clinician, AxiosError, Client | Clinician>
>(
	options?: T
) => {
	const { setGlobalInfoComplete, setLoggedInUser } = useGlobalActions();

	return useAppQuery('Api.users.getMyInfo', Api.users.getMyInfo, {
		staleTime: 0,
		cacheTime: 5 * ONE_MINUTE_IN_MS,
		...options,
		onSuccess: (person) => {
			setLoggedInUser(person.nellie_user);

			if (person.role.type === Roles.client || person.role.type === Roles.authenticated) {
				setGlobalInfoComplete(Boolean(person.nellie_user.general_info_complete));
			}

			options?.onSuccess?.(person);
		},
		retry: false,
	});
};
