export default (state, action) => {
	switch (action.type) {
		case 'SET_GLOBAL_INFO_COMPLETE':
			return {
				...state,
				generalInfoComplete: action.payload,
			};
		case 'SET_VIDEO_CALL_STATUS':
			return {
				...state,
				videoCallStatus: action.payload,
			};
		case 'SET_TRAINEE':
			return {
				...state,
				trainee: action.payload,
			};
		case 'SET_CLIENT':
			return {
				...state,
				client: action.payload,
			};
		case 'SET_LOGGED_IN_USER':
			return {
				...state,
				loggedInUser: action.payload,
			};
		default:
			return state;
	}
};
