import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { isEqual } from 'lodash-es';

import { Alert404 } from '@/components';
import { Roles } from '@/utils';
import auth from '@/utils/auth';

type RoleBaseAuthenticatedRouteProps = {
	requiredRoles: Roles[];
};

export const RoleBasedAuthenticatedRoute = memo<RoleBaseAuthenticatedRouteProps>(
	({ requiredRoles }) => {
		if (requiredRoles.includes(auth.getUserRole())) return <Outlet />;

		return <Alert404 />;
	},
	isEqual
);
