import { memo, useEffect, useState } from 'react';

import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import { ConfirmButton } from '../../ui';

type SessionTimeoutModalProps = {
	getRemainingTime: () => number;
	onClose: VoidFunction;
};

export const SessionTimeoutModal = memo<SessionTimeoutModalProps>(
	({ onClose, getRemainingTime }) => {
		const [timeLefInSeconds, setTimeInSecondsLeft] = useState(getRemainingTime() / 1000);

		useEffect(() => {
			const interval = setInterval(() => {
				setTimeInSecondsLeft(Math.ceil(getRemainingTime() / 1000));
			}, 500);

			return () => {
				clearInterval(interval);
			};
		});

		const minutes: number = parseInt((timeLefInSeconds / 60).toString(), 10);
		const seconds: number = parseInt((timeLefInSeconds % 60).toString(), 10);

		return (
			<Modal isOpen onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign="center">Session Timeout</ModalHeader>
					<ModalBody textAlign="center">
						You will be logged out soon due to inactivity.
						<Text fontSize="xl" fontWeight="bold" mt={3}>
							{minutes} : {seconds < 10 ? '0' + seconds : seconds}
						</Text>
					</ModalBody>

					<ModalFooter alignItems="center">
						<ConfirmButton mx="auto" onClick={onClose}>
							Continue
						</ConfirmButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}
);
