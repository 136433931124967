import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Api } from '@/api';
import { GetCliniciansResponse } from '@/api/users';
import { useAppQuery } from '@/app-react-query';

import { useFocusedClientId } from '../useFocusedClientId';

/**
 * `useGetClientInfo` is a React hook that returns the result of calling `useQuery` with the
 * `Api.users.getClientInfo` function
 */
export const useGetClientInfo = <ActualResponse = GetCliniciansResponse, TError = AxiosError>(
	options?: Omit<
		UseQueryOptions<GetCliniciansResponse, TError, ActualResponse, any>,
		'queryKey' | 'queryFn'
	>
) => {
	const clientId = useFocusedClientId();

	return useAppQuery(
		['Api.users.getClientInfo', { client_id: clientId.toString() }],
		() => Api.users.getClientInfo(clientId.toString()),
		{
			enabled: Boolean(clientId),
			...options,
		}
	);
};
