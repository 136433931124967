import { useMemo, useRef } from 'react';

import {
	Box,
	Flex,
	Grid,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useMediaQuery,
	useUnmountEffect,
} from '@chakra-ui/react';

import { useAppContentRef } from '@/containers/NavLayout/NavLayout';

import { useSidebarContext } from '../../../context/SidebarContext';
import { useMountEffect } from '../../../hooks';

import { FormElement } from './FormElement';

export const CBWorksheet = ({
	saveOnBlur,
	jsonData: {
		formHeader: { formTitle, grid, tableColWidth: colWidth, tableRowHeight: rowHeight },
		elements,
	},
	isDisabled_ = false,
}) => {
	const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

	const gridWrapperRef = useRef(null);
	const [isCollapsed, setIsCollapsed] = useSidebarContext();
	const isCollapsedRef = useRef({
		initialIsCollapsed: isCollapsed,
		isCollapsedWasModified: false,
	});
	const appLayoutRef = useAppContentRef();

	useMountEffect(() => {
		const margin = 20;
		if (appLayoutRef.current?.clientWidth + margin <= gridWrapperRef.current?.clientWidth) {
			setIsCollapsed(true);
			isCollapsedRef.current.isCollapsedWasModified = true;
		}
	});

	useUnmountEffect(() => {
		if (isCollapsedRef.current.isCollapsedWasModified) {
			setIsCollapsed(isCollapsedRef.current.initialIsCollapsed);
		}
	});

	const gridArr = useMemo(() => {
		let count = 0;
		const tempGridArr = [];
		grid.map((el) => {
			const tempArr = [];
			for (let i = 0; i < el; i++) {
				tempArr.push(elements[count++]);
			}
			tempGridArr.push(tempArr);
		});
		return tempGridArr;
	}, [elements, grid]);

	const headers = gridArr.map((el) => el[0].text);
	const descriptions = gridArr.map((el) => el[1].text);
	const columns = gridArr.map((el) => el.slice(2));

	return (
		<>
			<Text fontSize="xl" fontWeight="bold">
				{formTitle}
			</Text>

			{isLargerThan1200 ? (
				<Table height="100%" maxW="1443px" outline="1px solid black">
					<Thead>
						<Tr outline="1px solid black">
							{headers.map((el, i) => (
								<Th key={el} outline={i % 2 ? '1px solid black' : undefined}>
									<Box minWidth="fit-content">{el}</Box>
								</Th>
							))}
						</Tr>
						<Tr outline="1px solid black">
							{descriptions.map((el, i) => (
								<Td key={el} outline={i % 2 ? '1px solid black' : undefined}>
									<Box minWidth="fit-content">{el}</Box>
								</Td>
							))}
						</Tr>
					</Thead>
					<Tbody outline="1px solid black">
						<Tr>
							{columns.map((cols, i) => {
								return (
									<Td
										key={i}
										outline={i % 2 ? '1px solid black' : undefined}
										padding="10px 0px 10px 0px"
									>
										<Stack alignItems="start" height="100%" justifyContent="space-between">
											{cols.map((el) => {
												if (!el.qid)
													return (
														<Box
															key={el.text}
															borderBottom="1px solid black"
															borderTop="1px solid black"
															flexShrink={1}
															w="100%"
														>
															<FormElement
																saveSpace
																isDisabled_={isDisabled_}
																mb={0}
																mt={0}
																saveOnBlur={saveOnBlur}
																thisElement={el}
															/>
														</Box>
													);

												return (
													<Box key={el.qid} flexGrow={1} px="10px" w="100%">
														<FormElement
															isDisabled_={isDisabled_}
															mb={0}
															mt={0}
															saveOnBlur={saveOnBlur}
															saveSpace={true}
															thisElement={el}
														/>
													</Box>
												);
											})}
										</Stack>
									</Td>
								);
							})}
						</Tr>
					</Tbody>
				</Table>
			) : (
				<Stack
					ref={gridWrapperRef}
					align="baseline"
					direction={['column', 'column', 'column', 'column', 'column', 'row']}
					spacing="0px"
				>
					{gridArr.map((col, index) => {
						return (
							<Grid
								key={index}
								alignContent="start"
								gap="0"
								spacing="0"
								templateColumns={[310, 310, 310, 310, 310, colWidth[index]]}
								templateRows={[
									'min-content',
									'min-content',
									'min-content',
									'min-content',
									'min-content',
									rowHeight[index],
								]}
							>
								{col.map((el, i) => {
									const {
										style: { bordersAlt, borders },
									} = el;
									return (
										<Flex
											key={`${index}${i}`}
											alignItems="center"
											borderColor="black"
											borderWidth={[
												`${bordersAlt[0]} ${bordersAlt[1]} ${bordersAlt[2]} ${bordersAlt[3]}`,
												`${bordersAlt[0]} ${bordersAlt[1]} ${bordersAlt[2]} ${bordersAlt[3]}`,
												`${bordersAlt[0]} ${bordersAlt[1]} ${bordersAlt[2]} ${bordersAlt[3]}`,
												`${bordersAlt[0]} ${bordersAlt[1]} ${bordersAlt[2]} ${bordersAlt[3]}`,
												`${bordersAlt[0]} ${bordersAlt[1]} ${bordersAlt[2]} ${bordersAlt[3]}`,
												`${borders[0]} ${borders[1]} ${borders[2]} ${borders[3]}`,
											]}
											pb="1px"
											pt="2px"
											px="2"
										>
											<FormElement
												removeReactMarkdownMarginBottom
												isDisabled_={isDisabled_}
												mb={0}
												mt={0}
												saveOnBlur={saveOnBlur}
												saveSpace={true}
												thisElement={el}
											/>
										</Flex>
									);
								})}
							</Grid>
						);
					})}
				</Stack>
			)}
		</>
	);
};
