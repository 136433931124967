import { ReactNode, memo } from 'react';

import {
	Alert,
	AlertIcon,
	Box,
	BoxProps,
	CenterProps,
	Heading,
	Stack,
	Text,
	VStack,
	useColorModeValue as mode,
} from '@chakra-ui/react';

import { Alert404 } from '@/components';

import { CustomSpinner } from '..';

export const PrimaryContainer = (props: BoxProps) => {
	const { children, ...rest } = props;

	return (
		<Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ md: '8' }} {...rest}>
			{children}
		</Box>
	);
};

export const SecondaryContainer = (props: BoxProps) => {
	const bgColor = mode('white', 'gray.700');
	const { children, ...rest } = props;

	return (
		<Box
			bg={bgColor}
			maxW="3xl"
			ml={{ md: '1%' }}
			mt="6"
			mx="auto"
			rounded="lg"
			shadow="base"
			{...rest}
		>
			{children}
		</Box>
	);
};

export const TertiaryContainer = (props: BoxProps) => {
	const { children, ...rest } = props;

	return (
		<Box px="6" py="4" {...rest}>
			{children}
		</Box>
	);
};

type ContainerHeaderProps = BoxProps & {
	alert?: ReactNode;
	heading?: ReactNode;
	smallheading?: ReactNode;
	subheading?: ReactNode;
};

export const ContainerHeader = (props: ContainerHeaderProps) => {
	const textLight = mode('gray.600', 'gray.300');
	const { children, heading, subheading = null, smallheading, alert, ...rest } = props;

	return (
		<Box px="6" py="4" {...rest}>
			<Heading as="h1" paddingBottom="4" size="lg">
				{heading}
				{children}
			</Heading>
			<Text fontSize="2xl" fontWeight="bold">
				{smallheading}
			</Text>

			<Text color={textLight} fontSize="sm">
				{subheading}
			</Text>
			{alert ? (
				<Alert status="info">
					<AlertIcon />
					{alert}
				</Alert>
			) : null}
		</Box>
	);
};

type SplitLayoutContainerProps = {
	left?: React.ReactNode;
	right?: React.ReactNode;
};

export const SplitLayoutContainer = memo<SplitLayoutContainerProps>(({ left, right }) => {
	return (
		<Stack
			direction={{ lg: 'row', base: 'column' }}
			gap={{ lg: 6, md: 0, sm: 0 }}
			px={{ lg: 3, md: 2, sm: 2 }}
		>
			<VStack
				alignItems={{ lg: 'end', md: 'center', sm: 'center' }}
				gap="5"
				minWidth="calc(50% - 0.75rem)"
			>
				{left || <></>}
			</VStack>
			<VStack
				alignItems={{ lg: 'start', md: 'center', sm: 'center' }}
				gap="5"
				margin="0px !important"
				minWidth="calc(50% - 0.75rem)"
			>
				{right || <></>}
			</VStack>
		</Stack>
	);
});

export type LoadingContainerProps = {
	children?: React.ReactNode;
	error?: unknown;
	isLoading: boolean;
	spinnerProps?: CenterProps;
};

export const LoadingContainer = memo<LoadingContainerProps>(
	({ children, isLoading, error, spinnerProps }) => {
		if (isLoading) return <CustomSpinner {...spinnerProps} />;
		if (error) return <Alert404 />;

		return <>{children}</>;
	}
);
